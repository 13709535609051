import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Button } from '@material-ui/core';
import { getKnowledgeBaseUrl, getLabelPlural, onClickResultItem } from '../../../utilities';
import ErrorIcon from '@material-ui/icons/Error';
import { CerebrumLongListLoader, SolrLongListLoader } from '../../../LongListLoader';
import WarningBanner from './WarningBanner';
import { globalListenerRef } from '../../../GlobalListenerRef';
import moment from 'moment';
const styles = theme => ({
  bannerButton: {
    padding: '4px 8px',
    marginLeft: 24,
    height: 24,
    flexShrink: 0,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.background.main
    }
  },
  bannerText: {
    fontSize: 13.75,
    flexGrow: 0,
    color: theme.palette.primaryText.main,
    '& *': {
      color: theme.palette.primaryText.main,
      fontSize: 16,
    }
  },
  banner: {
    // height: '56px',
    flexGrow: 1,
    backgroundColor: theme.palette.inputBackground.main,
    border: `1px solid ${theme.palette.border.main}`,
    width: '100%',
    boxSizing: 'border-box',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 4px'
  },
});



const ProfileBanner = props => {

  const {
    classes,
    data,
    history,
    label,
    // banner
    tabOptions,
    bannerdisplayText,
    bannerCause,
    bannerData,
    bannerAction,
    state,
    dispatch,
  } = props;

  const [warnings, setWarnings] = useState()

  const enrichWarnings = ws => {
    if (!ws || ws.length === 0) {
      setWarnings([]);
      return;
    }
    SolrLongListLoader({
      url: '/solr/search/select',
      params: {
        q: "*",
        fq: `id:(${ws.map(e => e.id).join(' ')})`,
        rows: 50,
      },
      onFinishLoad: ({ data }) => {
        setWarnings(
          ws
            .map(w => {
              return {
                ...w,
                ...(data.find(d => d.id === w.id) || {})
              }
            })
            .filter(f => {
              if (f.expiry_srt && moment(f.expiry_srt).isBefore(moment())) return false;
              return true;
            })
        )
      },
      onError: () => {
        setWarnings(ws)
      }
    })
  }

  const loadWarnings = () => {
    if (!data) {
      setWarnings([])
      return;
    };
    CerebrumLongListLoader({
      url: `/api/${getLabelPlural(data.object?.name || data.object_type_txt || label)}/${data.id.toLowerCase()}/related/collections`,
      params: {
        parent_name: 'Warning Notice',
        relationship: 'MEMBER_OF',
        sort: 'END_DESC',
        object_active_flag: true
      },
      per_page: 50,
      onFinishLoad: ({ data }) => {
        enrichWarnings(data)
      },
      onError: () => {
        setWarnings([])
      }
    })
  }

  useEffect(() => {
    loadWarnings()

    window.removeEventListener('message', globalListenerRef.bannerMsgListener)
    globalListenerRef.bannerMsgListener = (msg) => {
      if (msg.data.reload_warning_notice) {
        loadWarnings();
      }
    }
    window.addEventListener('message', globalListenerRef.bannerMsgListener)

    return () => {
      window.removeEventListener('message', globalListenerRef.bannerMsgListener)
    }
    // eslint-disable-next-line
  }, [])

  const seeDetails = () => {
    if (bannerCause === 'issue') {
      dispatch({ type: 'set_tab_state', tabState: tabOptions.indexOf('ISSUES') })
    }
    else if (bannerCause === 'external_issue') {
      window.open(data.external_url, '_blank')
    }
    else if (bannerCause === 'emailNotConfigured') {
      window.open('/admin/integrations?capability=EMAIL', '_blank')
    }
    else if (bannerCause === 'replacedByDate') {
      if (state.replacedObject) {
        onClickResultItem({ item: state.replacedObject, label: state.replacedObject.object_type_txt, id: state.replacedObject.id, history })
      } else {
        dispatch({ type: 'set_tab_state', tabState: tabOptions.indexOf('LINEAGE') })
      }
    }
    else if (bannerCause === 'access_key') {
      history.push(`/admin/access_key`)
    }
    else if (bannerCause === 'merge') {
      history.push(bannerData.targetUrl)
    }
    else if (bannerCause === 'bulk_edit_templated') {
      window.open('https://docs.kada.ai/home/bulk-update-via-data-cart', '_blank')
    }
    else if (bannerCause === 'bulk_edit_link_collection_info') {
      window.open('https://docs.kada.ai/home/bulk-update-via-data-cart', '_blank')
    }
    else return
  }


  let button;

  if (
    bannerCause === 'replacedByDate' ||
    // bannerCause === 'question_unanswered' ||
    bannerCause === 'access_key' ||
    bannerCause === 'merge' ||
    (bannerCause === 'issue' && tabOptions) ||
    (bannerCause === 'external_issue' && data.external_url) ||
    bannerCause === 'source_deactivation' ||
    bannerCause === 'emailNotConfigured' ||
    bannerCause === 'bulk_edit_templated' ||
    bannerCause === 'ia_limit' ||
    bannerCause === 'bulk_edit_link_collection_info'
  ) {
    let buttonMsg = "SEE DETAILS"
    // if(bannerCause==='question_unanswered')buttonMsg = "ADD AN ANSWER"
    if (bannerCause === 'access_key') buttonMsg = "CREATE ACCESS KEY"
    if (bannerCause === 'merge') buttonMsg = 'GO TO MERGED ACCOUNT'
    if (bannerCause === 'external_issue') {
      if (data.source_obj) {
        buttonMsg = `CLICK TO OPEN IN ${data.source_obj.source_template.name}`
      }
    }
    if (bannerCause === 'replacedByDate' && state.replacedObject) {
      buttonMsg = 'GO TO REPLACEMENT'
    }
    if (bannerCause === 'source_deactivation') {
      buttonMsg = 'REACTIVATE'
    }
    if (bannerCause === 'emailNotConfigured') {
      buttonMsg = 'CONFIGURE EMAIL NOTIFICATION'
    }
    if (bannerCause === 'bulk_edit_templated') {
      buttonMsg = 'GO TO KNOWLEDGE BASE'
    }
    if (bannerCause === 'bulk_edit_link_collection_info') {
      buttonMsg = 'GO TO KNOWLEDGE BASE'
    }
    if (bannerCause === 'ia_limit') {
      buttonMsg = 'GO TO KNOWLEDGE BASE'
    }
    button = (
      <Button
        onClick={bannerAction || seeDetails}
        color='secondary'
        variant="outlined"
        className={classes.bannerButton}
      >
        {buttonMsg}
      </Button>
    )
    if (bannerCause === 'ia_limit' && !getKnowledgeBaseUrl()) {
      button = undefined;
    }
  }


  if (warnings?.length > 0) {
    return (
      <WarningBanner
        warnings={warnings}
        data={data}
      />
    )
  }

  if(!warnings)return <></>

  if (warnings?.length===0 && !bannerCause) return <></>

  return (
    <div style={{ display: 'flex', marginBottom: 4 }}>
      <div className={classes.banner} id='profile-header-banner' >
        <ErrorIcon style={{ marginRight: 16, marginLeft: 16, color: '#FC642D', fontSize: 24 }} />
        <span className={classes.bannerText}>{bannerdisplayText}</span>
        {button}
      </div>
    </div>
  )
}


ProfileBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  data: PropTypes.object,
  history: PropTypes.object,
  // banner
  tabOptions: PropTypes.array,
  bannerdisplayText: PropTypes.string,
  bannerCause: PropTypes.string,
  bannerData: PropTypes.object,
  bannerAction: PropTypes.func,
  state: PropTypes.object,
  dispatch: PropTypes.func,
}



export default withTheme()(withStyles(styles)(ProfileBanner));
