import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem, Paper, Popper, MenuList, ClickAwayListener, Checkbox, InputBase, IconButton } from '@material-ui/core';
import { onClickResultItem,getDispFields,transformLogScale, getIconComponent, isInViewport, getSearchMode, formatNumber, getNameSearchQuery } from '../../../utilities';
import UsageListItem from '../../UI/SearchResults/UsageListItem';
import * as actions from '../../../store/actions/index';
import { connect, useStore } from 'react-redux';
import axiosSolr from '../../../axios-solr';
import { checkCanAddToCart, checkCanBulkUpdate } from '../../../permissionChecker';
import CartAdder, { addToCartLimit } from '../../UI/CartAdder/CartAdder';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
	root: {
		width: '100%'
	},
	title: {
		color: theme.palette.header.main,
		fontSize: 20,
		fontWeight: '400'
	},
	subtitle: {
		fontSize: '0.75rem',
		fontWeight: '400',
		color: theme.palette.primaryText.light,
		width: '15rem'
	},
	body: {
	},
	selector: {
    ...theme.components.selector,
    width: 180,
	},
	loadingUsage:{
		color:theme.palette.primaryText.main
	},
	listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main
    }
	},
	checkbox:{
		paddingLeft:0
	},
	searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
		width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
	emptyMsg:{
		fontSize:13.75,
		colour:theme.palette.primaryText.main,
		'& span':{
			textDecoration:'underline',
			cursor:'pointer',
		}
	}
})

const SchemaList = props => {

	const {
		history,
		theme,
		classes,
		state,
		dispatch,
		setBulkEditPrams,
		isShell
	} = props;

	const schemaLoader = useRef();
	const [maxFrequency, setMaxFrequency] = useState(0);
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
	const searchTimeoutRef = useRef()

	const [addToCartIds, setAddToCartIds] = useState()

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

	const sortByItems = [
		{ dispName: 'Frequently used', value: 'total_usage_srt desc' },
		{ dispName: 'Recently used', value: 'last_used_srt desc' },
		{ dispName: 'A-Z', value: 'name_srt asc' },
		{ dispName: 'Z-A', value: 'name_srt desc' }
	]
	const {
    sendAlert
  } = useAlert({
  })


	const loadReferenceItemCount = () => {
		const queryParams = {
			q: '*',
			fq: `object_type_srt:SCHEMA AND hierarchy_parent_id_srt:${state.basicData.id} AND reference_srt:YES`,
			fl:'*',
			rows: 0,
		};
		axiosSolr.get('/solr/search/select', { params: queryParams })
			.then(response => {
				dispatch({type:'set_reference_child_count',referenceChildCount:response.data.response.numFound})
			})
			.catch(error => {
				console.log(error)
			})
	}

	const onLoading = ({start=0,sort=state.sortBy,showDeleted=state.showDeletedSchemas,showReference=state.showReferenceSchemas,search=state.searchFilter, addToCart}) => {
		if(!addToCart){
			dispatch({
				type:'set_schemalist_data',
				schemalistData:start===0?undefined:state.schemalistData,
				schemalistLoading:true
			})
		}
		if(!sort.includes('name_srt')){sort+=', name_srt asc'}
		const queryParams = {
			q: getNameSearchQuery(search),
			fq: `object_type_srt:SCHEMA AND hierarchy_parent_id_srt:${state.basicData.id}${showReference?'':' AND reference_srt:NO'} ${showDeleted?'':'AND active_srt:YES'}`,
			fl:'*',
			start: addToCart?0:start,
			rows: addToCart?addToCartLimit:20,
			sort: search?undefined:sort,
      mm: '90%'
		};
		axiosSolr.get('/solr/search/select', { params: queryParams }, { withCredentials: true })
			.then(response => {
				if(addToCart){
					setAddToCartIds(response.data.response.docs.map(el=>el.id))
          return;
				}
				let newData = {...response.data.response}
				if(start===0){
					if(response.data.response.numFound===0 && isNaN(state.referenceChildCount) && !search){
						loadReferenceItemCount()
					}
					dispatch({ type: 'set_schemalist_data', schemalistData: newData})
					return;
				}
				newData.docs = [...state.schemalistData.docs,...response.data.response.docs]
				dispatch({ type: 'set_schemalist_data', schemalistData: newData})
			})
			.catch(error => {
				if(addToCart){
          sendAlert({message:'Error occurred adding items to Cart',type:'error'})
					return;
				}
				console.log('Error retrieving data from cerebrum', error);
				dispatch({ type: 'set_schemalist_data', schemalistError: true})
			})
	}

	useEffect(()=>{
		if(!state.schemalistData)onLoading({})
		// eslint-disable-next-line
	},[])

	const changeSort = (value) => {
		if (value === state.sortBy) return;
		dispatch({ type: 'set_sort', sortBy: value })
		onLoading({sort:value})
	}


	const onClickShowDeleted = value => {
		dispatch({type:'set_show_deleted_schemas',showDeletedSchemas:value})
		onLoading({showDeleted:value})
	}

	const onClickShowReference = value => {
		dispatch({type:'set_show_reference_schemas',showReferenceSchemas:value})
		onLoading({showReference:value})
	}

	// eslint-disable-next-line
	const loadMoreSchemas = () => {
		if (isInViewport(schemaLoader) && state.schemalistData && state.schemalistData.docs.length < state.schemalistData.numFound && !state.schemalistLoading) {
			onLoading({start:state.schemalistData.docs.length})
		}
	}


	useEffect(()=>{
		loadMoreSchemas()
	},[state.schemalistData,loadMoreSchemas,state.schemalistLoading])

	window.onscroll = () => loadMoreSchemas()


	if( state.schemalistError){
		return <Typography className={classes.loadingUsage}>Error occurred loading schemas</Typography>
	}


	const mapDataToRows = data => {
		if (!data) return [];
		let maxFreq = maxFrequency;

		let frequencyArr = data.map(col=>col.total_usage_srt)
		let {logValue} = transformLogScale(frequencyArr,Math.E)
		maxFreq=Math.max(...logValue)

		let rowArray = data.map((schema,index) => {
			return {
				item:schema,
				id: schema.id,
				name: schema.name_txt || 'untitled',
				definition: schema.description,
				frequency: Math.max(logValue[index],0),
				labels: 'schema',
				collection: 'data',
			}
		})
		if (maxFreq > maxFrequency) setMaxFrequency(maxFreq)
		return rowArray;
	}

	const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

	const onClickViewInSearch = () => {
		if(getSearchMode()==='basic_search'){
      let presetFilter = `hierarchy_parent_id_srt:${state.basicData.id}${state.showReferenceSchemas?'':' AND reference_srt:NO'} ${state.showDeletedSchemas?'':'AND active_srt:YES'}`;
      if(state.basicData.name_txt){
				presetFilter += ` AND database_srt:${state.basicData.name_txt}`
			}
			if(state.basicData.source_txt){
				presetFilter += ` AND source_srt:${state.basicData.source_txt}`
			}
			history.push(`/basic_search?query=*&object=SCHEMA&presetFilter=${presetFilter}`)
    }
	}

	const onClickBulkEdit = () => {
		setBulkEditPrams({
			url:'/solr/search/select',
			type:'solr',
			params:{
				q: getNameSearchQuery(state.searchFilter),
				fq: `object_type_srt:SCHEMA AND hierarchy_parent_id_srt:${state.basicData.id}${state.showReferenceSchemas?"":" AND reference_srt:NO"} ${state.showDeletedSchemas?'':'AND active_srt:YES'}`,
				fl:'*',
				rows:1000,
			},
			redirectUrl:window.location.pathname.split('?')[0],
			fileName:`${state.schemalistData?formatNumber(state.schemalistData.numFound):''} schemas from ${state.basicData.name} database`.trim(),
		})
		history.push('/bulk_update')
	}

	const onSearchChange = value => {
    dispatch({type:'set_search_filter',searchFilter:value})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      onLoading({search:value})
    },250)
  }

	const onAddToCart = () => {
    onLoading({addToCart:true})
    setListActionOpen(false)
  }

	return (
		<div className={classes.root}>
			<div style={{display:'flex',alignItems:'flex-end',justifyContent:'space-between',flexWrap:'wrap'}}>
				<div style={{flexGrow:1,marginBottom:24}}>
					<Typography className={classes.title}>{state.schemalistData?formatNumber(state.schemalistData.numFound):0} SCHEMAS</Typography>
					<Typography className={classes.subtitle}>Select a Schema to see more details</Typography>
				</div>
				<div style={{display:'flex',alignItems:'center',marginBottom:24,flexShrink:0}}>
					<InputBase
						value={state.searchFilter}
						onChange={event => onSearchChange(event.target.value)}
						variant={'filled'}
						placeholder={'Search'}
						className={classes.searchFilter}
						endAdornment={
							<IconButton
								disabled={state.searchFilter===''}
								onClick={()=>onSearchChange('')}
								style={{width:32,height:32,marginRight:6}}
							>
								{getIconComponent({label:state.searchFilter===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
							</IconButton>
						}
					/>
					<Select
						className={classes.selector}
						value={state.sortBy}
						onChange={event => changeSort(event.target.value)}
						MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
						disabled={state.searchFilter}
					>
						{
							sortByItems.map(el => (
								<MenuItem  className={classes.menuItem} value={el.value}>
									<span>{el.dispName}</span>
								</MenuItem>
							))
						}
					</Select>
					<div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
						{getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
					</div>
					<Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
						<ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
							<Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
								<Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
								<MenuList className={classes.listContainer}>
									<MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
										<Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
									</MenuItem>
									{
										checkCanBulkUpdate({sessionData}) &&
										<MenuItem onClick={()=>{onClickBulkEdit()}} className={classes.menuItem} >
											<Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Bulk action</Typography>
										</MenuItem>
									}
									{
										checkCanAddToCart({sessionData, objectType:'SCHEMA'}) &&
										<MenuItem data-test-id="child-list-add-cart" onClick={()=>{onAddToCart()}} className={classes.menuItem} >
											<Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Add to Cart</Typography>
										</MenuItem>
									}
								</MenuList>
								<Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
								<MenuItem onClick={()=>{onClickShowReference(!state.showReferenceSchemas)}} className={classes.menuItem} >
                  <Checkbox key={state.showReferenceSchemas} className={classes.checkbox} color='primary' checked={state.showReferenceSchemas}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show reference</Typography>
                </MenuItem>
								<MenuItem onClick={()=>{onClickShowDeleted(!state.showDeletedSchemas)}} className={classes.menuItem} >
                  <Checkbox key={state.showDeletedSchemas} className={classes.checkbox} color='primary' checked={!state.showDeletedSchemas}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                </MenuItem>
							</Paper>
						</ClickAwayListener>
					</Popper>
				</div>
			</div>
			<div className={classes.body}>
				{
					state.schemalistData && state.schemalistData.numFound === 0 &&
					(
						!state.searchFilter && state.childCount ?
							!state.showReferenceSchemas && state.referenceChildCount ?
							<Typography className={classes.emptyMsg}>No active schemas found. <span onClick={()=>onClickShowReference(true)}>Click to see reference schemas</span> linked to this database</Typography>:
							<Typography className={classes.emptyMsg}>No active schemas found. <span onClick={()=>onClickShowDeleted(true)}>Click to see inactive schemas</span></Typography>
						:
						<Typography className={classes.emptyMsg}>No schemas found</Typography>
					)
				}
				{
					state.schemalistData && state.schemalistData.numFound > 0 && !isShell &&
					<div style={{display:'flex',justifyContent:'flex-end'}}><Typography style={{fontSize:12,letterSpacing:2, color:theme.palette.primaryText.main,marginRight:132,marginBottom:8}}>USAGE</Typography></div>
				}
				{
					state.schemalistData && state.schemalistData.numFound > 0 && mapDataToRows(state.schemalistData.docs).map(schema => (
						<UsageListItem
							key={schema.id}
							item={schema.item}
							dispTitle={getDispFields(schema.item, 'dispTitle')}
							frequency={schema.frequency}
							maxFrequency={maxFrequency}
							enableEdit
							onDescriptionUpdated={({item,description})=>{
								dispatch({
									type:'set_schemalist_data',
									schemalistData:{
										...state.schemalistData,
										docs:state.schemalistData.docs.map(el=>{
											if(el.id!==item.id)return el;
											return {...el,description}
										})
									}
								})
							}}
							onClick={() => onClickResultItem({ id:schema.id, label: 'schema',  history: history, item: schema.item })}
						/>
					)
					)}
				<div
					style={{ marginBottom: 10, display:'flex',justifyContent:'center' }}
					ref={schemaLoader}
				>
					{
						state.schemalistLoading && <CircularProgress color='secondary'/>
					}
				</div>
			</div>

      <CartAdder
        objectIds={addToCartIds}
        onFinish={()=>setAddToCartIds()}
        history={history}
      />
		</div>
	)
}

SchemaList.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => {
  return {
		setBulkEditPrams:(data) => dispatch(actions.setBulkEditParam(data))
  }
}
const mapStateToProps = state => {
  return {
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(SchemaList)));
