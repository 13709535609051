import React, {useEffect, useRef, useState} from 'react';
import { withStyles, CircularProgress, Typography, InputBase, IconButton, Select, MenuItem, MenuList, Popper, Paper, ClickAwayListener, Checkbox} from '@material-ui/core';
import axiosSolr from '../../../axios-solr'
import { formatNumber, getIconComponent, getNameSearchQuery, isInViewport, mapObjectName } from '../../../utilities'
import SolrList from '../../UI/SolrList/SolrList'
import theme from '../../../theme';
import { getCollectionObjectName } from '../../BasicSearch/MainSearch/Utils/Utils';
import * as actions from '../../../store/actions/actionTypes'
import { useDispatch } from 'react-redux';
// import CollectionList from './CollectionList';

const styles = theme => ({
  normalText:{
    color:theme.palette.primaryText.main
  },
  title:{
    color:theme.palette.header.main,
    fontSize:20,
    flexGrow:1
  },
  listItem:{
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  selector: {
    ...theme.components.selector,
		width: 150,
  },
  nameFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  checkbox:{
    paddingLeft:0,
  },
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main
    }
	},
})

function Source(props) {

  const {
    classes,
    state,
    dispatch,
    history,
    formatName
  } = props;

  const scrollRef = useRef()
  const searchTimeout = useRef()
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);

  const reduxDispatch = useDispatch();

  const isReference = state.basicData.reference;
  const sortByItems = [
    {dispName:'A-Z',value:'name_srt asc'},
    {dispName:'Z-A',value:'name_srt desc'},
    {dispName:'Trust', value:'trust_srt desc'}
  ]
  const sourceFilter = isReference?`parent_id_srt:${state.basicData.id}`:`source_id_srt:${state.basicData.source_id}`

  let selectedLabel = state.linkedFacets && state.linkedFacets.length>0?state.linkedFacets[state.sourceTabState].name:undefined;

  const loadItems = ({label,searchFilter='',sort='name_srt asc',start=0,showDeleted=state.showDeleted[selectedLabel],showReference=state.showReference[selectedLabel]}) => {
    let isCollection = state.linkedFacets.find(el=>el.name===label).isCollection
    let isCode = state.linkedFacets.find(el=>el.name===label).isCode
    // if(isCollection)return;
    dispatch({
      type:'set_linked_data',
      linkedData:{...state.linkedData,[label]:{
        ...(start===0?{}:state.linkedData[label]),
        loading:true
      }}
    })
    let fq;
    if(isCollection){
      fq = `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"${label}" AND ${sourceFilter}` + (showDeleted?"":' AND active_srt:YES')
    }
    else if(isCode){
      fq = `object_type_srt:CODE AND code_type_srt:${label} AND ${sourceFilter}` +  (showReference?'':` AND reference_srt:NO`) + (showDeleted?"":' AND active_srt:YES')
    }
    else{
      fq = `object_type_srt:${label} AND ${sourceFilter}` +  (showReference?'':` AND reference_srt:NO`) + (showDeleted?"":' AND active_srt:YES')
    }

    axiosSolr
      .get(
        '/solr/search/select',
        {params:{
          q:getNameSearchQuery(searchFilter),
          fq,
          fl:'*',
          sort:searchFilter?undefined:sort,
          start:start,
          rows:20
        }}
      )
      .then(response=>{
        dispatch({
          type:'set_linked_data',
          linkedData:{...state.linkedData,[label]:start===0?{...response.data.response}:{...response.data.response,docs:[...state.linkedData[label].docs,...response.data.response.docs]}}
        })
      })
      .catch(error=>{
        dispatch({
          type:'set_linked_data',
          linkedData:{...state.linkedData,[label]:{error:true}}
        })
      })
  }

  useEffect(()=>{
    if(!state.linkedFacets)return;
    let labelName = state.linkedFacets[state.sourceTabState]?state.linkedFacets[state.sourceTabState].name:undefined
    if(labelName && !state.linkedData[labelName]){
      loadItems({label:labelName,sort:state.linkedSort[labelName]});
    }
  // eslint-disable-next-line
  },[state.sourceTabState, state.linkedFacets])


  const shouldLoadMore = () => {
    if(state.linkedData[selectedLabel] && state.linkedData[selectedLabel].isCollection) return false
    return state.linkedData[selectedLabel] && !state.linkedData[selectedLabel].loading && state.linkedData[selectedLabel].docs.length<state.linkedData[selectedLabel].numFound && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldLoadMore())loadItems({label:selectedLabel, searchFilter:state.linkedSearch[selectedLabel],sort:state.linkedSort[selectedLabel],start:state.linkedData[selectedLabel].docs.length})
  }

  useEffect(()=>{
    if(!selectedLabel)return;
    if(shouldLoadMore())loadItems({label:selectedLabel, searchFilter:state.linkedSearch[selectedLabel],sort:state.linkedSort[selectedLabel],start:state.linkedData[selectedLabel].docs.length})
  // eslint-disable-next-line
  },[state.linkedData[selectedLabel]])


  const onNameFilterChange = value => {
    dispatch({type:'set_linked_search',linkedSearch:{...state.linkedSearch,[selectedLabel]:value}})
    clearTimeout(searchTimeout.current)
    searchTimeout.current = setTimeout(()=>{
      loadItems({label:selectedLabel, searchFilter:value,sort:state.linkedSort[selectedLabel]})
    },250)

  }

  const onSortChange = value => {
    dispatch({type:'set_linked_sort',linkedSort:{...state.linkedSort,[selectedLabel]:value}})
    loadItems({label:selectedLabel, searchFilter:state.linkedSearch[selectedLabel],sort:value})
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const generateFq = () => {
    let isCollection = state.linkedFacets.find(el=>el.name===selectedLabel).isCollection
    let isCode = state.linkedFacets.find(el=>el.name===selectedLabel).isCode
    let label = state.linkedFacets.find(el=>el.name===selectedLabel).name
    let fq;
    let objectType = label;
    if(isCollection){
      fq = `collection_srt:"${label}" AND ${sourceFilter}` +  (isReference?'':` AND reference_srt:NO`) + (state.showDeleted[selectedLabel]?"":' AND active_srt:YES')
      label = getCollectionObjectName({collectionName:label,collectionType:state.linkedFacets.find(el=>el.name===selectedLabel).collection_type});
      objectType = 'COLLECTION_INSTANCE';
    }
    else if(isCode){
      fq = `${sourceFilter}` +  (state.showReference[selectedLabel]?'':` AND reference_srt:NO`) + (state.showDeleted[selectedLabel]?"":' AND active_srt:YES')
    }
    else{
      fq = `${sourceFilter}` +  (state.showReference[selectedLabel]?'':` AND reference_srt:NO`) + (state.showDeleted[selectedLabel]?"":' AND active_srt:YES')
    }
    return {fq, label, objectType};
  }

  const onClickViewInSearch = () => {
    let query = `*`;
    // let presetFilter = fq + ` AND source_srt:${state.basicData.alternate_name}`
    let { fq, label } = generateFq();
    if(state.basicData.source_txt){
      fq += ` AND source_srt:${state.basicData.source_txt}`
    }
    history.push(`/basic_search?query=${query}&object=${label}&presetFilter=${fq}`)
	}

  const onClickBulkEdit = () => {

    let fq = generateFq().fq;
    if(state.basicData.source_txt){
      fq += ` AND source_srt:"${state.basicData.source_txt}"`
    }
    fq += ` AND object_type_srt:"${generateFq().objectType}"`
    reduxDispatch({
      type:actions.SET_BULK_EDIT_PARAM,
      data:{
        url:'/solr/search/select',
        type:'solr',
        params:{
          q: '*',
          fq,
        },
        redirectUrl:window.location.pathname,
        fileName:`${formatNumber(state.linkedData[selectedLabel]?state.linkedData[selectedLabel].numFound || 0:0)} ${formatName(mapObjectName(selectedLabel,true)).replace(/_/g,' ').toLowerCase()}(s) from source ${state.basicData.name}`
      }
    })

		history.push('/bulk_update')
	}

  const onClickShowReference = showReference => {
    dispatch({type:'set_show_reference',showReference:{...state.showReference,[selectedLabel]:showReference}})
    loadItems({label:selectedLabel, searchFilter:state.linkedSearch[selectedLabel],sort:state.linkedSort[selectedLabel],start:0,showReference})
  }

  const onClickShowDeleted = showDeleted => {
    dispatch({type:'set_show_deleted',showDeleted:{...state.showDeleted,[selectedLabel]:showDeleted}})
    loadItems({label:selectedLabel, searchFilter:state.linkedSearch[selectedLabel],sort:state.linkedSort[selectedLabel],start:0,showDeleted})
  }


  if(state.linkedFacetsError)return <Typography className={classes.normalText}>Error occurred loading data</Typography>
  if(!state.linkedFacets)return <div></div>
  if(state.linkedFacets.length===0)return <Typography className={classes.normalText}>No items linked to the source</Typography>

  return (
    <div className={classes.root}>
      <div style={{zIndex:9,position:'sticky',top:167,background:theme.palette.background.main,display:'flex',alignItems:'center',flexWrap:'wrap'}}>
        <Typography className={classes.title} style={{paddingBottom:8}}>
          {`${formatNumber(state.linkedData[selectedLabel]?state.linkedData[selectedLabel].numFound || 0:0)} ${formatName(mapObjectName(selectedLabel,true)).replace(/_/g,' ')}(S)`.toUpperCase()}
        </Typography>
        <div style={{display:'flex',marginBottom:8,alignItems:'center'}}>
          <InputBase
            value={state.linkedSearch[selectedLabel] || ''}
            onChange={event => onNameFilterChange(event.target.value)}
            variant={'filled'}
            placeholder={'Search'}
            className={classes.nameFilter}
            endAdornment={
              <IconButton
                disabled={!state.linkedSearch[selectedLabel]}
                onClick={()=>onNameFilterChange('')}
                style={{width:32,height:32,marginRight:6}}
              >
                {getIconComponent({label:state.linkedSearch[selectedLabel]?'clear':'search',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
          <Select
            className={classes.selector}
            value={state.linkedSort[selectedLabel] || 'name_srt asc'}
            onChange={event => onSortChange(event.target.value)}
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
            disabled={state.linkedSearch[selectedLabel]}
          >
            {
              sortByItems.map(el => (
                <MenuItem  className={classes.menuItem} value={el.value}>
                  <span>{el.dispName}</span>
                </MenuItem>
              ))
            }
          </Select>
          <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
            {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
          </div>
          <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
            <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
              <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                <MenuList className={classes.listContainer}>
                  <MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                  </MenuItem>
                  <MenuItem onClick={()=>{onClickBulkEdit()}} className={classes.menuItem} >
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Bulk action</Typography>
                  </MenuItem>
                </MenuList>
                <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                <MenuItem onClick={()=>{onClickShowReference(!state.showReference[selectedLabel])}} className={classes.menuItem} >
                  <Checkbox key={state.showReference[selectedLabel]} className={classes.checkbox} color='primary' checked={state.showReference[selectedLabel]===true}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show reference</Typography>
                </MenuItem>
                <MenuItem onClick={()=>{onClickShowDeleted(!state.showDeleted[selectedLabel])}} className={classes.menuItem} >
                  <Checkbox key={state.showDeleted[selectedLabel]} className={classes.checkbox} color='primary' checked={state.showDeleted[selectedLabel]!==true}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                </MenuItem>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      </div>
      {
         state.linkedData[selectedLabel] && state.linkedData[selectedLabel].docs &&
         <SolrList
            list={state.linkedData[selectedLabel].docs}
            history={history}
         />
      }
      {
        state.linkedData[selectedLabel] && state.linkedData[selectedLabel].error &&
        <Typography className={classes.normalText}>Error occurred loading items</Typography>
      }
      <div style={{marginTop:8}} ref={scrollRef}>
        {
          state.linkedData[selectedLabel] && state.linkedData[selectedLabel].loading &&
          <div style={{width:'100%',textAlign:'center'}}><CircularProgress color='secondary'/></div>
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(Source);
