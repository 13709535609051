import React from 'react';
import PropTypes from 'prop-types';
import { withTheme,withStyles, LinearProgress, Typography, Divider } from '@material-ui/core';
import { getIconComponent, toTitleCase, getInitials, onClickResultItem, mapObjectName } from '../../../../utilities';
import Avatar from '@material-ui/core/Avatar';
import OpenIcon from '@material-ui/icons/OpenInNewOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDownOutlined';
import KTooltip from '../../KTooltip/KTooltip';

const styles = theme => ({
  root: {
  },
  subtitle: {
    fontSize: 13.75,
    color: theme.palette.primaryText.light,
    fontWeight: 400,
    marginBottom: '2rem'
  },
  expansionPanel: {
    width: '100%',
  },
  avatar_display: {
    display: 'flex',
    flexDirection: 'row',
    height: '28px',
    marginRight: '5px',
    flex: '1 0 40%'
  },
  avatar: {
    marginRight: '4px',
    backgroundColor: theme.palette.avatar.main,
    cursor: 'pointer',
    height: '24px !important',
    width: '24px !important',
    fontSize: '12px ',
    textAlign: 'center',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  list: {
    width: '100%',
    paddingBottom:0
  },
  usageTitle: {
    width: '100%',
    color: theme.palette.header.main,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    marginTop: '24px'
  },
  usageSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  expansionPanelList: {
    width: '100%'
  },
  icon: {
    height: '24px',
    width: '24px',
    marginLeft: '16px',
    marginRight: '16px'
  },
  itemName: {
    height: '28px',
    flex: '1 0 45%',

    fontSize: '15.71px',
    letterSpacing: '0.5px',
    lineHeight: '28px',
  },
  usedBy: {
    height: '28px',
    marginRight:16,
    color: theme.palette.primaryText.main,
    fontSize: '11.78px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginTop: '5px'
  },
  memberLength: {
    height: '20px',
    width: '20px',
    color: theme.palette.primary.main,
    fontSize: '13.75px',
    letterSpacing: '0.25px',
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: '14px',
    marginTop: '2px'
  },
  listItemText: {
    wordBreak: 'break-all',
    paddingLeft: '0px !important',
    marginLeft:8,
    '& span':{
      color:`${theme.palette.primaryText.main}`
    }
  },
  '&.MuiDivider-root': {
    height: '2px !important'
  },
  "& .MuiDivider-root": {
    backgroundColor: "currentColor",
    opacity: 0.3,
    height: '2px !important'
  },
  openIcon: {
    color:theme.palette.primaryText.light,
  },
  loadingUsage: {
    color: theme.palette.primaryText.main,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    textAlign: 'center'
  },
  listItemRoot:{
    overflow:'hidden',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  divider:{
    background:theme.palette.listItemDivider.main
  },
  iconRoot:{
    marginRight:4
  },
  chip:{
    background:theme.palette.chip.main,
    border:`1px solid ${theme.palette.chipBorder.main}`,
    fontSize:12,
    display:'flex',
    alignItems:'center',
    height:24,
    borderRadius:12,
    padding:'0 8px',
    marginRight:6,
    maxWidth:140,
    minWidth:16,
  },
  chipText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primaryText.main,
  }
})


const ExpansionPanel = withStyles(theme=>({
  root: {
    boxShadow:'none',
    '&::before': {
      background: 'none'
    },
  },
  expanded: {
    margin: 'auto',
    marginBottom:`3px !important`,
    border:`1px solid ${theme.palette.border.main}`,
    boxShadow:'0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)' ,
  },
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme=>({
  root: {
    backgroundColor: theme.palette.background.main,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    maxHeight: 42,
    minHeight: 0,
    padding: 0,
    height: '48px',
    paddingTop: 9,
    paddingBottom:3,
    '&:hover':{
      background:theme.palette.hovered.main
    },
    '&$expanded': {
      padding: 0,
      minHeight: 0,
      paddingTop: 9,
      paddingBottom:3,
      borderBottom:`none`,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
      boxShadow: 'black'

    },
  },
  expandIcon: {
    top: '60% !important',
    color:theme.palette.primaryText.main
  },
  expanded: {
  },
}))(props => <MuiExpansionPanelSummary {...props} />);
ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0,
    flexDirection:'column',
    background:theme.palette.background.main
  },
}))(MuiExpansionPanelDetails);


function DirectAndIndirectUsers(props) {
  const {
    classes,
    theme,
    history,
    state,
    label,
    getTimeText
  } = props;

  const [expanded, setExpanded] = React.useState(-1);
  const [secondExpanded, setSecondExpanded] = React.useState(-1)


  const handleChange = index => (event, newExpanded) => {
    setExpanded(newExpanded ? index : -1);
    if(!newExpanded){
      setSecondExpanded(-1)
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const handleSecondChange = (source,index) => (event, newExpanded) => {
    setSecondExpanded(newExpanded ? (source+index) : -1);
    event.stopPropagation();
    event.preventDefault();
  };


  const onItemClick = (id,collection,label) => {
    onClickResultItem({id, label, collection, history });
  }


  const onClickMember = member => {
    if(state.usageType==='direct'){
      history.push(`/profile/user/${member.id}`)
    }else{
      history.push(`/profile/content/${member.id}`)
    }
  }


  const getSourceTypes = usageData => {
    let sourceTypes = [];
    usageData.forEach(el=>{
      if(!sourceTypes.includes(el.source_type))sourceTypes.push(el.source_type);
    })
    return sourceTypes
  }

  const generateJobTypeTags = names => {
    if(!names || names.length===0)return;
    let arr = names.slice(0,3).map((name,index)=>(
      <KTooltip title={name}>
        <div className={classes.chip} key={name}>
          <span className={classes.chipText}>{name}</span>
        </div>
      </KTooltip>
    ))
    if(names.length>3){
      arr.push(
        <div className={classes.chip} key={'more'}>
          +{names.length-3}
        </div>
      )
    }
    return arr;
  }

  return (
    <div >
      {
        state.usagePanelLoading  &&
        <div style={{ paddingBottom: '5rem', display: 'flex', justifyContent: 'center', marginTop: 100 }}>
          {/* <CircularProgress /> */}
          <div>
            <Typography className={classes.loadingUsage}> Calculating usage.</Typography>
            <Typography className={classes.loadingUsage}>This may take some time to complete.</Typography>
            <LinearProgress style={{ marginTop: '1.75rem' }} color="secondary" />
          </div>
        </div>
      }
      {
        state.usagePanelError && !state.usagePanelLoading &&
        <Typography className={classes.loadingUsage}>Error occurred loading usage data</Typography>
      }
      {
        !state.usagePanelLoading && !state.usagePanelError && state.usagePanelData?.data?.length>0 &&
          <div>
            <Typography color='primary' className={classes.usageTitle}>{getTimeText()}</Typography>
            <Typography className={classes.usageSubtitle}>
              {state.usageType==='direct'?'Users by Teams':`Content by tools that used this ${toTitleCase(label)}`}
            </Typography>
            <div className={classes.expansionPanelList}>
              {
                state.usagePanelData?.data && state.usageType==='direct' &&
                state.usagePanelData.data.map((item,index) => (
                  <div>
                    <ExpansionPanel
                      expanded={expanded === index}
                      className={classes.expansionPanel}
                      onChange={handleChange(index)} key={'type'}>
                      <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon style={{ paddingTop: '1rem !important' }} />}>
                        <div className={classes.icon}>
                          {
                            getIconComponent({
                              label: item.object_name,
                              size: 24,
                              colour: expanded === index ? theme.palette.primary.main : theme.palette.primaryText.light
                            })
                          }
                        </div>
                        <Typography variant='body1' className={classes.itemName} style={{ color: theme.palette.primaryText.main }}>
                          {item.name}
                        </Typography>
                        <div className={classes.avatar_display}>
                          {
                            item.members &&
                            <Typography className={classes.usedBy}>Used by</Typography>
                          }
                          {
                            item.members &&
                            item.members.slice(0, 5).map((m, i) =>
                              <KTooltip
                                title={m.name}
                                onClick={(event) => onItemClick(m.id, 'people','user')}
                              >
                                <Avatar
                                  className={classes.avatar}
                                  key={i}
                                >
                                  {getInitials(m.name)}
                                </Avatar>
                              </KTooltip>
                            )
                          }
                          {
                            (item.members && item.members.length > 5) &&
                            <div className={classes.memberLength}>+{item.members.length - 5}</div>
                          }
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <List component="nav" className={classes.list}>
                          {item.members.map((el) => (
                            <div>
                              <Divider className={classes.divider} style={{ marginLeft: '16px' }} />
                              <ListItem
                                classes={{
                                  root: classes.listItemRoot,
                                  selected: classes.listItemSelected,
                                }}
                                button
                                onClick={(event) => onClickMember(el)}
                              >
                                <ListItemIcon>
                                  <Avatar
                                    className={classes.avatar}
                                  >
                                    {getInitials(el.name)}
                                  </Avatar>
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText} primary={el.name}/>
                                {
                                  generateJobTypeTags(el.job_type_kc_txts)
                                }
                                <ListItemIcon className={classes.iconRoot}>
                                  <OpenIcon className={classes.openIcon} />
                                </ListItemIcon>
                              </ListItem>

                            </div>
                          ))}
                        </List>

                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                ))
              }

              {
                state.usagePanelData && state.usagePanelData.data.length>0 && state.usageType==='indirect' &&
                getSourceTypes(state.usagePanelData.data).map((source,index) => (
                  <div style={{marginBottom:16}}>
                    <Typography color='secondary' style={{fontSize:12,letterSpacing:2,marginBottom:8}}>
                      {`${source} SERVER`}
                    </Typography>
                    <div>
                      {
                        state.usagePanelData.data.filter(obj=>obj.source_type===source).map((obj,secondIndex)=>(
                          <ExpansionPanel
                            expanded={secondExpanded === (source+secondIndex)}
                            className={classes.expansionPanel}
                            onChange={handleSecondChange(source,secondIndex)} key={'type'}>
                            <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon style={{ paddingTop: '1rem !important' }} />}>
                              <div className={classes.icon}>
                                {
                                  getIconComponent({
                                    label: obj.object_name,
                                    size: 24,
                                    colour: secondExpanded === (source+secondIndex) ? theme.palette.primary.main : theme.palette.primaryText.light
                                  })
                                }
                              </div>
                              <Typography variant='body1' className={classes.itemName} style={{ color: theme.palette.primaryText.main }}>
                                {`${obj.members.length} ${toTitleCase(mapObjectName(obj.object_name).replace(/_/g,' '))}(s)`}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <List component="nav" className={classes.list}>
                                {obj.members.map((el) => (
                                  <div>
                                    <Divider  className={classes.divider} style={{ marginLeft: '16px' }} />
                                    <ListItem
                                      classes={{
                                        root: classes.listItemRoot,
                                        selected: classes.listItemSelected,
                                      }}
                                      button
                                      onClick={(event) =>onItemClick(el.id, 'content',obj.object_name)}
                                    >
                                      <ListItemIcon>
                                        {
                                          getIconComponent({
                                            label: obj.object_name,
                                            size: 24,
                                            colour:theme.palette.primary.main
                                          })
                                        }

                                      </ListItemIcon>
                                      <ListItemText className={classes.listItemText} primary={el.name}/>
                                      <ListItemIcon className={classes.iconRoot}>
                                        <OpenIcon className={classes.openIcon}  />
                                      </ListItemIcon>
                                    </ListItem>

                                  </div>
                                ))}
                              </List>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        }
    </div>
  );
}

DirectAndIndirectUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}


export default withTheme()(withStyles(styles)(DirectAndIndirectUsers));
