import React from 'react';
import { withTheme, withStyles, Button, Typography} from '@material-ui/core';
import { toTitleCase } from '../../../../utilities';
import KTooltip from '../../../UI/KTooltip/KTooltip';

const styles = theme => ({
  chip:{
    display:'flex',
    overflow:'hidden',
    height:64,
    paddingTop:10,
    paddingBottom:10,
    background:theme.palette.hovered.main,
    // boxShadow:`4px 2px 8px 2px #aaa`,
    // borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  unSelectedChip:{
    // boxShadow:'none',
    background:'none',
  },
  chipText:{
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:"max-content",
    maxWidth:'100%'
  },
  selector:{
    ...theme.components.selector,
    border:'none',
    width:120,
    '& :focus':{
      background:'#00D46A',
      color:'black',
      borderRadius:8
    },
    '& div div':{
      paddingTop:'6px',
      paddingBottom:'6px',
      paddingLeft:'16px',
      background:'#00D46A',
      color:'black',
      borderRadius:8,
      fontSize:16
    },
    '& svg':{
      marginRight:8,
      color:'black'
    }
  },
  tooltip:{
    fontSize:13.75,
    maxWidth:'100vw'
  },
  button:{
    padding:'2px 8px',
    // width:36,
    flexGrow:0,
    minWidth:36,
    // width:'max-content',
    marginRight:16
  },
  hide:{
    display:'none'
  }
})

function BasicChip(props) {

  const {
    classes,
    theme,
    processedSurvey,
    onSubmit,
    onSkip,
    rawSurveyObject,
    answeringQuestion,
    selectedId,
    setSelectedId,
    buttonWidth=300
  } = props;

  // Each processedSurvey has below attributes:

  // questionTitle
  // actionType
  // description
  // options
  // objectSignature
  // objectLabel

  // const [selectedAnswer, setSelectedAnswer] = useState('placeholder')

  return (
    <div onMouseEnter={()=>setSelectedId(rawSurveyObject.id)} className={classes.chip + (selectedId===rawSurveyObject.id?'':` ${classes.unSelectedChip}`)}>
      <Typography className={classes.chipText} style={{flex:'1 1 500px',paddingLeft:16,whiteSpace:'pre-wrap',maxHeight:42}}>
        {processedSurvey.questionTitle}
      </Typography>
      <div  style={{flex:'2 1 410px',paddingLeft:16, overflow:'hidden'}} >
        <div style={{display:'flex',overflow:'hidden'}}>
          <Typography className={classes.chipText} style={{textDecoration:'none', marginRight:8}}>{toTitleCase(processedSurvey.objectLabel)}: </Typography>
          <KTooltip title={processedSurvey.objectName} onClick={processedSurvey.onItemClick} style={{cursor:'pointer',textDecoration:'underline',color:theme.palette.hyperLink.main}} classes={{tooltip:processedSurvey.objectSignature===''?classes.hide:classes.tooltip}}>
            <Typography className={classes.chipText}>
            {processedSurvey.objectName}
            </Typography>
          </KTooltip>
        </div>
        <KTooltip title={processedSurvey.objectSignature} classes={{tooltip:processedSurvey.objectSignature===''?classes.hide:classes.tooltip}}>
          <Typography className={classes.chipText} style={{marginTop:2, color:theme.palette.primaryText.light}}>
            {processedSurvey.objectSignature}
          </Typography>
        </KTooltip>
      </div>
      <div className={classes.chipText} style={{flex:`0 0 ${buttonWidth}px`,display:'flex',alignItems:'center',paddingLeft:16}}>
        <div style={{display:'flex'}}>
          {
            processedSurvey.options.map(el=>(
              <Button keu={el.name} disabled={answeringQuestion!==-1} variant='outlined' className={classes.button} color='primary' value={el.name} onClick={()=>onSubmit(rawSurveyObject,[el.name])}>
                {el.name}
              </Button>
            ))
          }
        </div>
        <Button disabled={answeringQuestion!==-1} style={{marginLeft:0,marginRight:32,flexShrink:0,flexGrow:0}} variant='outlined' className={classes.button} color='secondary' onClick={()=>onSkip(rawSurveyObject)}>
          SKIP
        </Button>
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(BasicChip));
