import React, { useState } from 'react';
import { withTheme,withStyles, CircularProgress, Typography,IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent } from '../../../utilities';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import KTooltip from '../../UI/KTooltip/KTooltip';
import ConfigModal from './ConfigModal';

const styles = theme => ({
  root: {
  },
  dataBoxes: {
    marginBottom: '2.5rem'
  },
  caption:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:4
  },
  listView: {
    flexBasis: 400,
    flexGrow: 1
  },
  dataBox: {
    margin: '0 1.5rem 1.5rem 0'
  },
  subtitle: {
    fontSize: '0.75rem',
    color: theme.palette.primaryText.light,
    fontWeight: 400,
    marginBottom: '2rem'
  },
  block: {
    marginTop: '3.5rem'
  },
  headerText: {
    height: '32px',
    color: theme.palette.primary.main,

    fontSize: '11.78px',
    fontWeight: 500,
    letterSpacing: '2px',
    lineHeight: '16px'
  },
  noSettings: {
    color:theme.palette.primaryText.main,
    width: '240px',
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  listItem:{
    height:48,
    boxSizing:'border-box',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  hoverable:{
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listItemText:{
    color:theme.palette.primaryText.main,
    width:'100%',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  sectionHeaderContainer:{
    height:48,
    display:'flex',
    alignItems:'center',
    cursor:'pointer'
  },
  groupHeader:{
    color:theme.palette.primary.main,
    letterSpacing:1.5,
    marginRight:8,
    fontSize:13,
  },
  collapseIcon:{
    width:20,
    height:20,
    transition: "transform .3s ease",
  },
  sectionListContainer:{
    overflow:'hidden',
    transition: "height .3s ease",
    marginBottom:16,
  }
});

const Settings = props => {

  const {
    classes,
    theme,
    state,
    dispatch
  } = props;

  const [modalOpen, setModalOpen] = useState(false)
  const [clickedItem, setClickedItem] = useState()

  const [hoveringButton, setHoveringButton] = useState(false)

  const editSetting = (item) => {
    setModalOpen(true)
    setClickedItem(item);
  }

  const sortSettings = (a,b) => {
    if (a.display_name < b.display_name) return -1;
    else return 1;
  }


  const getSettingItem = item => {
    return (
      <div className={classes.listItem + (hoveringButton?'':" "+classes.hoverable)}  style={{ display: 'flex',alignItems:'center' }}>
        <div style={{ flex:'0 0 80px',height:24 }}>
          <KTooltip title={item.description} style={{ flexGrow: 0 }}>
            <InfoIcon style={{ width: 24, height: 24, color: theme.palette.primaryText.light, marginRight: 16, marginLeft: 8 }} />
          </KTooltip>
        </div>
        <div style={{ flex:'1 0 350px',marginRight:24,overflow:'hidden' }}>
          {
            <KTooltip title={item.display_name} style={{ flexGrow: 0 }}>
              <Typography variant='body1' className={classes.listItemText} >{item.display_name} </Typography>
            </KTooltip>
          }
        </div>
        <div style={{ flex:'1 0 320px',marginRight:16,overflow:'hidden' }}>
        {
          item.value.length>45 ?
          <KTooltip title={item.value} style={{ flexGrow: 0 }}>
            <Typography variant='body1' className={classes.listItemText} style={{fontWeight:700}}>{item.value} </Typography>
          </KTooltip> :
          <Typography variant='body1' className={classes.listItemText} style={{fontWeight:700}}>{item.value} </Typography>
        }
        </div>
        <div style={{ flex:'0 0 80px' }} >
          <IconButton onClick={() => editSetting(item)} onMouseEnter={()=>setHoveringButton(true)} onMouseLeave={()=>setHoveringButton(false)} >
            {getIconComponent({ label: 'edit', size: 25, colour:theme.palette.primaryText.light })}
          </IconButton>
        </div>
      </div>
    )
  }

  const generateGroups = (list) => {
    let groups = [];
    list.forEach(i=>{
      if(!groups.find(g=>g.name===i.category)){
        groups.push({
          name:i.category,
          items:[i]
        })
      }else{
        groups.find(g=>g.name===i.category).items.push(i)
      }
    })
    groups.sort((a,b)=>a.name<b.name?-1:1)
    groups.forEach(g=>{
      g.height = g.items.length * 48
    })
    return groups;
  }

  if (state.settingsError || state.jobsError ) return <Typography style={{color:theme.palette.primaryText.main}}>Error loading settings.</Typography>

  if (state.settingsLoading || state.jobsLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '5%' }}>
        <CircularProgress color="secondary" />
        <Typography style={{color:theme.palette.primaryText.main}}>Loading</Typography>
      </div>
    )
  }

  if(!state.settingsData ||  !state.jobsData)return <div></div>

  return (
    <div className={classes.root} style={{ paddingBottom: '5rem' }}>
      <div style={{position:'sticky',background:theme.palette.background.main,paddingBottom:16,top:180,zIndex:9}}>
        <Typography style={{color:theme.palette.header.main,fontSize:20}}>SETTINGS</Typography>
        <Typography className={classes.caption}>Click EDIT to update a platform setting. You can reset to the default value by selecting SET TO DEFAULT.</Typography>
      </div>
      <div>
        {state.settingsData && state.settingsData.length === 0 && state.jobsData && state.jobsData.length === 0 && <Typography variant='body2' className={classes.noSettings} style={{ marginTop: '1rem' }} >{'No items to be displayed'} </Typography>}
        {
          state.settingsData && state.settingsData.length>0 &&
          <div style={{ display: 'flex',position:'sticky',background:theme.palette.background.main,top:234,zIndex:9}}>
            <div style={{ flex:'0 0 80px' }}> <Typography variant='body1' color='primary' className={classes.headerText} >{''} </Typography> </div>
            <div style={{ flex:'1 0 350px',marginRight:24 }}> <Typography variant="body1" color='primary' className={classes.headerText} >{'SETTING'} </Typography> </div>
            <div style={{ flex:'1 0 320px',marginRight:16 }}> <Typography variant="body1" color='primary' className={classes.headerText} >{'VALUE'} </Typography> </div>
            <div style={{ flex:'0 0 80px' }}><Typography variant="body1" color='primary' className={classes.headerText} >{'ACTION'} </Typography> </div>
          </div>
        }
        {
          state.settingsData && generateGroups(state.settingsData).map(g=>(
            <div>
              <div
                className={classes.sectionHeaderContainer}
                data-test-classname="setting-group-header"
                onClick={()=>{
                  if(state.collapsedSettingGroup.includes(g.name)){
                    dispatch({type:'set_collapsed_setting_group',collapsedSettingGroup:state.collapsedSettingGroup.filter(el=>el!==g.name)})
                  }else{
                    dispatch({type:'set_collapsed_setting_group',collapsedSettingGroup:[...state.collapsedSettingGroup,g.name]})
                  }
                }}
              >
                <Typography className={classes.groupHeader}>
                  {g.name}
                </Typography>
                <div className={classes.collapseIcon} style={{transform:state.collapsedSettingGroup.includes(g.name)?'rotate(-90deg)':'rotate(0deg)'}}>
                  {getIconComponent({label:'triangle_down',size:20,colour:theme.palette.primary.main})}
                </div>
              </div>
              <div className={classes.sectionListContainer} style={{height:state.collapsedSettingGroup.includes(g.name)?0:g.height,marginBottom:state.collapsedSettingGroup.includes(g.name)?0:16}}>
                {
                  g.items.sort(sortSettings).map(i=>getSettingItem(i))
                }
              </div>
            </div>
          ))
        }
      </div>

      <ConfigModal
        key={clickedItem?.id}
        state={state}
        dispatch={dispatch}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        clickedItem={clickedItem}
        setClickedItem={setClickedItem}
      />

    </div>

  )
}


Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nodeKey: PropTypes.string.isRequired,
  memberData: PropTypes.array,
  memberNum: PropTypes.number,
  dispatch: PropTypes.func
}

export default withTheme()(withStyles(styles)(Settings));
