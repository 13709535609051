import React, {useEffect, useRef, useState} from 'react';
import { withTheme, withStyles, LinearProgress, Typography, Select, MenuItem, Paper, Popper, ClickAwayListener, MenuList } from '@material-ui/core';
import PropTypes from 'prop-types';
import SummaryChart from '../SummaryChart/SummaryChart';
import DirectAndIndirectUsers from '../DirectAndIndirectUsers/DirectAndIndirectUsers';
import { toTitleCase, getIconComponent, getUserRoles, getJobResult } from '../../../../utilities'
import axiosCerebrum from '../../../../axios-cerebrum'
import { useStore } from 'react-redux'
import fileDownloader from 'js-file-download';
import timezoneDetect from 'jstimezonedetect';
import moment from 'moment';
import useAlert from '../../../../hooks/useAlert';
import axiosSolr from '../../../../axios-solr';

const styles = theme => ({
  root: {
  },
  subtitle: {
    fontSize: '0.75rem',
    color: theme.palette.primaryText.light,
    fontWeight: 400,
    marginTop:8,
  },
  selector:{
    ...theme.components.titleSelector
  },
  usageTitle: {
    height: '24px',
    width: '240px',
    color: theme.palette.header.main,
    fontSize: 16,
    letterSpacing: '0.15px',
    lineHeight: '24px',
    marginTop: '24px'
  },
  noUsageSubtitle: {
    height: '16px',
    // width: '264px',
    color: theme.palette.primaryText.light,

    fontSize: '11.78px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    marginBottom: '16px',
    marginTop: '8px'
  },
  loadingUsage: {
    //height: '48px',
    //width: '280px',
    color: theme.palette.primaryText.main,
    fontFamily: 'Roboto',
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    //lineHeight: '24px',
    textAlign: 'center'
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
	listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  errorMsg:{
    fontSize:16,
    color:theme.palette.primaryText.light
  },
  usageTitleTwo: {
    width: '100%',
    color: theme.palette.header.main,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    marginTop: '24px'
  },
  usageSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
})


function Dashboard(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
    label,
    drillThrough
  } = props;


	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
  const activeSeries = state.usageActiveSeries || [];
  const setActiveSeries = s => dispatch({type:'set_usage_active_series',usageActiveSeries:s})

  const usageApiID = useRef(1)
  const detailApiID = useRef(1)

  const store = useStore();
  let sessionData = store.getState().auth.session_user;
  let roles = getUserRoles(sessionData.user_role)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef,
    id:'usage_report_'+state.basicData.id
  })

  useEffect(()=>{
    if(!state.usageActiveSeries){
      setActiveSeries(['query','data_pipeline'].includes(label)?['Runs']:['Reads'])
    }
    return ()=>{
      isCancelledRef.current = true
    }
  // eslint-disable-next-line
  },[])

  let usageData;

  if (state.usageData &&  state.usageData.main) {
    usageData = state.usageData.main[0];
  }

  const loadUsageData = (callID, usageType = state.usageType, period = state.usagePeriod) => {
    dispatch({type:'set_usage_data',usageLoading:true});
    if(drillThrough)dispatch({type:'set_usage_panel_data'});
    axiosCerebrum
      .post(
        '/api/platformbatches/0',
        {
          "adhoc_args":{
            "job": "_0010_usage",
            "object_name": state.basicData.object.name,
            "id": state.basicData.id,
            "tz": timezoneDetect.determine().name(),
            'usage_type':usageType.toUpperCase(),
            period
          }
        }
      )
      .then(response=>{
        if(callID!==usageApiID.current)return;
        let id = response.data.id;
        getJobResult(
          id,
          data=>{
            callID===usageApiID.current && dispatch({type:'set_usage_data',usageData:data.result})
          },
          ()=>callID===usageApiID.current && dispatch({type:'set_usage_data',usageError:true})
        )
      })
      .catch(error=>{
        console.log(error);
        dispatch({type:'set_usage_data',usageError:true})
      })
  }

  const enrichMembersData = async (dataset) => {
    let allMembers = [];
    dataset.data?.forEach(el=>{
      allMembers.push(...(el.members || []))
    })
    if(allMembers.length===0)return;
    await axiosSolr
      .post(
        `/solr/search/select`,{
          params:{
            q:"*",
            fq:`id:(${allMembers.map(el=>el.id).join(' OR ')})`,
            rows:allMembers.length
          }
        }
      )
      .then(response=>{
        dataset.data?.forEach(el=>{
          el.members.forEach((member,i)=>{
            let memberData = response.data.response.docs.find(doc=>doc.id===member.id) || {};
            dataset.data[dataset.data.indexOf(el)].members[i] = {...member,...memberData}
          })
        })
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const loadUsagePanelData = (callID, view) => {
    // if(state.usageLoading)return;
    dispatch({type:'set_usage_panel_data',usagePanelLoading:true});
    let date = moment(state.selectedDate?.date).format('YYYY-MM-DD');
    axiosCerebrum
      .post(
        '/api/platformbatches/0',
        {
          "adhoc_args":{
            "job": "_0020_drill_through_usage",
            "object_name": state.basicData.object.name,
            "id": state.basicData.id,
            "tz": timezoneDetect.determine().name(),
            "date": moment(state.selectedDate?.date).format('YYYY-MM-DD'),
            "period":state.usagePeriod,
            "usage_type":state.usageType.toUpperCase()
          }
        }
      )
      .then(response=>{
        if(callID!==detailApiID.current)return;
        let id = response.data.id;
        getJobResult(
          id,
          data=>{
            if(callID!==detailApiID.current)return;
            let dataset = data.result.main[0]?.disp_data.datasets;
            if(view==='Reads'){
              dataset = dataset.filter(el=>el.labels==='Read')
            }
            if(view==='Writes'){
              dataset = dataset.filter(el=>el.labels==='Write')
            }
            if(view==='Modifies'){
              dataset = dataset.filter(el=>el.labels==='Modified')
            }
            if(view==='Runs'){
              dataset = dataset.filter(el=>el.labels==='Run')
            }
            if(view==='Downloads'){
              dataset = dataset.filter(el=>el.labels==='Downloads')
            }
            enrichMembersData(dataset[0])
            dispatch({type:'set_usage_panel_data',usagePanelData:{...dataset[0], date}})
          },
          ()=>callID===detailApiID.current && dispatch({type:'set_usage_panel_data',usagePanelError:true})
        )
      })
      .catch(error=>{
        console.log(error);
        dispatch({type:'set_usage_panel_data',usagePanelError:true})
      })
  }

  useEffect(()=>{
    if(!state.usageData){
      usageApiID.current += 1;
      loadUsageData(usageApiID.current)
    }
    // eslint-disable-next-line
  },[])


  useEffect(() => {
    if (!state.selectedDate || !drillThrough || !state.usageData) return;
    // if(moment(state.selectedDate?.date).format('YYYY-MM-DD')===state.usagePanelData?.date)return;
    detailApiID.current += 1;
    loadUsagePanelData(detailApiID.current, state.selectedDate.variant);
  // eslint-disable-next-line
  }, [state.selectedDate])


  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const onClickDownloadReport = () => {
    setListActionOpen(false)
    let currentPeriod = state.usagePeriod * 30;
    let payload={
      type:'EXTRACTS',
      domain:document.location.protocol + "//" + document.location.hostname,
      lookup_code:'0330',
      filters:{
        object_id:state.basicData.id,
        period:currentPeriod
      }
    }
    sendAlert({
      message: "Usage report is being generated. This may take a few minutes.",
      type: 'info'
    })
    axiosCerebrum.post(
      '/api/extracts',payload
    ).then(extractResponse=>{
      // history.push('/question_board?tabName=EXTRACTS')
      let extractId = extractResponse.data.id;
      let downloaded = false;
      let previousReturned = true;
      let recursionTimes = 0;

      const onError = (interval) => {
        clearInterval(interval);
        dispatch({type:'set_running_code',runningCode:''})
        sendAlert({
          message: "Error occurred downloading the report",
          type: 'error'
        })
      }

      let downloadExtractInterval = setInterval(()=>{
        if(!previousReturned)return;
        if(recursionTimes>200){
          onError(downloadExtractInterval)
          return;
        }
        recursionTimes += 1;
        previousReturned=false;
        axiosCerebrum.get(`/api/extracts/${extractId}`).then(response=>{
          previousReturned = true;
          if(response.data.status==='COMPLETE'){
            if(downloaded)return;
            downloaded = true;
            axiosCerebrum.get(`/api/extracts/${extractId}/download`)
              .then(downloadRes=>{
                clearInterval(downloadExtractInterval);
                fileDownloader(
                  downloadRes.data.replace(/\|/g,','),
                  `K_${state.basicData.object.name.toLowerCase()}_usage_report_${state.basicData.name}_${currentPeriod}d.csv`);
              })
              .catch(error=>{
                console.log(error)
                onError(downloadExtractInterval);
              })
          }
          if(response.data.status==='FAILED'){
            onError(downloadExtractInterval);
          }
        }).catch(error=>{
          console.log(error);
          onError(downloadExtractInterval);
          previousReturned = true;
        })
      },2000)
    })
    .catch(error=>{
      console.log(error);
      dispatch({type:'set_running_code',runningCode:''})
      sendAlert({
        message: "Error occurred downloading the report",
        type: 'error'
      })
    })
  }

  const getTimeText = () => {
    let variant = state.selectedDate?.variant.slice(0,-1)
    switch(state.usagePeriod){
      case -1:
        return variant + ' usage on ' + moment(state.selectedDate?.date).format('DD MMM, YYYY')
      case 6:
        return variant + ' usage from ' + moment(state.selectedDate?.date).format('DD MMM')+ ' - ' + moment(state.selectedDate?.date).add(1,'week').add(-1,'day').format('DD MMM, YYYY')
      case 12:
        return variant +' usage from ' + moment(state.selectedDate?.date).format('DD MMM')+ ' - ' + moment(state.selectedDate?.date).add(1,'month').add(-1,'day').format('DD MMM, YYYY')
      default:
        return variant + ' usage on ' + moment(state.selectedDate?.date).format('DD MMM, YYYY')
    }
  }

  return (
    <div className={classes.root} style={{ marginBottom: '5rem' }}>

      <div style={{display:'flex',alignItems:'center'}}>
        {
          ['database','schema', 'macro','procedure','table','column','file','dataset','dataset_table','dataset_field'].includes(label) ?
          <Select
            className={classes.selector}
            value={state.usageType}
            style={{marginRight:4}}
            onChange={event=>{
              dispatch({type:'set_usage_type',usageType:event.target.value})
              usageApiID.current += 1;
              loadUsageData(usageApiID.current,event.target.value,state.usagePeriod)
            }}
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
            disableUnderline
            renderValue={el=>{
              return el==='direct'?'USAGE BY USERS':'USAGE BY CONTENT'
            }}
          >
            <MenuItem  className={classes.menuItem} value={'direct'}>
              Usage by Users
            </MenuItem>
            <MenuItem  className={classes.menuItem} value={'indirect'}>
              Usage by Content
            </MenuItem>
          </Select>
          :
          <Typography  style={{ fontSize: 20,color:theme.palette.header.main}}>USAGE BY {state.usageType==='direct'?'USERS':'CONTENT'}</Typography>
        }
        <Typography style={{ fontSize: 20 ,marginRight:6,marginLeft:6,color:theme.palette.header.main}}>OVER</Typography>
        <Select
          className={classes.selector}
          style={{marginLeft:4}}
          value={state.usagePeriod}
          disableUnderline
          onChange={event=>{
            dispatch({type:'set_usage_period',usagePeriod:event.target.value})
            usageApiID.current += 1;
            loadUsageData(usageApiID.current,state.usageType,event.target.value)
          }}
          MenuProps={{
            classes:{
              paper:classes.selectPaper
            }
          }}
          renderValue={el=>{
            if(el===3)return '90 DAYS'
            if(el===6)return '6 MONTHS'
            if(el===12)return '1 YEAR'
          }}
        >
          <MenuItem  className={classes.menuItem} value={3}>
            90 days (by days)
          </MenuItem>
          <MenuItem  className={classes.menuItem} value={6}>
            6 months (by week)
          </MenuItem>
          <MenuItem  className={classes.menuItem} value={12}>
            1 year (by month)
          </MenuItem>
        </Select>
        <div style={{flexGrow:1}}></div>
        {
          ['10','40'].some(r=>roles.includes(r)) &&
          <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
            {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
          </div>
        }
        <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
          <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
            <Paper style={{marginTop:12,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
              <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
              <MenuList className={classes.listContainer}>
                <MenuItem onClick={()=>{onClickDownloadReport()}} className={classes.menuItem} >
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Download usage report</Typography>
                </MenuItem>
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>

      {
         state.usageLoading
          ?
          <div style={{ marginBottom: '5rem', display: 'flex', justifyContent: 'center', marginTop: 100 }}>
            {/* <CircularProgress /> */}
            <div>
              <Typography className={classes.loadingUsage}> Calculating usage.</Typography>
              <Typography className={classes.loadingUsage}>This may take some time to complete.</Typography>
              <LinearProgress style={{ marginTop: '1.75rem' }} color="secondary" />
            </div>
          </div>
          :
          (
            state.usageError
              ?
              <div style={{ marginBottom: '5rem', display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                <div style={{textAlign:'center'}}>
                  <Typography style={{fontSize:24,color:theme.palette.primary.main,marginBottom:24}}>
                    (╯°□°)╯︵ ┻━┻
                  </Typography>
                  <Typography className={classes.errorMsg} style={{marginBottom:32}}>Argh! Looks like it's a busy period and this request is taking too long.</Typography>
                  <Typography className={classes.errorMsg}>Try again soon</Typography>
                </div>
              </div>
              :
              usageData &&
              <div >
                <SummaryChart
                  data={usageData.disp_data.datasets}
                  objectLabel={label}
                  labels={usageData.disp_data.labels}
                  dispatch={dispatch}
                  state={state}
                  replacedByDate={state.basicData.replace_date}
                  replacedByDateIndex={usageData.disp_data.labels.indexOf(state.basicData.replace_date)}
                  activeSeries={activeSeries}
                  setActiveSeries={setActiveSeries}
                />
                {
                  (
                    (!usageData.disp_data.datasets[0] || Math.max(...usageData.disp_data.datasets[0].data) === 0 || usageData.disp_data.datasets[0].data.length===0) &&
                    (!usageData.disp_data.datasets[1] || Math.max(...usageData.disp_data.datasets[1].data) === 0 || usageData.disp_data.datasets[1].data.length===0) &&
                    (!usageData.disp_data.datasets[2] || Math.max(...usageData.disp_data.datasets[2].data) === 0 || usageData.disp_data.datasets[2].data.length===0)
                  )
                  && !state.selectedDate ?
                    <div>
                      <Typography color='primary' className={classes.usageTitle}>Usage details</Typography>
                      <Typography className={classes.normalText} style={{marginTop:16}}>
                        {`The ${toTitleCase(label)} has not been used in the last `}
                          {state.usagePeriod===3 && '90 days'}
                          {state.usagePeriod===6 && '6 months'}
                          {state.usagePeriod===12 && '1 year'}
                      </Typography>
                    </div>
                    :
                    !state.selectedDate && drillThrough ?
                      <div>
                        <Typography color='primary' className={classes.usageTitle}>Usage details</Typography>
                        <Typography className={classes.noUsageSubtitle} style={{ width: '553px !important' }}>Select a day on the chart above to see usage details</Typography>
                      </div>
                      :
                      null
                }
                {
                  drillThrough &&
                  <div>
                    {
                      state.selectedDate && state.usagePanelLoading  ?
                        <div style={{ marginBottom: '5rem', display: 'flex', justifyContent: 'center', marginTop: 100 }}>
                          {/* <CircularProgress /> */}
                          <div>
                            <Typography className={classes.loadingUsage}> Calculating usage.</Typography>
                            <Typography className={classes.loadingUsage}>This may take some time to complete.</Typography>
                            <LinearProgress style={{ marginTop: '1.75rem' }} color="secondary" />
                          </div>
                        </div>
                        :
                        (state.usagePanelError) ? <Typography className={classes.loadingUsage}>Error occurred loading usage data</Typography> :
                          (state.usagePanelData ) ?
                            <DirectAndIndirectUsers
                              dispatch={dispatch}
                              state={state}
                              label={label}
                              history={history}
                              activeSeries={activeSeries}
                              getTimeText={getTimeText}
                            /> : null
                    }
                  </div>
                }
              </div>
          )
      }
    </div>
  )
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  drillThrough: PropTypes.bool
}

export default withTheme()(withStyles(styles)(Dashboard));
