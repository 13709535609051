import React, { useState , useEffect, useRef} from 'react';
import { withTheme,withStyles, Typography, CircularProgress, Button, IconButton, Modal, Paper} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment'
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getExtractResult, getIconComponent, isInViewport, toTitleCase } from '../../../utilities';
import CancelModal from '../../PlatformManagement/Monitor/CancelModal';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {

  },
  statusChip:{
    width:88,
    textAlign:'center',
    padding:'8px 12px',
    borderRadius:3,
    fontSize:13.75
  },
  tooltip:{
    fontSize:13.75
  },
  columnRow:{
    height:48,
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    display:'flex',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  hoverableRow:{
    // cursor:'pointer',
    // '&:hover':{
    //   background:theme.palette.hovered.main
    // }
  },
  titleRow:{
    display:'flex',
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main
  },
  iconButton:{
    padding:2
  },
  modalContainer:{
    display:'flex',
    width:'100vw',
    height:'100vh',
    alignItems:'center',
    justifyContent:'center',
    '&:hover': {
      outline: 'none',
    },
  },
  paperContainer:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    width:'80vw',
    padding:'24px 24px 8px',
    outline: 'none',
  },
})

const History = props => {

  const {
    classes,
    theme,
    state,
    dispatch
  } = props;

  // const [actionButtonHovered, setActionButtonHovered] = useState(false)
  const [historyData, setHistoryData] = useState({loading:false,error:false});
  const [modalOpen, setModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const unmounted = useRef()

  const [autoRefreshCount, setAutoRefreshCount] = useState(0)
  const refreshInterval = useRef()
  const scrollRef = useRef();

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`code-search-history`,
    isCancelledRef
  })

  useEffect(()=>{
    return () => {
      isCancelledRef.current = true
    }
  },[])

  const loadHistory = ({page=1,autoRefresh=false}) => {
    if(!autoRefresh)setHistoryData({loading:true})
    if(page===1){
      setAutoRefreshCount()
      clearInterval(refreshInterval.current)
    }
    axiosCerebrum
      .get(
        `/api/me/extracts`,
        {params:{
          per_page:10,
          sort:'START_DESC',
          page:page,
          status:'COMPLETE,FAILED,RUNNING,SUBMITTED,KILLED',
          type:`CODE_SEARCH`,
        }}
      )
      .then(async response=>{
        if(response.data.total===0){
          setHistoryData({loading:false,data:response.data})
        }else{
          let list = response.data.items
          setHistoryData({loading:false,data:{...response.data,items:list}})
          if(!unmounted.current && page===1){
            clearTimeout(refreshInterval.current)
            setAutoRefreshCount(30)
            let count = 30;
            refreshInterval.current = setInterval(()=>{
              count-=1;
              setAutoRefreshCount(count)
              if(count===0){
                setAutoRefreshCount()
                clearInterval(refreshInterval.current)
                loadHistory({page:1,autoRefresh:true})
              }
            },1000)
          }
        }
      })
      .catch(error=>{
        console.log(error);
        setHistoryData({loading:false,error:true})
      })
  }

  useEffect(()=>{
    loadHistory({})
    return ()=>{
      unmounted.current = true;
      clearInterval(refreshInterval.current)
    }
     // eslint-disable-next-line
  },[])

  const onReSubmit = extract => {

    let filters = extract.args?.filters || {};

    dispatch({
      type:'set_source_ids',
      sourceIDs:filters.source_ids || []
    })
    dispatch({
      type:'set_object_types',
      objectTypes:filters.object_types || []
    })
    dispatch({
      type:'set_max_limit',
      maxLimit:filters.limit || 1000
    })
    dispatch({
      type:'set_search_str',
      searchStr:filters.search_term || ''
    })
    dispatch({type:'set_step',step:3})
    dispatch({type:'set_tab_state',tabState:0})
  }

  const onClickHistory = extract => {
    if(extract.status!=='COMPLETE')return;
    sendAlert({type:'info',message:'Download started'})
    getExtractResult({
      id:extract.id,
      defaultFileName:`code_search_results_${extract.args?.filters?.search_term}.csv`,
      onError:()=>{
        sendAlert({type:'error',message:'Error occurred downloading the data, please try again'})
      }
    })
  }


  const getIcon = extract => {
    if(extract.status==='RUNNING'){
      return <div className={classes.statusChip} style={{background:'#FC642D',color:'white'}}>Running</div>
    }
    if(extract.status==='COMPLETE'){
      return <div className={classes.statusChip} style={{background:'#48D46A',color:'black'}}>Complete</div>
    }
    if(extract.status==='SUBMITTED'){
      return <div className={classes.statusChip} style={{background:'#FFAB00',color:'black'}}>Submitted</div>
    }
    if(extract.status==='FAILED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Failed</div>
    }
    if(extract.status==='KILLED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Cancelled</div>
    }
  }

  const getDetails = el => {
    let txt = '';
    let filters = el.args?.filters;
    if(!filters)return txt;
    if(filters.source_ids){
      txt += `Sources: ${filters.source_ids.map(id=>state.sourceList?.find(s=>s.id===id)?.name || id).join(', ')}. `
    }
    if(filters.object_types){
      txt += `Object Types: ${filters.object_types.map(el=>toTitleCase(el.replace(/_/g,' '))).join(', ')}. `
    }
    if(filters.limit){
      txt += `Limit: ${filters.limit}. `
    }
    if(filters.search_term){
      txt += `Search Term: ${filters.search_term}`
    }
    return txt
  }

  window.onscroll = () => {
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
      clearInterval( refreshInterval.current)
      setAutoRefreshCount()
    }
  }

  useEffect(()=>{
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
    }
  // eslint-disable-next-line
  },[historyData.data,historyData.loading])


  return (
    <div className={classes.root}>
      <div style={{zIndex:9,background:theme.palette.background.main,paddingBottom:16,position:'sticky',top:150}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start',marginBottom:24,marginTop:16}}>
          <div>
            <Typography  style={{fontSize:20,color:theme.palette.header.main,}}>{historyData.data?historyData.data.items.length:0} RESULT(S)</Typography>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>Available code search results to download</Typography>
          </div>
          <Button color='primary' style={{height:36,flexShrink:0,background:autoRefreshCount?theme.palette.success.main:undefined,color:autoRefreshCount?'#000':undefined}} variant='contained' onClick={()=>loadHistory({})}>
            REFRESH {autoRefreshCount && `(${autoRefreshCount})`}
          </Button>
        </div>
        <div className={classes.titleRow}>
          <div className={classes.tableTitle} style={{width:120,marginLeft:16,flexShrink:0}}>
            STATUS
          </div>
          <div className={classes.tableTitle}  style={{flexGrow:1}}>
            DETAILS
          </div>
          <div className={classes.tableTitle}  style={{width:200,flexShrink:0}}>
            CREATED ON
          </div>
          <div className={classes.tableTitle}  style={{width:130,flexShrink:0}}>
            ACTIONS
          </div>
        </div>
      </div>
      {
        historyData.error &&
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading history</Typography>
      }
      {
        historyData.data && historyData.data.total===0 &&
        <Typography style={{color:theme.palette.primaryText.main}}>No history found</Typography>
      }
      {
        historyData.data && historyData.data.items.map(el=>(
          <div className={classes.columnRow} data-test-classname="ia-list-item">
            <KTooltip classes={{tooltip:classes.tooltip}} title={el.status.toLowerCase()}>
              <div style={{width:120,display:'flex',alignItems:'center',flexShrink:0,marginLeft:16}}>
                {getIcon(el)}
              </div>
            </KTooltip>
            <KTooltip classes={{tooltip:classes.tooltip}} title={getDetails(el)}>
              <div style={{flexGrow:1,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
                {getDetails(el)}
              </div>
            </KTooltip>
            <div style={{width:200,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
              {moment(el.user_created_at).format('llll')}
            </div>
            <div style={{width:130,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',display:'flex',alignItems:'center'}}>
              {
                el.status==='COMPLETE'?
                <KTooltip classes={{tooltip:classes.tooltip}} title={'Download results'}>
                  <IconButton
                    className={classes.iconButton}
                    style={{marginRight:8}}
                    onClick={(event)=>{event.stopPropagation();onClickHistory(el)}}
                  >
                    {getIconComponent({label:'download',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </KTooltip>
                :
                <div style={{flex:'0 0 24px',marginRight:8}}></div>
              }
              {
                el.status!=='RUNNING' && el.status!=='SUBMITTED'?
                <KTooltip classes={{tooltip:classes.tooltip}} title={'Rerun the search'}>
                  <IconButton
                    className={classes.iconButton}
                    style={{marginRight:8}}
                    onClick={(event)=>{event.stopPropagation();onReSubmit(el)}}
                  >
                    {getIconComponent({label:'refresh',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </KTooltip>
                :
                <div style={{flex:'0 0 24px',marginRight:8}}></div>
              }
              {
                el.status==='FAILED' && el.job_execution?.error ?
                <KTooltip classes={{tooltip:classes.tooltip}} title={'See error detail'}>
                  <IconButton
                    className={classes.iconButton}
                    style={{marginRight:8}}
                    onClick={(event)=>{event.stopPropagation();setModalOpen({error:el.job_execution.error})}}
                  >
                    {getIconComponent({label:'info',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </KTooltip>
                :
                <div style={{flex:'0 0 24px',marginRight:8}}></div>
              }
              {
                ['RUNNING','SUBMITTED'].includes(el.status) &&
                <KTooltip title="Cancel job">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setCancelModalOpen({item:el,type:'single'});
                    }}
                    className={classes.iconButton}
                  >
                    {getIconComponent({label:'clear_circle',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </KTooltip>
              }
            </div>
          </div>
        ))
      }


      <div ref={scrollRef} style={{marginBottom:40,display:'flex',justifyContent:'center'}}>
        {
          historyData.loading &&
          <CircularProgress color='secondary'/>
        }
      </div>

      <CancelModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        loadMoreHistory={loadHistory}
      />

      <Modal
        open={modalOpen}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        hideBackdrop={false}
        className = {classes.modalContainer}
    >
        {
          modalOpen &&
          <Paper className = {classes.paperContainer}>
            <Typography style={{fontSize:20,marginBottom:24,color:theme.palette.header.main}}>
              Error details
            </Typography>
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,whiteSpace:'pre-wrap'}}>{modalOpen.error}</Typography>
            <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
              <Button color='secondary' style={{width:90,height:36,marginTop:'24px'}} onClick={(event)=>{setModalOpen(false);}}>CLOSE</Button>
            </div>
          </Paper>
        }
      </Modal>
    </div>
  )
}

export default withTheme()(withStyles(styles)(History));
