import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, ClickAwayListener, Modal, Button } from '@material-ui/core';
import { getDispFields, getIconComponent } from '../../../utilities';
import { checkClassName, checkId } from '../../UI/Editor/utils';
import KTooltip from '../../UI/KTooltip/KTooltip';

const styles = theme => ({
  root: {
    width: 320,
    height: 56,
    padding: 16,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 3,
  },
  modalBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
  },
  modalContent: {
    padding: 16,
    backgroundColor: theme.palette.background.main,
    borderRadius: 3,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  bodyContent: {
    flexGrow: 1,
    overflow: 'hidden',
    userSelect: 'none',
  },
  title: {
    fontSize: 16,
  },
  subtitle: {
    fontSize: 10,
    color: theme.palette.primaryText.light
  }
})

function SelectionChip(props) {

  const {
    classes,
    theme,
    id,
    object,
    interactiveHeader,
    interactiveBody,
    width,
    height,
    inputWidth,
    disabled,
    isErrorBorder
  } = props;

  const [modalOpen, setModalOpen] = useState(null)

  return (
    <>
      <ClickAwayListener onClickAway={(event) => !checkClassName(event.target, 'selection-chip-modal') && !checkId(event.target, 'search_selector_popper') && setModalOpen(false)}>
        <div
          className={classes.root}
          style={{
            cursor: interactiveBody ? 'pointer' : undefined,
            width,
            height,
            opacity: disabled ? 0.6 : undefined,
            pointerEvents: disabled ? 'none' : undefined,
            border: isErrorBorder ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.listItemDivider.main}`
          }}
          onClick={event => {
            if (interactiveBody) {
              setModalOpen(true)
            }
          }}
          id={id}
        >
          {
            object?.object_type_txt &&
            <div className={classes.icon}>
              {getIconComponent({ label: object.object_type_txt, size: 24, colour: theme.palette.primary.main })}
            </div>
          }
          <div className={classes.bodyContent}>
            <KTooltip title={getDispFields(object, 'dispTitle')}>
              <Typography className={classes.title + ' overflow-text'}>
                {getDispFields(object, 'dispTitle')}
              </Typography>
            </KTooltip>
            {
              object?.id &&
              <KTooltip title={getDispFields(object, 'dispSubtitle')}>
                <Typography className={classes.subtitle + ' overflow-text'}>
                  {getDispFields(object, 'dispSubtitle')}
                </Typography>
              </KTooltip>
            }
          </div>
          {
            interactiveBody &&
            <div className={classes.icon} style={{ marginRight: 0 }}>
              {getIconComponent({ label: 'triangle_down', size: 24, colour: theme.palette.primaryText.main })}
            </div>
          }
        </div>
      </ClickAwayListener>
      {
        interactiveBody &&
        <Modal
          open={Boolean(modalOpen)}
          onClose={() => setModalOpen(false)}
        >
          <div className={`selection-chip-modal ${classes.modalBody}`}>
            <div
              className={classes.modalContent}
            >
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Typography style={{ marginBottom: 16, fontSize: 20 }}>
                  {interactiveHeader}
                </Typography>
                <Button color='primary' id="modal-close-btn" onClick={() => setModalOpen(false)} variant='outlined'>
                  CLOSE
                </Button>
              </div>
              <div style={{ width: inputWidth || 500, marginTop: 6, borderRadius: 3, border: `1px solid ${theme.palette.listItemDivider.main}`, backgroundColor: theme.palette.background.main }}>
                {interactiveBody}
              </div>
            </div>
          </div>
          {/* </ClickAwayListener> */}
        </Modal>
      }
    </>
  )
}

SelectionChip.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  interactiveBody: PropTypes.object,
}

export default withTheme()(withStyles(styles)(SelectionChip));
