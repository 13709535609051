import React from 'react';
import { withTheme, withStyles, Typography} from '@material-ui/core';

const styles = theme => ({
  root:{
    padding:12,
    width:256,
    height:104,
    border:`1px solid ${theme.palette.tileBorder.main}`,
    background:theme.palette.background.main,
    borderRadius:4
  },
  header:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2
  },
  value:{
    fontSize:32,
    color:theme.palette.primaryText.light,
  },
  footer:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginTop:-4
  },
  selectedText:{
    color:theme.palette.background.main
  }
})

function DataTile(props) {

  const {
    classes,
    header,
    value,
    footer,
    rightMargin,
    theme,
    selected
  } = props;


  return (
    <div className={classes.root} style={{marginRight:rightMargin?40:0,background:selected?theme.palette.primary.main:undefined}}>
      <Typography className={classes.header + (selected?' '+classes.selectedText:'')}>{header}</Typography>
      <Typography className={classes.value + (selected?' '+classes.selectedText:'')}>{value}</Typography>
      <Typography className={classes.footer + (selected?' '+classes.selectedText:'')}>{footer}</Typography>
    </div>
  )
}

export default withTheme()(withStyles(styles)(DataTile));
