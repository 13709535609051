import React, {useEffect, useRef, useState} from 'react';
import { withTheme, withStyles, Typography, Button, Modal, Paper, IconButton} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import ScheduleIcon from '@material-ui/icons/QueryBuilder'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import KTooltip from '../../UI/KTooltip/KTooltip';
import { generateScheduleText } from '../../OnBoardSource/utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  listItem:{
    display:'flex',
    alignItems:'center',
    padding:'8px 0px',
    borderBottom:'1px solid #DDD',
    overflow:"hidden",
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  itemText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginLeft:16,
    minWidth:120
  },
  itemTitle:{
    fontSize:16,
    color:theme.palette.primaryText.main
  },
  itemSubtitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light
  },
  modalBlock:{
    marginBottom:24
  },
  modalBlockTitle:{
    color:theme.palette.primaryText.main,
    fontSize:16,
    marginBottom:8
  },
  selector: {
    ...theme.components.selector,
    '& div div':{
      padding:6,
      fontSize:16
    },
    width: '100%',
  },
  button:{
    width:90,
    marginLeft:16
  },
  errorMsg:{
    fontSize:12,
    marginLeft:13.75,
    marginTop:2,
    color:theme.palette.error.main
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    '& input':{
      color:theme.palette.primaryText.main,
    },
  },
  inputLabel:{
    color:theme.palette.primaryText.light
  }
})

const BatchChip = props => {

  const {
    classes,
    theme,
    title,
    subtitle,
    type,
    item,
    dispatch,
  } = props;

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [asAtDate, setAsAtDate] = useState();

  const [modalTitle,setModalTitle] = useState();
  const [modalParams,setModalParams] = useState([]);
  const [modalOpen, setModalOpen] = useState(false)

  const isCancelledRef = useRef(false)
  const [buttonHovered, setButtonHovered] = useState(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const runJob = (db,payload) => {
    if(db){
      axiosCerebrum.post(
        `/api/batches/${db.id}`,payload
      ).then(extractResponse=>{
        sendAlert({type:'info',message:`Source load job for ${db.name} has been started`})
        dispatch({type:'set_tab_state',tabState:0})
      }).catch(error=>{
        console.log(error);
        sendAlert({type:'error',message:`Error occurred running source load job for ${db.name}`})
      })
    }
    else{
      axiosCerebrum.post(
          `/api/platformbatches/${item.id}`,payload
      ).then(extractResponse=>{
        sendAlert({type:'info',message:`Platform load job ${item.name} for has been started`})
        dispatch({type:'set_params',params:{per_page:10,sort:'START_DESC'}})
        dispatch({type:'set_tab_state',tabState:0})
      }).catch(error=>{
        console.log(error);
        sendAlert({type:'error',message:`Error occurred running platform load job ${item.name}`})
      })
    }

  }

  const openModal = () => {
    if(type === 'platform'){
      setModalOpen(true)
      setModalTitle(title);
      setModalParams([])
    }
  }

  const clearStates = () => {
    setStartDate(new Date());
    setEndDate(new Date());
  }

  const checkRequired = () => {
    // handle the source job n platform job
      // if(datasource==='none' && type === 'source')return false;
      if(moment(startDate).diff(moment(endDate),'day') > 0)return false;
      return true;
  }

  const onModalRun = () => {
    let filters;
    if(type === 'platform'){
      if(asAtDate){
        filters = {
          as_at:moment(asAtDate).utc().format('YYYY-MM-DDTHH:mm:ssZ'),
        }
      }else{
        filters = {}
      }
      runJob(false,filters)
      clearStates();
      setModalOpen(false);
      dispatch({type:'set_monitor_tab_state',monitorTabState:0});
    }

  }

  const onModalCancel = () => {
    setModalOpen(false);
    clearStates();
  }


  const onOpenAsAtDate = () => {
    if(modalParams.includes('asAtDate')){
      setModalParams([]);
      setAsAtDate();
    }else{
      setModalParams(['asAtDate'])
      setAsAtDate(moment())
    }
  }

  return (
    <div
      className={classes.listItem}
      style={{background:buttonHovered?theme.palette.background.main:undefined}}
    >
      <KTooltip title={title}>
        <Typography className={classes.itemText} style={{flex:'0 1 300px'}}>
          {title}
        </Typography>
      </KTooltip>
      <KTooltip title={subtitle}>
        <Typography className={classes.itemText} style={{flex:'1 1 300px',color:theme.palette.primaryText.light}}>
          {subtitle}
        </Typography>
      </KTooltip>
      <KTooltip title={generateScheduleText(item)}>
        <Typography className={classes.itemText} style={{flex:'0 1 300px',color:theme.palette.primaryText.light}}>
          {generateScheduleText(item)}
        </Typography>
      </KTooltip>

      <div className={classes.itemText} style={{flex:'0 0 80px',display:'flex'}}>
        {
          [802,999].includes(item.id)?
          <div style={{width:40}}></div>
          :
          <KTooltip title={"Run the job"}>
            <div>
              <IconButton
                onClick={(event)=>{event.stopPropagation();openModal()}}
                onMouseEnter={()=>setButtonHovered(true)}
                onMouseLeave={()=>setButtonHovered(false)}
              >
                <PlayIcon style={{color:theme.palette.primaryText.light,width:24,height:24}}/>
              </IconButton>
            </div>
          </KTooltip>
        }
        {
          [1,2,3,702,4,5,100,108,802,999].includes(item.id)?
          <KTooltip title={"Schedule job"}>
            <div>
              <IconButton
                onClick={(event)=>{event.stopPropagation();dispatch({type:'set_selected_job',selectedJob:item});dispatch({type:'set_view',view:"scheduler"})}}
                onMouseEnter={()=>setButtonHovered(true)}
                onMouseLeave={()=>setButtonHovered(false)}
              >
                <ScheduleIcon style={{color:theme.palette.primaryText.light,width:24,height:24}}/>
              </IconButton>
            </div>
          </KTooltip>
          :
          <div style={{width:40}}></div>
        }
      </div>

      {/* <ConfigModal
        key={clickedItem?.id}
        state={state}
        dispatch={dispatch}
        modalOpen={scheduleModalOpen}
        setModalOpen={setScheduleModalOpen}
        clickedItem={clickedItem}
        setClickedItem={setClickedItem}
      />       */}

      <Modal
        open={modalOpen}
        onClose={() =>setModalOpen(false)}
        disableBackdropClick={true}
      >
        <div style={{width:`100%`,display:'flex',justifyContent:'center',outline:'none'}}>
          <Paper style={{maxHeight:'80vh',marginTop:'15vh',width:560,padding:'30px 40px 16px',overflow:'auto', background:theme.palette.background.main, border:`1px solid ${theme.palette.border.main}`}}>
            <Typography color='primary' style={{fontSize:20,marginBottom:4}}>{modalTitle}</Typography>
            <Typography style={{fontSize:12,marginBottom:24,color:theme.palette.primaryText.main}}>Set the job parameters and click RUN</Typography>
            {
              modalParams.length===0 &&
              <Typography style={{color:theme.palette.primaryText.light}}>No parameters required for this batch job.</Typography>
            }
            {
              modalParams.includes('startDate') &&
              <div className={classes.modalBlock}>
                <Typography color='primary' className={classes.modalBlockTitle}>START OF LOAD PERIOD</Typography>
                  <div style={{width:'100%'}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        autoOk
                        className={classes.inputBase}
                        emptyLabel={'Select a Date'}
                        invalidLabel={'Select a Date'}
                        label="Load from date"
                        format='ll'
                        value={startDate||''}
                        invalidDateMessage={''}
                        error={false}
                        onChange={setStartDate}
                        InputLabelProps={{
                          className:classes.inputLabel
                        }}
                        variant="filled"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
            }
            {
              modalParams.includes('endDate') &&
              <div className={classes.modalBlock}>
                <Typography color='primary' className={classes.modalBlockTitle}>END OF LOAD PERIOD</Typography>
                  <div style={{width:'100%'}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        autoOk
                        className={classes.inputBase}
                        label="Load to date"
                        format='ll'
                        emptyLabel={'Select a Date'}
                        invalidLabel={'Select a Date'}
                        value={endDate||''}
                        invalidDateMessage={''}
                        InputLabelProps={{
                          className:classes.inputLabel
                        }}
                        error={false}
                        onChange={setEndDate}
                        variant="filled"
                      />
                    </MuiPickersUtilsProvider>
                    {
                      moment(startDate).diff(moment(endDate),'day') > 0 &&
                      <Typography className={classes.errorMsg}>END DATE MUST BE AFTER START DATE</Typography>
                    }
                  </div>
                </div>
            }
            {
              modalParams.includes('asAtDate') &&
              <div className={classes.modalBlock}>
                <Typography color='primary' className={classes.modalBlockTitle}>AS_AT DATE</Typography>
                  <div style={{width:'100%'}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        autoOk
                        className={classes.inputBase}
                        label="date"
                        emptyLabel={'Select a Date'}
                        invalidLabel={'Select a Date'}
                        format='ll'
                        helperText={(moment(startDate).diff(moment(endDate),'day') > 0) ? 'END DATE MUST BE AFTER START DATE' : null}
                        value={asAtDate||''}
                        invalidDateMessage={''}
                        InputLabelProps={{
                          className:classes.inputLabel
                        }}
                        error={false}
                        onChange={setAsAtDate}
                        variant="filled"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
            }
            <div style={{height:20}}></div>
            <div style={{position:'sticky',bottom:0,backgroundColor:theme.palette.background.main,display:'flex',justifyContent:'flex-end',paddingTop:16}}>
              {
                type==='platform' &&
                <Button color='primary'  className={classes.button} style={{width:175}} onClick={onOpenAsAtDate}>
                  {modalParams.includes('asAtDate')?'Remove as_at date':'Set as_at date'}
                </Button>
              }
              <Button color='primary' className={classes.button} disabled={!checkRequired()} data-testid = {`RunButton`} onClick={()=>onModalRun()}>RUN</Button>
              <Button color='secondary'  className={classes.button} onClick={()=>onModalCancel()}>CANCEL</Button>
            </div>
          </Paper>
        </div>
      </Modal>
    </div>

  )
}

export default withTheme()(withStyles(styles)(BatchChip));
