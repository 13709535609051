import React,{ useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withTheme, Typography, withStyles, Button,CircularProgress } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { getLabelPlural, onClickResultItem, mapObjectName, toTitleCase, getIconComponent, getInitials, getCollectionColour } from '../../../utilities'
import axiosSolr from '../../../axios-solr'
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ExpandLessIcon from '@material-ui/icons/ArrowDropUpOutlined';
import moment from 'moment';
import { loadSuggestedTerm, onLinkTermSuggestion } from './utils';
import KTooltip from '../KTooltip/KTooltip';
import { useStore } from 'react-redux'
import { checkTermLinkable } from '../../../permissionChecker';
import InteractiveViewer from '../InteractiveInput/InteractiveViewer';
import { globalListenerRef } from '../../../GlobalListenerRef';

const styles = theme => ({
  normalText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },
  columnHeader:{
    color:theme.palette.primaryText.light,
    letterSpacing:2,
    fontSize:12,
    marginLeft:16
  },
  listText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    margin:'12px 0px 12px 16px'
  },
  clickableItem:{
    cursor:'pointer',
    "&:hover":{
      background:theme.palette.hovered.main
    }
  },
  scrollableContent:{
    maxHeight:80,
    height:'max-content',
    whiteSpace:'pre-wrap',
    marginTop:8,
    color:theme.palette.primaryText.light,
    overflow:'auto',
    fontSize:13.75,
    width:'90%',
    ...theme.components.customScroll,
    ...theme.components.MDViewer
  },
  customScroll:{
    overflow:'auto',
    ...theme.components.customScroll,
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:2,
  },
  glossaryChip:{
    color:getCollectionColour(undefined,'glossary',undefined).colour,
    border:`1px solid ${getCollectionColour(undefined,'glossary',undefined).colour}`,
    height:32,
    display:"flex",
    alignItems:'center',
    width:'max-content',
    padding:'0 16px',
    cursor:'pointer',
    borderRadius:16,
    marginTop:8,
    fontSize:13.75,
    '&:hover':{
      filter: 'brightness(130%)'
    }
  },
  suggestionList:{
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    cursor:'pointer',
    "&:hover":{
      background:theme.palette.hovered.main
    }
  },
  avatar:{
    width:24,
    height:24,
    borderRadius:12,
    flexShrink:0,
    background:theme.palette.avatar.main,
    color:'#fff',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:12,
    marginRight:8
  },
  userChip:{
    width:130,
    padding:4,
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    borderRadius:4,
    cursor:'pointer',
  },
  chipName:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:"nowrap"
  },
  detailBodyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    marginBottom:24,
    whiteSpace:"pre-wrap"
  },
})

function GlossaryAdder(props) {

  const {
    theme,
    classes,
    state,
    dispatch,
    history,
    title,
    forceEditable,
    headerFontSize
  } = props;

  let sessionData = useStore().getState().auth.session_user;
  let editable = typeof(forceEditable)==='boolean'?forceEditable:checkTermLinkable({sessionData})
  const [expandedItems, setExpandedItems] = useState([])
  const [detailMap, setDetailMap] = useState({})

  const [termSuggestionsShowMore, setTermSuggestionsShowMore] = useState(false)
  const [termSuggestion, setTermSuggestion] = useState()
  const [tmpForcedLinkedTerm, setTmpForcedLinkedTerm] = useState([])

  const loadSuggestion = (text, forceUnlinkedTerm, forceLinkedTerm) =>{
    if(((state.basicData && state.basicData.description) || text) && state.terms){
      let excludeName;
      if(state.basicData.parent?.category==='GLOSSARY'){
        excludeName = state.basicData.name;
      }
      loadSuggestedTerm({
        forceUnlinkedTerm,
        forceLinkedTerm,text:text || state.basicData.description,
        onFinish:value=>setTermSuggestion(value.filter(el=>el.id!==state.basicData.id)),
        targetID:state.basicData.id,
        relatedTerms: state.terms.items.filter(el=>el.relationship==='RELATED'),
        excludeName
      })
    }else{
      setTermSuggestion()
    }
  }

  let description;
  if(state.basicData)description = state.basicData.description

  useEffect(()=>{

    const onMsgReceived = (msg) => {
      if(msg.data.reload_suggest_terms ){
        let forceUnlinkedTerm;
        let forceLinkedTerm;
        if(msg.data.force_unlinked_term){
          try{
            forceUnlinkedTerm = JSON.parse(msg.data.force_unlinked_term)
          }catch{}
        }
        loadSuggestion(undefined,forceUnlinkedTerm,forceLinkedTerm)
      }
      if(msg.data.add_related_terms){
        try{
          let addedTerms = JSON.parse(msg.data.add_related_terms)
          setTmpForcedLinkedTerm([...addedTerms,...tmpForcedLinkedTerm])
        }catch(error){
          console.log(error)
        }
      }
    }
    window.removeEventListener('message',globalListenerRef.reloadSuggestTerm);
    globalListenerRef.reloadSuggestTerm = onMsgReceived;
    window.addEventListener("message", globalListenerRef.reloadSuggestTerm);

    if(!state.terms)return;
    loadSuggestion()

    return (()=>{window.removeEventListener('message',globalListenerRef.reloadSuggestTerm);})
  // eslint-disable-next-line
  },[state.terms, description, tmpForcedLinkedTerm])

  const loadGlossaryTerms = () => {
    const load = ({pervData=[],page=1}) => {
      axiosCerebrum
        .get(
          `/api/${getLabelPlural(state.basicData.object.name)}/${state.basicData.id.toLowerCase()}/related/collections`,
          {params:{
            category:'GLOSSARY',
            relationship:'RELATED,RELATES_TO',
            per_page:50,
            page
          }}
        )
        .then(response=>{
          if(response.data.items.length>0){
            axiosSolr
              .get(
                `/solr/search/select`,{
                  params:{
                    q:"*",
                    fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
                    rows:response.data.items.length
                  }
                }
              )
              .then(solrResponse=>{
                let data = response.data.items.map(el=>({
                  ...el,
                  ...(solrResponse.data.response.docs.find(s=>s.id===el.id)||{})
                }));
                data = [...pervData, ...data]
                if(response.data.page<response.data.pages){
                  load({pervData:data,page:response.data.page+1})
                }else{
                  // dedupe the list
                  data = data.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
                  dispatch({
                    type:'set_terms',
                    terms:{
                      ...response.data,
                      items:data,
                      shownPage:1
                    }
                  })
                }
              })
              .catch(error=>{
                console.log(error)
                dispatch({
                  type:'set_terms',
                  termsError:true
                })
              })
          }else{
            dispatch({
              type:'set_terms',
              terms:{
                ...response.data,
                shownPage:1
              }
            })
          }
        })
        .catch(error=>{
          console.log(error)
          dispatch({
            type:'set_terms',
            termsError:true
          })
        })
    }
    dispatch({
      type:'set_terms',
      termsLoading:true
    })
    load({})
  }

  useEffect(()=>{
    if(!state.termsLoading && !state.terms && !state.termsError){
      loadGlossaryTerms()
    }
  // eslint-disable-next-line
  },[state.terms, state.termsLoading])

  const loadDetails = async (id) => {
    try{
      let updatedBy;
      let createdBy;
      let obj;

      obj = await axiosCerebrum
        .get(
          `/api/collectioninstances/${id}`
        )

      updatedBy = await axiosCerebrum
        .get(
          `/api/collectioninstances/${id}/related`,{
            params:{relationship:'UPDATED_BY',per_page:1,sort:'END_DESC'}
          }
        )
      if(!updatedBy.data.items[0]){
        createdBy = await axiosCerebrum
          .get(
            `/api/collectioninstances/${id}/related`,{
              params:{relationship:'CREATED_BY',per_page:1,sort:'END_DESC'}
            }
          )
      }

      setDetailMap({
        ...detailMap,
        [id]:{
          updatedBy:updatedBy?updatedBy.data.items[0]:undefined,
          createdBy:createdBy?createdBy.data.items[0]:undefined,
          object:obj.data
        }
      })

    }catch{
      setDetailMap({
        ...detailMap,
        [id]:undefined
      })
    }

  }

  const onExpand = (event, id, item) => {
    let el = event.currentTarget.parentElement;
    if(!el)return;
    if(!expandedItems.includes(id)){
      expandedItems.forEach(i=>{
        onExpand({currentTarget:{parentElement:document.getElementById(`term_list_${i}`)}}, i)
      })
      loadDetails(item.id)
      setExpandedItems([id])
      el.style["max-height"] = '335px';
    }else{
      el.style["max-height"] = '47px';
      setExpandedItems(expandedItems.filter(e=>e!==id))
    }
  }

  let header = 'TERMS'
  let objectName = toTitleCase(mapObjectName(state.basicData.object.name).replace(/_/g,' '))
  if(state.basicData.object.name==='COLLECTION_INSTANCE' && state.basicData.parent.category==='GLOSSARY'){
    header = 'LINKED TERMS'
    objectName = 'Term'
  }
  let subTitle = `Terms linked to this ${objectName}`

  const getDescription = item => {
    let description = ''
    if(detailMap[item.id] && detailMap[item.id].object){
      description =  detailMap[item.id].object.description
    }else{
      description = item.description;
    }
    if(!description)return '';
    if(description.length>180)return description.slice(0,277)+'...';
    return description
  }

  const onClickEdit = () => {
    window.postMessage({open_link_term_modal:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
  }

  const onClickShowMore = () => {
    let newPage = state.terms.shownPage+1
    dispatch({
      type:'set_terms',
      terms:{
        ...state.terms,
        shownPage:newPage
      }
    })
  }

  const onClickSeeLess = () => {
    dispatch({
      type:'set_terms',
      terms:{
        ...state.terms,
        shownPage:1
      }
    })
  }

  return (
    <div className={classes.root}>
      <div id="profile-glossary-adder">
        <div style={{display:'flex',flexGrow:1}}>
          <Typography style={{fontSize:headerFontSize||20,color:theme.palette.header.main}}>{title || header}</Typography>
          {
            editable &&
            <Button color='primary' data-test-id="glossary-editor-edit-button" style={{marginLeft:16,marginTop:-2}} onClick={()=>onClickEdit()}>EDIT</Button>
          }
        </div>
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>{subTitle}</Typography>
        {
          state.terms && state.terms.items.length>0 &&
          <div style={{display:'flex',width:'100%',height:24,alignItems:'center'}}>
            <Typography className={classes.columnHeader} style={{marginLeft:56,flex:'0 0 25%'}}>TERM</Typography>
            <Typography className={classes.columnHeader} style={{flex:'0 1 75%'}}>DESCRIPTION</Typography>
          </div>
        }
        {
          state.terms && [...tmpForcedLinkedTerm,...state.terms.items].slice(0,state.terms.shownPage*5).map((el,index)=>(
            <div key={el.id} data-test-id={`term-list-${el.name.toLowerCase().replace(/\s/g,'-')}`} id={`term_list_${index}`} style={{transition: "max-height .1s ease",maxHeight:47,display:'flex',flexDirection:'column', overflow:'hidden',borderRadius:expandedItems.includes(index)?5:0,border:`1px solid ${theme.palette.listItemDivider.main}`, borderStyle:expandedItems.includes(index)?'solid':'none none solid none'}}>
              <div onClick={event=>onExpand(event, index, el)} className={classes.clickableItem}  style={{display:'flex',width:'100%',alignItems:'flex-start',height:47}}>
                <KTooltip classes={{tooltip:classes.tooltip}} title={el.description && el.description.trim()!==''?el.description:'No description'}>
                  <div style={{width:24,height:24,margin:'12px 0px 12px 16px',flexShrink:0,flexGrow:0}}>
                    {getIconComponent({label:'glossary',size:24,colour:theme.palette.primary.main})}
                  </div>
                </KTooltip>

                <KTooltip placement='bottom-start' classes={{tooltip:classes.tooltip}} title={el.name}>
                  <Typography  data-test-classname="term-list-name" className={classes.listText} style={{flex:expandedItems.includes(index)?'1 1 100%':'0 0 25%',fontWeight:expandedItems.includes(index)?700:400}}>{el.name}</Typography>
                </KTooltip>

                {
                  !expandedItems.includes(index) &&
                  <KTooltip placement='bottom-start' title={el.description || ''}>
                    <Typography  data-test-classname="term-list-description" className={classes.listText} style={{flex:'0 1 75%' }}>{el.description && el.description.trim()!==''?el.description:'No description'}</Typography>
                  </KTooltip>
                }

                {
                  expandedItems.includes(index)?
                  <ExpandLessIcon style={{marginTop:12, marginRight:8,marginLeft:30, color:theme.palette.primaryText.light }}/>:
                  <ExpandMoreIcon style={{marginTop:12, marginRight:8,marginLeft:30, color:theme.palette.primaryText.light }} />
                }
              </div>

              {
                expandedItems.includes(index) &&
                <div style={{flex:'1 1', height:'100%', display:'flex', flexDirection:'column',paddingLeft:56,paddingTop:8}}>
                  <div style={{flex:'1 1' }}>
                    <div style={{display:'flex',alignItems:'center',overflow:'hidden',marginBottom:24}}>
                      <div style={{flexBasis:'33%',flexGrow:1,marginRight:16,flexShrink:1,overflow:'hidden'}}>
                        <div className={classes.userChip} style={{width:'100%'}} onClick={()=>history.push(`/profile/collection/${el.parent_id}`)}>
                          {getIconComponent({label:'collection',size:20,colour:theme.palette.primary.main})}
                          <KTooltip title={el.collection_txt}>
                            <Typography data-test-classname="term-list-expanded-collection"  className={classes.chipName} style={{color:theme.palette.primaryText.main,fontSize:13.75,marginLeft:8}}>
                              {el.collection_txt}
                            </Typography>
                          </KTooltip>
                        </div>
                      </div>
                      {
                        detailMap[el.id] && (detailMap[el.id].updatedBy || detailMap[el.id].createdBy) &&
                        <div style={{flexBasis:'33%',flexGrow:1,marginRight:16,flexShrink:1}}>
                          <div className={classes.userChip}  onClick={()=>history.push(`/profile/user/${(detailMap[el.id].updatedBy || detailMap[el.id].createdBy).id}`)}>
                            <div className={classes.avatar}>
                              {getInitials((detailMap[el.id].updatedBy || detailMap[el.id].createdBy).name)}
                            </div>
                            <KTooltip title={(detailMap[el.id].updatedBy || detailMap[el.id].createdBy).name}>
                              <Typography data-test-classname="term-list-expanded-user"  className={classes.chipName} style={{color:theme.palette.primaryText.main,fontSize:13.75}}>{(detailMap[el.id].updatedBy || detailMap[el.id].createdBy).name}</Typography>
                            </KTooltip>
                          </div>
                        </div>
                      }
                      <div style={{flexBasis:'33%',flexGrow:1,marginRight:16,flexShrink:1,display:'flex',alignItems:'center'}}>
                        {getIconComponent({label:'time',size:24,colour:theme.palette.primary.main})}
                        <Typography data-test-classname="term-list-expanded-last-updated"  className={classes.detailBodyText} style={{marginBottom:0,marginLeft:8}}>
                          {moment(el.last_updated_srt).fromNow()}
                        </Typography>
                      </div>
                    </div>
                    <Typography className={classes.sectionHeader} style={{marginTop:12}}>DESCRIPTION</Typography>
                    <div data-test-classname="term-list-expanded-description"  className={classes.scrollableContent}>
                      <InteractiveViewer
                        key={ getDescription(el) && getDescription(el).trim()!==''?getDescription(el):'No description'}
                        initialValue={ getDescription(el) && getDescription(el).trim()!==''?getDescription(el):'No description'}
                        id={'description'+index}
                        alwaysShown={true}
                      />
                    </div>
                  </div>
                  <Button
                    data-test-classname="term-list-expanded-open-button"
                    color='primary'
                    style={{marginTop:24,marginBottom:16,width:'max-content'}}
                    onClick={() => onClickResultItem({
                      item:el,
                      id:el.id,
                      label: 'collection_instance',
                      collection: 'collections',
                      history: history
                    })}
                  >
                    OPEN
                  </Button>
                </div>
              }
            </div>
          ))
        }
        {
          state.termsLoading &&
          <CircularProgress color='secondary' />
        }
        {
          state.termsError &&
          <Typography className={classes.normalText}>Error occurred loading terms</Typography>
        }
        {
          state.terms && state.terms.total>5 &&
          <div style={{marginTop:8}}>
            {
              state.terms.shownPage*5<state.terms.total &&
              <Button color='primary' onClick={()=>{onClickShowMore()}}  style={{marginRight:8}}>SEE MORE</Button>
            }
            {
              state.terms.shownPage>1 &&
              <Button color='primary' onClick={()=>{onClickSeeLess()}}>SEE LESS</Button>
            }
          </div>
        }
        {
          state.terms && state.terms.total===0 && tmpForcedLinkedTerm.length===0 &&
          <Typography className={classes.normalText}>No terms linked</Typography>
        }
        {
          termSuggestion && termSuggestion.length>0 &&
          <div style={{marginTop:24}}>
            <Typography style={{color:theme.palette.secondary.main,fontSize:13.75,marginLeft:16,marginBottom:16,wordBreak:'break-word'}}>
              Suggested Terms found in the Description
            </Typography>
            <div style={{display:'flex',marginBottom:4}}>
              <div style={{flex:'0 0 24px'}} className={classes.columnHeader}></div>
              <div style={{flex:'0 0 25%'}} className={classes.columnHeader}>TERM</div>
              <div style={{flex:'0 0 45%'}} className={classes.columnHeader}>DESCRIPTION</div>
              <div style={{flex:'0 1 30%'}} className={classes.columnHeader}>GLOSSARY</div>
              <div style={{flex:'0 0 64px'}} className={classes.columnHeader}></div>
            </div>
            {
              (termSuggestionsShowMore?termSuggestion:termSuggestion.slice(0,5)).map(t=>(
                <div key={t.id} data-test-id={`term-list-suggestion-${t.term.toLowerCase().replace(/\s/g,'-')}`} className={classes.suggestionList} onClick={()=>history.push(`/profile/collection_instance/${t.id}`)}>
                  <div style={{width:24,height:24,flexShrink:0, flexGrow:0}} className={classes.listText}>
                    {getIconComponent({label:'glossary',size:24,colour:theme.palette.primaryText.light})}
                  </div>
                  <KTooltip title={t.term}>
                    <Typography data-test-classname="term-list-suggestion-name"  style={{flex:'0 0 25%',color:theme.palette.primaryText.main}} className={classes.listText}>
                      {t.term}
                    </Typography>
                  </KTooltip>
                  <KTooltip title={t.description||''}>
                    <Typography data-test-classname="term-list-suggestion-description" style={{flex:'0 0 45%'}} className={classes.listText}>
                      {!t.description || t.description===''?'No description':t.description}
                    </Typography>
                  </KTooltip>
                  <KTooltip title={t.collection_name || ''}>
                    <Typography data-test-classname="term-list-collection-name" style={{flex:'0 1 30%'}} className={classes.listText}>
                      {t.collection_name || ''}
                    </Typography>
                  </KTooltip>
                  {
                    editable &&
                    <Button
                      data-test-classname="term-list-suggestion-open-button"
                      onClick={(event)=>{
                        event.stopPropagation()
                        onLinkTermSuggestion({
                          term:t,
                          targetID:state.basicData.id,
                        })
                      }}
                      style={{flex:'0 0 40px',padding:0,minWidth:0,width:40,marginLeft:16,marginRight:4}}
                      color='primary'
                    >
                      LINK
                    </Button>
                  }
                </div>
              ))
            }
            {
              termSuggestion.length>5 &&
              <Button color='primary' style={{marginTop:4}} onClick={()=>{setTermSuggestionsShowMore(!termSuggestionsShowMore)}}>
                SEE {termSuggestionsShowMore?'LESS':'MORE'}
              </Button>
            }
          </div>
        }
      </div>
    </div>
  )
}

GlossaryAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
  forceEditable: PropTypes.bool,
  headerFontSize: PropTypes.number
}

export default withTheme()(withStyles(styles)(GlossaryAdder));
