import React, {useState, useEffect} from 'react';
import { withTheme,withStyles } from '@material-ui/core/styles';
import { Typography,Divider, Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import {toTitleCase, getInitials, getIconComponent, formatNumber, collectionIds} from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import axiosCerebrum from '../../../axios-cerebrum'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom:32
  },
  main_avatar: {
    height: 80,
    width: 80,
    marginBottom: '24px',
    backgroundColor: theme.palette.avatar.main,
    fontSize: '34px',
    letterSpacing: '0.25px',
    textAlign: 'center'
  },
  metricsTitle:{
    color:theme.palette.primaryText.main,
    marginRight:12,
    fontSize:13.75,
    flexGrow:1,
    wordBreak:'break-word',
    overflow:"hidden",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  metricsValue:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    flexShrink:0,
    flexGrow:0
  },
  teamChip:{
    display:'flex',
    alignItems:'center',
    padding:'4px 12px 4px 6px',
    maxWidth:188,
    width:'max-content',
    cursor:'pointer',
    border:`1px solid ${theme.palette.primary.main}`,
    borderRadius:4,
    marginBottom:8,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  metricsBox:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    overflow:'hidden',
    height:48,
    padding:'0px 16px',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  clickableBox:{
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  valueBackground:{
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.chip.main,
    borderRadius:16,
    padding:'2px 8px'
  },
})


function SideBar(props) {
  const {
    classes,
    theme,
    state,
    history,
    roles,
    sessionData
  } = props;

  let groupData = localStorage.getItem('userGroupData');
  let userData = localStorage.getItem('userInfo');
  if(groupData)groupData = JSON.parse(groupData);
  if(userData)userData = JSON.parse(userData)

  const [teamExpanded, setTeamExpanded] = useState(false)

  const [myActions, setMyActions] = useState({});
  const [myItems, setMyItems] = useState({});

  const loadSurveyCount = async () => {
    setMyActions({loading:true})
    let categories;
    try{
      categories = await axiosCerebrum.get('/api/surveys/categories')
    }catch(error){
      setMyActions({error:true})
      console.log(error)
    }
    let myActionArr = []
    let promises = []
    for(let i=0; i<categories.data.items.length; i++){
      try{
        let surveyName = toTitleCase(categories.data.items[i].name);
        if(surveyName==='Access')surveyName = 'Review access'
        if(surveyName==='Feedback')surveyName = 'Give feedback'
        if(surveyName==='Opinion')surveyName = 'Share knowledge'
        promises.push(axiosCerebrum
          .get(`/api/me/surveys`,{params:{category:categories.data.items[i].name,per_page:0,status:'OPEN'}})
          .then(count=>{
            if(count.data.total!==0)myActionArr.push({text:`${surveyName}`,value:count.data.total ,onClick:()=>history.push(`/add_knowledge?category=${categories.data.items[i].name}`)})
          })
        )

      }catch(error){
        console.log(error)
      }
    }
    await Promise.all(promises)
    let order = {
      'Review access':1,
      'Give feedback':2,
      'Share knowledge':3
    }
    setMyActions({data:myActionArr.sort((a,b)=>order[a.text]-order[b.text])})
  }

  const loadMyItems = async () => {
    setMyItems({loading:true})
    let items = []
    await axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related`,{
          params:{
            relationship:'ASSIGNEE_OF',
            object_name:'ISSUE',
            per_page:1,
          }
        }
      )
      .then(response=>{
        if(response.data.total===0)return;
        items.push({text:`Assigned issues`,value:response.data.total,onClick:response.data.total===0?undefined:()=>history.push(`/my_tasks?tabName=ISSUES`)})
      })
      .catch(error=>{
        console.log(error);
        setMyItems({error:true})
      })
    await axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related/collections`,{
          params:{
            relationship:'CREATOR_OF',
            category:'KNOWLEDGE',
            parent_id:collectionIds.question,
            sort:'END_DESC',
            per_page:0,
          }
        }
      )
      .then(response=>{
        if(response.data.total===0)return;
        items.push({text:`Pending questions`,value:response.data.total,onClick:response.data.total===0?undefined:()=>history.push(`/my_tasks?tabName=QUESTIONS`)})
      })
      .catch(error=>{
        console.log(error);
        setMyItems({error:true})
      })
    setMyItems({data:items})
  }

  useEffect(()=>{
    if(!myActions.data && ['10','20','40'].find(el=>roles.includes(el))){
      loadSurveyCount()
    }
    if(!myItems.data){
      loadMyItems()
    }
  // eslint-disable-next-line
  },[])

  return (
    <div className={classes.root}>
      <div style={{marginLeft:20}}>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'max-content',maxWidth:'100%'}}>
          <Avatar className={classes.main_avatar}>{getInitials(userData.name)}</Avatar>
          <Typography variant='h5' style={{color:theme.palette.header.main,whiteSpace:'pre-wrap',wordBreak:'break-all'}} >{toTitleCase(userData.name)}</Typography>
        </div>
        <Divider style={{background:theme.palette.listItemDivider.main,marginTop:40,marginBottom:23, width:120}}/>
        <Typography  style={{color:theme.palette.header.main,marginBottom:9,fontSize:20}}>Team</Typography>
        {
          groupData && groupData.length>0 && (teamExpanded?groupData:groupData.slice(0,1)).map(el=>(
            <div className={classes.teamChip} data-test-classname="team-chip" onClick={()=>history.push(`/profile/group/${el.id}`)}>
              {getIconComponent({label:'group',size:24,colour:theme.palette.primary.main})}
              <Typography
                style={{marginLeft:12, fontSize:13.75, color:theme.palette.primary.main, overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}
              >
                {el.name}
              </Typography>
            </div>
          ))
        }
        {
           groupData && groupData.length>1 &&
          <Button color='primary' data-test-id="team-see-more-button" onClick={()=>setTeamExpanded(!teamExpanded)}>
            {teamExpanded?'SEE LESS':'SEE MORE'}
          </Button>
        }
        {
          (!groupData || groupData.length===0) &&
          <div className={classes.teamChip} data-test-id="add-team-button" onClick={()=>history.push(`/settings`)}>
            {getIconComponent({label:'info',size:24,colour:theme.palette.primary.main})}
            <Typography
              style={{marginLeft:12, fontSize:13.75, color:theme.palette.primaryText.main}}
            >
              Add your Team
            </Typography>
          </div>
        }

        {
          (
            (myActions && myActions.data && myActions.data.length!==0) ||
            (myItems && myItems.data && myItems.data.length!==0)
          ) &&
          <div style={{marginTop:48}}>
            <Typography style={{color:theme.palette.header.main,marginBottom:8,fontSize:20}}>My actions</Typography>
            {
              myActions && myActions.data && myActions.data.map(el=>(
                <div data-test-classname="my-action-item" className={classes.metricsBox+` `+(el.onClick?classes.clickableBox:undefined)} onClick={el.onClick}>
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>{el.text}</Typography>
                  <Typography className={classes.metricsValue + ' ' + classes.valueBackground}>{formatNumber(el.value)}</Typography>
                </div>
              ))
            }
            {
              myItems && myItems.data && myItems.data.map(el=>(
                <div data-test-classname="my-action-item" className={classes.metricsBox+` `+(el.onClick?classes.clickableBox:undefined)} onClick={el.onClick}>
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>{el.text}</Typography>
                  <Typography className={classes.metricsValue + ' ' + classes.valueBackground}>{formatNumber(el.value)}</Typography>
                </div>
              ))
            }
          </div>
        }

        { state.metricsData && state.metricsData.filter(el=>el.value!==0).length!==0 && <Typography style={{color:theme.palette.header.main,marginBottom:8,fontSize:20,marginTop:48}}>Metrics</Typography>}
        {
          state.metricsData && state.metricsData.filter(el=>el.value!==0).map(el=>(
            <div>
              <ContextMenuTrigger id={el.name+'_home_metrics'}>
                <div data-test-classname="home-metrics" className={classes.metricsBox+' '+(el.value!==0?classes.clickableBox:'')} onClick={el.value!==0?el.onClick:undefined}>
                  <Typography className={classes.metricsTitle}>{el.name}</Typography>
                  <Typography className={classes.metricsValue + ' ' + classes.valueBackground}>{formatNumber(el.value)}</Typography>
                </div>
              </ContextMenuTrigger>
              <ContextMenu id={el.name+'_home_metrics'}>
                <CustomMenu
                  itemUrl={el.url}
                  actions={[
                    'open_new_tab'
                  ]}
                />
              </ContextMenu>
            </div>
          ))
        }

      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SideBar));
