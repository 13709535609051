import React from 'react';
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import pageCacheReducer from './store/reducers/pageCache';
import keycloakAuth from './store/reducers/keycloakAuth';
import bulkEditReducer from './store/reducers/bulkEdit'

import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
// import { resizeScreen } from './screenResizer'
require('./index.css')


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: keycloakAuth,
  pageCache:pageCacheReducer,
  bulkEdit:bulkEditReducer
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

// resizeScreen();

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>
);

const container = document.getElementById('k_platform_root');
const root = createRoot(container);
root.render(app);

serviceWorker.unregister();
