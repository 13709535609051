import React, {  }  from 'react';
import { withStyles, withTheme, Typography, Avatar, IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent, getInitials } from '../../../utilities';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  chip: {
    // border:`1px solid ${theme.palette.border.main}`,
    marginRight: 6,
    marginTop:2,
    marginBottom:2,
    height:24,
    display:"flex",
    alignItems:'center',
    borderRadius:16,
    padding:'0 12px 0 2px',
    width:'max-content',
    overflow:"hidden",
    boxSizing:'border-box',
    '&:hover .delete-button': {
      visibility: 'visible !important'
    }
  },
  clearButton:{
    '&:hover': {
      background: theme.palette.hovered.main
    }
  },
  avatar: {
    fontSize: 10,
    height: 20,
    width: 20,
    // backgroundColor: theme.palette.avatar.main,
  },
  clickableText:{
    '&:hover':{
      textDecoration:'underline'
    }
  },
  ellipsisText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
})

const UserChip = props => {

  const {
    classes,
    theme,
    user,
    onClick,
    onRemove,
    maxWidth='300px',
    onlyShowDeleteOnHover,
    showTooltip,
    testID,
    hideBorder,
    height,
    // variant = 'dark',
    bottomMargin,
    iconLabel,
    avatarGap,
    removeIcon,
  } = props;

  const userName = user?.name||user?.name_txt||''

  let fontColour = theme.palette.primary.main;
  let chipBackground = theme.palette.background.main;

  return (
    <KTooltip title={showTooltip && userName}>
      <div
        className={classes.chip + ' prevent-inline-edit'}
        onClick={onClick}
        data-test-classname={testID}
        style={{
          background:chipBackground,
          border:hideBorder?undefined:`1px solid ${fontColour}`,
          marginBottom:bottomMargin,
          cursor:onClick?'pointer':'default',
          maxWidth,
          marginLeft:hideBorder?1:0,
          height,
          borderRadius:height?height/2:undefined
        }}
      >
        {
          !removeIcon &&
          <div style={{marginRight:avatarGap||10, display:'inline-block', verticalAlign:'middle'}}>
            {
              iconLabel?
              <div style={{width:16,height:16,marginLeft:6, display: 'flex', alignItems:'center'}}>
                {getIconComponent({label:iconLabel,size:16,colour:fontColour})}
              </div>
              :
              <Avatar align='center' className={classes.avatar} style={{backgroundColor:fontColour,color:chipBackground}}>{getInitials(userName)}</Avatar>
            }
          </div>
        }
        <Typography className={classes.ellipsisText + (onClick?' '+classes.clickableText:'')} style={{display:'inline-block',lineHeight:"normal", verticalAlign:'middle',color:fontColour,paddingLeft:removeIcon?6:undefined}}>
          {userName}
        </Typography>
        {
          onRemove &&
          <IconButton data-test-classname="user-chip-button" className={classes.clearButton+' delete-button'} style={{marginLeft:6,marginRight:-10,width:20,height:20,padding:3,visibility:onlyShowDeleteOnHover?'hidden':undefined}} onClick={onRemove}>
            {getIconComponent({label:'clear', size:18,colour:fontColour})}
          </IconButton>
        }
      </div>
    </KTooltip>
  )
}

UserChip.propTypes = {
  classes: PropTypes.object.isRequired,
  user:PropTypes.object.isRequired,
  onClick:PropTypes.func.isRequired,
  onRemove:PropTypes.func,
  testID:PropTypes.string,
  bottomMargin:PropTypes.number,
  onlyShowDeleteOnHover:PropTypes.bool,
  showTooltip:PropTypes.bool,
  maxWidth:PropTypes.any,
  hideBorder:PropTypes.bool,
  height:PropTypes.number,
  iconLabel:PropTypes.string,
  avatarGap:PropTypes.number,
  removeIcon:PropTypes.bool
}


export default withTheme()(withStyles(styles)(UserChip));
