import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Popper, Paper, List, ClickAwayListener, CircularProgress, Typography, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import axiosSolr from '../../../axios-solr';
import axiosCerebrum from '../../../axios-cerebrum';
import { getIconComponent, getNameSearchQuery } from '../../../utilities'
import KTooltip from '../KTooltip/KTooltip';
import { checkClassName } from '../Editor/utils';
// import {useStore} from 'react-redux'

const styles = theme => ({
  root: {
    width: '100%',
    display:'flex',
    boxSizing:'border-box'
  },
  input: {
    ...theme.components.inputBase,
    flexGrow:1,
    minHeight:48,
  },
  list:{
    // minWidth:500,
    maxHeight:'40vh',
    overflow:'auto',
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.main
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{

    },
    '&::-webkit-scrollbar': {
      width: 5
    },
  },
  icon:{
    padding:4,
    width:20,
    height:20
  },
  listItem:{
    height:36,
    display:'flex',
    alignItems:'center',
    paddingLeft:8,
    paddingRight:8,
    overflow:'hidden',
  },
  disabledListItem:{
    pointerEvents:'none',
    '& span':{
      color:theme.palette.primaryText.light
    }
  },
  listItemText:{
    fontSize:13.75,
    height:36,
    flexGrow:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primaryText.main,
    display:'flex',
    alignItems:'center',
    paddingLeft:8,
    '&:hover':{
      cursor:"pointer",
      background:theme.palette.hovered.main
    }
  },
  loadMoreButton:{
    display:'flex',
    justifyContent:'center',
    height:20,
    marginLeft:32,
    '&:hover':{
      cursor:"pointer",
      background:theme.palette.hovered.main
    }
  },
})


const HierarchySearchSelector = props => {
  const {
    theme,
    classes,
    placeholder,
    clearable,
    selectedItem,
    searchValue,
    setSearchValue,
    rootId,
    rootLabel,
    fq,
    disabled,
    hideBorder,
    autoFocus,
    height,
    onResultClick,
    isItemSelected,
    isItemDisabled,
    replaceItemCheckbox,
    checkboxColour,
    borderColour,
    isPlugin,
    testID
  } = props;

  // const [search, setSearch] = useState(false);
  const [inputRef, setInputRef] = useState();
  const [popperOpen, setPopperOpen] = useState(false);
  const [data, setData] = useState({});
  const [hierarchyData, setHierarchyData] = useState({});
  const typeTimeout = useRef();

  const loadData = ({search=searchValue, start=0}) => {
    setData({
      ...(start===0?{}:data),
      loading:true
    })
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:getNameSearchQuery(search),
          start,
          rows:10,
          fq
        }}
      )
      .then(response=>{
        setData({
          ...response.data.response,
          docs:start===0?response.data.response.docs:(data.docs||[]).concat(response.data.response.docs),
        })
      })
      .catch(error=>{
        console.log(error);
        setData({
          error:true
        })
      })
  }

  const loadPreselected = ({item, hierarchy, data}) => {
    let url;
    if(item.hierarchy_parent_id){
      url = `/api/collectioninstances/${item.hierarchy_parent_id}`
    }else if(item.hierarchy_parent_id_txt){
      url = `/api/collectioninstances/${item.hierarchy_parent_id_txt}`
    }else if(item.parent_id_txt){
      url = `/api/collections/${item.parent_id_txt}`
    }else{
      url = `/api/collections/${item.parent?.id}`
    }

    axiosCerebrum
      .get(url)
      .then(response=>{
        let parent = response.data;
        parent.has_children = true;
        parent.expanded = true;
        if(parent){
          hierarchy.unshift(parent)
          if(parent.id!==rootId){
            if(hierarchy.length>20){
              setHierarchyData(data)
              return;
            }
            loadPreselected({item:parent, hierarchy, data})
          }else{
            hierarchy.forEach((h,i)=>{
              if(i===0)return;
              if(i===1){
                let found = data[rootId]?.items?.find(d=>d.id===h.id);
                if(found){
                  found.expanded = true;
                }else{
                  data[rootId].items.unshift({
                    ...h,
                    expanded:true,
                    has_children:true
                  })
                }
              }else{
                data[hierarchy[i-1].id] = {
                  expanded:true,
                  has_children:true,
                  items:[h],
                  page:0,
                  pages:1
                }
              }
            })
            setHierarchyData(data)
          }
        }else{
          setHierarchyData(data)
        }
      })
      .catch(error=>{
        console.log(error);
        setHierarchyData(data)
      })
  }

  const loadHierarchy = ({parentId, page=1}) => {
    setHierarchyData({
      ...hierarchyData,
      [parentId]:{
        ...(page===1?{}:(hierarchyData[parentId]||{})),
        loading:true,
        expanded:true,
      }
    })

    axiosCerebrum
      .get(
        `/api/collectioninstances`,{params:{
          page,
          per_page:10,
          sort:'ALPHABETICAL',
          ...(
            parentId===rootId?
            {hierarchy_parent_id:'null',collection_id:rootId}:
            {hierarchy_parent_id:parentId}
          ),
        }}
      )
      .then(response=>{
        response.data.items = response.data.items.map(d=>({
          ...d,
          has_children:d.hierarchy_children?.length>0
        }))
        let presetItem = hierarchyData[parentId]?.page===0?hierarchyData[parentId]?.items[0]:null;
        let dataList = page===1?response.data.items:(hierarchyData[parentId].items||[]).concat(response.data.items);
        if(presetItem){
          dataList = dataList.filter(d=>d.id!==presetItem.id)
          dataList.unshift(presetItem)
        }
        let newData = {
          ...hierarchyData,
          [parentId]:{
            ...response.data,
            expanded:true,
            items:dataList
          }
        }
        if(page===1 && selectedItem && parentId===rootId){
          loadPreselected({item:selectedItem, hierarchy:[{...selectedItem}], data:newData})
        }else{
          setHierarchyData(newData)
        }
      })
      .catch(error=>{
        console.log(error);
        setHierarchyData({
          ...hierarchyData,
          [parentId]:{
            error:true,
            expanded:true,
          }
        })
      })
  }

  // const listOnScroll = (event) => {
  //   if(!scrollable)return;
  //   // if(!isInViewport(scrollRef))return;
  //   if(event.target.scrollTop<event.target.scrollHeight-event.target.clientHeight-10)return;
  // }

  const onClickExpand = item => {
    if(hierarchyData[item.id]){
      setHierarchyData({
        ...hierarchyData,
        [item.id]:{
          ...hierarchyData[item.id],
          expanded:!hierarchyData[item.id].expanded
        }
      })
    }else{
      loadHierarchy({parentId:item.id, label:item.object.name})
    }
  }

  const getListItem = ({item, isSearch}) => {
    return (
      <div className={classes.listItem}>
        {
          !isSearch &&
          <div style={{width:24,height:24,flexGrow:0,flexShrink:0,transform:hierarchyData[item.id]?.expanded?`rotate(90deg)`:undefined}}>
            {
              item.has_children?
              <IconButton
                style={{padding:6,width:24,height:24}}
                onClick={()=>{
                  onClickExpand(item)
                }}
              >
                {getIconComponent({label:'expand_right',size:16,colour:theme.palette.primaryText.light})}
              </IconButton>
              :null
            }
          </div>
        }
        <KTooltip title={item.name || item.name_txt}>
          <div
            className={classes.listItemText + (isItemDisabled?.(item)?' '+classes.disabledListItem:'')}
            onClick={()=>onResultClick(item)}
          >
            {
              isItemSelected &&
              (
                replaceItemCheckbox?.(item) ||
                <Checkbox
                  checked={isItemSelected(item)}
                  color='primary'
                  style={{padding:0,marginRight:4,color:!isItemSelected(item)?undefined:checkboxColour}}
                  disabled={isItemDisabled && isItemDisabled(item)}
                />
              )
            }
            <span>{item.name || item.name_txt}</span>
          </div>
        </KTooltip>
      </div>
    )
  }

  const generateHierarchyGroup = ({parentId, parentType}) => {
    if(!hierarchyData[parentId])return;
    if(!hierarchyData[parentId].expanded)return;
    let d = hierarchyData[parentId];
    if(parentId===rootId && d.items?.length===0){
      return (
        <Typography style={{fontSize:16,marginLeft:16,marginBottom:8,marginTop:8}}>
          No result(s) found
        </Typography>
      )
    }
    return (
      <>
        {
          d.items?.map(c=>{
            return (
              <>
                {getListItem({item:c})}
                {
                  c.has_children?
                  <div style={{paddingLeft:16}}>
                    {generateHierarchyGroup({parentId:c.id, parentType:c.object.name})}
                  </div>
                  :
                  null
                }
              </>
            )
          })
        }
        {d.error && <Typography style={{fontSize:12,marginLeft:24,marginTop:8}}>Error loading data</Typography>}
        {d.loading && <div style={{marginLeft:24,marginTop:8}}><CircularProgress style={{width:20,height:20}} color='secondary' /></div>}
        {
          d.page < d.pages && !d.loading && !d.error &&
          <div
            className={classes.loadMoreButton}
            onClick={()=>loadHierarchy({parentId:parentId,label:parentType,page:d.page+1})}
          >
            {getIconComponent({label:'more_horiz',size:20,colour:theme.palette.primaryText.light})}
          </div>
        }
      </>
    )
  }

  const generateSearchGroup = () => {
    if(!data)return;
    return (
      <>
        <Typography style={{fontSize:16,marginLeft:16,marginBottom:8,marginTop:8}}>
          {isNaN(data.numFound)?'':data.numFound} result(s) found
        </Typography>
        {
          data.docs?.map(d=>{
            return getListItem({item:d, isSearch:true})
          })
        }
        {data.error && <Typography style={{fontSize:12,marginLeft:24,marginTop:8}}>Error loading data</Typography>}
        {data.loading && <div style={{marginLeft:24,marginTop:12}}><CircularProgress style={{width:20,height:20}} color='secondary' /></div>}
        {
          data.numFound > data.docs?.length && !data.loading && !data.error &&
          <div
            className={classes.loadMoreButton}
            onClick={()=>loadData({start:data.docs.length})}
          >
            {getIconComponent({label:'more_horiz',size:20,colour:theme.palette.primaryText.light})}
          </div>
        }
      </>
    )
  }

  const ListComponent = (
    <List
      className={classes.list + ' hierarchy-search-list'}
      id="search_selector_popper_list"
      // onScroll={listOnScroll}
    >
      {
        searchValue.trim()===''?
        generateHierarchyGroup({parentId:rootId,parentType:rootLabel}):
        generateSearchGroup()
      }
    </List>
  )

  return (
    <div
      className={classes.root}
      style={{
        display: isPlugin?'block':'flex',
        minWidth: isPlugin?260:undefined,
      }}
    >
      <ClickAwayListener
        onClickAway={(event)=>{
          if(checkClassName(event.target,'hierarchy-search-list'))return;
          setPopperOpen(false)
        }}
      >
        <InputBase
          inputProps={{
            'data-test-id':testID
          }}
          autoFocus={autoFocus}
          className={classes.input}
          style={{
            border:hideBorder?'none':undefined,
            borderColor:borderColour,
            minHeight:height||undefined,
            height,
            fontSize:height && height<=32?13.75:undefined,
            width: isPlugin?'100%':undefined,
          }}
          placeholder={placeholder || 'Enter search text'}
          value={searchValue}
          disabled={disabled}
          onFocus={()=>{
            if(Object.keys(data).length===0 && !hierarchyData[rootId] && searchValue===''){
              setPopperOpen(true)
              loadHierarchy({parentId:rootId,label:rootLabel})
            }
          }}
          onClick={()=>{
            if(searchValue===''){
              setPopperOpen(true)
            }
          }}
          onChange={event => {
            let value = event.target.value;
            setSearchValue(value);
            clearTimeout(typeTimeout.current);
            if(value.trim()===''){
              setData({})
              return;
            }
            setData({loading:true})
            typeTimeout.current = setTimeout(()=>{
              loadData({search:value})
            },500)
          }}
          inputRef={node => {setInputRef(node);}}
          endAdornment={
            <IconButton onClick={clearable?()=>setSearchValue(''):undefined} disabled={searchValue!==''?false:true} className={classes.icon} style={{marginRight:4}}>
              {
                clearable && searchValue!==''?
                getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light}):
                <SearchIcon style={{color:theme.palette.primaryText.light}} />
              }
            </IconButton>
          }
        />
        {
          isPlugin && ListComponent
        }

        {
          !isPlugin &&
          <Popper open={popperOpen} anchorEl={inputRef} placement={'bottom-start'} style={{marginTop:6, marginLeft:0,width:inputRef?inputRef.getBoundingClientRect().width+38:undefined}}>
            <Paper id="search_selector_popper" style={{background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`,paddingBottom:8}}>
              {ListComponent}
            </Paper>
          </Popper>
        }
      </ClickAwayListener>
    </div>
  )
}

HierarchySearchSelector.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  rootId: PropTypes.string,
  rootLabel: PropTypes.string,
  scrollable: PropTypes.bool,
  disabled: PropTypes.bool,
  hideBorder: PropTypes.bool,
  autoFocus: PropTypes.bool,
  height: PropTypes.number,
  onResultClick: PropTypes.func,
  isItemSelected: PropTypes.func,
  isItemDisabled: PropTypes.func,
  replaceItemCheckbox: PropTypes.func,
  testID: PropTypes.string
}



export default withTheme()(withStyles(styles)(HierarchySearchSelector));
