import React from 'react';
import { withStyles,Typography, CircularProgress } from '@material-ui/core';
import BatchChip from './BatchChip';
import theme from '../../../theme';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  caption:{
    color:theme.palette.primaryText.light,
    fontSize:12,
  },
  columnHeader:{
    fontSize:12,
    letterSpacing:2,
    minWidth:120,
    overflow:'hidden',
    marginLeft:16
  },
})

function Body(props) {

  const {
    classes,
    state,
    dispatch,
    history
  } = props;

  const sortJobs = (a,b) => {
    if (a.name < b.name) return -1;
    else return 1;
  }


  return (

    <div className={classes.root}>

      <div style={{position:'sticky',background:theme.palette.background.main,paddingBottom:16,top:180,zIndex:9}}>
        <Typography  className={classes.title}>JOBS</Typography>
        <Typography className={classes.caption}>Click RUN and confirm the job details to start the job. </Typography>
        {
          state.jobsData && state.jobsData.length>0 &&
          <div style={{display:'flex',justifyContent:'flex-end',paddingTop:8,overflow:'hidden'}}>
            <Typography color='primary' className={classes.columnHeader} style={{flex:'0 1 300px'}}>JOB NAME</Typography>
            <Typography color='primary' className={classes.columnHeader} style={{flex:'1 1 300px'}}>DESCRIPTION</Typography>
            <Typography color='primary' className={classes.columnHeader} style={{flex:'0 1 300px'}}>SCHEDULE</Typography>
            <Typography color='primary' className={classes.columnHeader} style={{flex:'0 0 80px'}}>ACTIONS</Typography>
          </div>
        }
      </div>
      <div>
        {
          state.jobsLoading && <CircularProgress color='secondary'/>
        }
        {
          state.jobsError && <Typography className={classes.normalText}>Error occurred loading platform jobs</Typography>
        }
        {
          state.jobsData && state.jobsData.length===0 && <Typography style={{fontSize:13.75}}>No platform jobs</Typography>
        }
        {
          state.jobsData && state.jobsData.filter(el=>el.internal_flag===false && ![701,702].includes(el.id)).sort(sortJobs).map(el=>(
            <BatchChip
              state={state}
              dispatch={dispatch}
              type = {'platform'}
              item={el}
              title={el.name}
              subtitle={el.description}
              history={history}
            />
          ))
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(Body);
