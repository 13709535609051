import Alert from 'react-s-alert';
import React from 'react'
import moment from 'moment';
import UpArrow from '@material-ui/icons/ArrowUpward';
import DownArrow from '@material-ui/icons/ArrowDownward'
import InfoIcon from '@material-ui/icons/InfoOutlined';
import theme, {palette} from './theme'
import axiosCerebrum from './axios-cerebrum'
import numeral from 'numeral'

import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { getIcon } from './IconManager';
import { getAllRules } from './components/UI/InteractiveInput/Templates';
import fileDownload from 'js-file-download';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import cronParser from 'cron-parser'
import {isValidCron} from 'cron-validator'
import { getCategoryColourByName } from './CategoryManager';

export const getIconComponent = getIcon

export function toTitleCase(str, isGlobal = true) {
  if (!str) return '';
  if(!isGlobal){
    return str.replace(/\w\S*/, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const titleCaseObjectName = name => {
  if(!name)return ''
  if(name==='ML_MODEL')return 'ML Model';
  if(name==='DQ TEST')return 'DQ Test';
  return toTitleCase(name.replace(/_/g,' '))
}

export function capitalizeFirstWord(str) {
  if (!str) return '';
  if(typeof(str)!=='string')return str;
  return str.replace(/\w/, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const copyToClipboard = (str) => {
  navigator.clipboard?.writeText(str).catch(error=>{console.log(error)});
};

export const isTagValid = s => {
  return /^[A-Za-z0-9]+[A-Za-z0-9_]*$/.test(s);
}

export const cleanseTag = s => {
  return s.replace(/\s+$/, '');
}

export const onGoToLink = url => {
  if(!url)return;
  if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)){
    url = 'https://' + url;
  }
  window.open(url,'_blank')
}

export const getTrustScoreIcon = ({ trustScore, size }) => {

  const getTrustDown = (colour) => {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" >
        <title>Icons/Trust down</title>
        <g id="Icons/Trust-down" stroke={colour} stroke-width="1" fill={colour}  >
          <path d="M12,1.99999996 L3.99999997,4.99999996 L3.99999997,11.09 C3.99999997,16.14 7.40999998,20.85 12,22 C16.59,20.85 20,16.14 20,11.09 L20,4.99999996 L12,1.99999996 Z M12,16 L6.99999998,11 L9.99999998,11 L9.99999998,6.99999999 L14,6.99999999 L14,11 L17,11 L12,16 Z" id="GppBadFilled" fill={colour} fill-rule="nonzero"></path>
        </g>
    </svg>
    )
  }

  const getTrustMedium = colour => {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" >
        <title>Icons/Trust med</title>
        <g id="Icons/Trust-med" stroke={colour} stroke-width="1" fill={colour} >
          <path d="M12,1.99999996 L20,4.99999996 L20,11.09 C20,16.14 16.59,20.85 12,22 C7.40999998,20.85 3.99999997,16.14 3.99999997,11.09 L3.99999997,11.09 L3.99999997,4.99999996 L12,1.99999996 Z M16,11 L7.99999998,11 L7.99999998,13 L16,13 L16,11 Z" id="GppMaybeFilled" fill={colour} fill-rule="nonzero"></path>
        </g>
      </svg>
    )
  }

  const getTrustUp = colour => {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" >
        <title>Icons/Trust up</title>
        <g id="Icons/Trust-up"stroke={colour} stroke-width="1" fill={colour}>
          <path d="M12,1.99999996 L3.99999997,4.99999996 L3.99999997,11.09 C3.99999997,16.14 7.40999998,20.85 12,22 C16.59,20.85 20,16.14 20,11.09 L20,4.99999996 L12,1.99999996 Z M12,7.00000003 L17,12 L14,12 L14,16 L10,16 L10,12 L7.00000002,12 L12,7.00000003 Z" id="GppGoodFilled" fill={colour} fill-rule="nonzero"></path>
        </g>
      </svg>
    )
  }

  if ((Number(trustScore) >= 80)) {
    return getTrustUp(palette.success.main)
  }
  else if (Number(trustScore) >= 60 && Number(trustScore) < 80) {
    return getTrustUp('#89E32E')
  }
  else if (Number(trustScore) >= 40 && Number(trustScore) < 60) {
    return getTrustMedium(`#F5A623`)
  }
  else if (Number(trustScore) >= 20 && Number(trustScore) < 40) {
    return getTrustDown(`#FC642D`)
  }
  else if (Number(trustScore) >= 0 && Number(trustScore) < 20) {
    return getTrustDown(`#D0021B`)
  }
  else {
    return
  }
}

export const getTrustScoreIconLabel = ({ trustScore }) => {
  if ((Number(trustScore) >= 60)) {
    return 'trust_up'
  }
  else if (Number(trustScore) >= 40 && Number(trustScore) < 60) {
    return 'trust_med'
  }
  else {
    return 'trust_down'
  }
}

export const getTrustScoreTooltip = ({ trustScore, objectType='asset' }) => {
  if(trustScore===undefined)return ''
  if ((Number(trustScore) >= 80)) {
    return `This ${objectType} is highly trusted and verified, has no/minimal data issues, strong documentation, and is frequently used by a range of users.`
  }
  else if (Number(trustScore) >= 60 && Number(trustScore) < 80) {
    return `This ${objectType} is considered reliable. It is frequently used, but needs further documentation or resolution to known issues to reach the highest trust score.`
  }
  else if (Number(trustScore) >= 40 && Number(trustScore) < 60) {
    return `This ${objectType} has a medium trust score due to low documentation or a high number of data issues. First-time users should contact Top Users or Stewards prior to use.`
  }
  else if (Number(trustScore) >= 20 && Number(trustScore) < 40) {
    return `A low trust score indicates that the ${objectType} is rarely used or has many data issues.  Users should contact Top Users or Stewards for more information prior to use.`
  }
  else if (Number(trustScore) >= 0 && Number(trustScore) < 20) {
    return `This is a warning sign that users should be cautious about using this ${objectType}, and they should contact the Data Owner or Steward prior to use.`
  }
  else {
    return
  }
}

export const getTrustScoreBand = ({ trustScore }) => {
  if(trustScore===undefined)return ''
  if ((Number(trustScore) >= 80)) {
    return 'Highest'
  }
  else if (Number(trustScore) >= 60 && Number(trustScore) < 80) {
    return 'High'
  }
  else if (Number(trustScore) >= 40 && Number(trustScore) < 60) {
    return 'Medium'
  }
  else if (Number(trustScore) >= 20 && Number(trustScore) < 40) {
    return 'Low'
  }
  else if (Number(trustScore) >= 0 && Number(trustScore) < 20) {
    return 'Lowest'
  }
  else {
    return
  }
}

export const getTrustScoreColor = (trustScore) => {

  if ((Number(trustScore) >= 80)) {
    return `#00d46A`
  }
  else if (Number(trustScore) >= 60 && Number(trustScore) < 80) {
    return '#4CAF50'
  }
  else if (Number(trustScore) >= 40 && Number(trustScore) < 60) {
    return `#FFAB00`
  }
  else if (Number(trustScore) >= 20 && Number(trustScore) < 40) {
    return `#F76A1C`
  }
  else if (Number(trustScore) >= 0 && Number(trustScore) < 20) {
    return '#F50057'
  }
  else {
    return
  }
}

export const getTestScoreColor = (testScore, isChart) => {
  if(isNaN(testScore))return;

  if ((Number(testScore) === 100)) {
    return isChart?'#00D46A':lighten(`#00D46A`,0.5)
  }
  else if (Number(testScore) >= 76 && Number(testScore) < 100) {
    return isChart?'#4CAF50':lighten(`#4CAF50`,0.5)
  }
  else if (Number(testScore) >= 50 && Number(testScore) < 76) {
    return isChart?'#FFAB00':lighten(`#FFAB00`,0.8)
  }
  else if (Number(testScore) >= 26 && Number(testScore) < 50) {
    return isChart?'#F76A1C':lighten(`#F76A1C`,0.8)
  }
  else if (Number(testScore) >= 1 && Number(testScore) < 26) {
    return isChart?'#F50057':lighten(`#F50057`,0.8)
  }
  else {
    return isChart?'#C62828':lighten(`#C62828`,0.8)
  }
}

export const formatLineageText = txt => {
  if(['UPSTREAM_ONLY','UPSTREAM_QUERY_ONLY'].includes(txt))return 'Upstream sources'
  if(['DOWNSTREAM_ONLY'].includes(txt))return 'Downstream sources'
  if(['UPSTREAM_DOWNSTREAM','UPSTREAM_QUERY_DOWNSTREAM'].includes(txt))return 'Up & Downstream sources'
  return txt
}

export const getLineageIcon = ({item,size,colour=palette.primary.main,forceField}) => {
  let fieldName = forceField || (item.active_txt==='YES'?'active_lineage_inc_reference_txt':'lineage_txt')
  let label;
  if(['DOWNSTREAM_ONLY'].includes(item[fieldName]))label = 'lineage_downstream'
  if(['UPSTREAM_ONLY','UPSTREAM_QUERY_ONLY'].includes(item[fieldName]))label = 'lineage_upstream'
  if(['UPSTREAM_DOWNSTREAM','UPSTREAM_QUERY_DOWNSTREAM'].includes(item[fieldName]))label = 'lineage_up_downstream'
  return label?getIconComponent({label,size,colour}):<></>
}

export const issueStatus = ['OPEN','IN_PROGRESS','CLOSED'];

export const issueTypes = ['INCOMPLETE_DATA','DUPLICATE_DATA','INCORRECT_DATA','INCONSISTENT_DATA','INVALID_FORMAT','OTHER'];

export const issueSeverities = ['HIGH','MEDIUM','LOW'];

export const getStatusColour = status => {
  switch (status) {
    case 'CLOSED':
      return palette.success.main
    default:
      return palette.primary.main
  }

}

export const getSeverityColour = (severity) => {
  switch (severity.toUpperCase()) {
    case 'HIGH':
      return red[500]
    case 'MEDIUM':
      return orange[500]
    case 'LOW':
      return blue[500]
    default:
  }
}

export const getSeverityIcon = ({ severity, size }) => {
  const style = { width: size, height: size }
  switch (severity) {
    case 'HIGH':
    case 30:
      return <UpArrow style={{ ...style, color: getSeverityColour(severity) }} />
    case 'MEDIUM':
    case 20:
      return <UpArrow style={{ ...style, color: getSeverityColour(severity) }} />
    case 'LOW':
    case 10:
      return <DownArrow style={{ ...style, color: getSeverityColour(severity) }} />
    default:
      return <InfoIcon style={{ ...style, color: palette.primary.main }} />
  }
}

export const getNoteColour = (noteType='') => {
  switch(noteType.toLowerCase()){
    case 'link':
      return '#42A5F5'
    default:
      return palette.primary.main
  }
}

export const getDispFields = (item,field,isApplyBoldText) => {
  let label,collectionName;
  if(item.labels)label=item.labels.toLowerCase();
  if(item.object)label=item.object.name.toLowerCase();
  if(item.object_type_txt)label=item.object_type_txt.toLowerCase();
  if(item.collection_name)collectionName=item.collection_name.toLowerCase();
  if(item.collection_txt)collectionName=item.collection_txt.toLowerCase();
  if(item.parent)collectionName=item.parent.name.toLowerCase();

  switch (field) {
    case 'dispTitle':
      if(label==='collection_instance'){
        if(collectionName === 'location'){
          return (item.additionalProperties && JSON.parse(item.additionalProperties).Title) || item.name_txt || item.name || item.id
        }
        let tables = item.tables || item.tables_txts;
        if(collectionName === 'table cluster' && tables && tables.length>0){
          let name = tables.reduce(
            function(accumulator, currentValue) {
              return accumulator.concat(currentValue.split('.').slice(-1)[0])
            },
            []
          ).join(', ');
          if(name.length>80)name = name.slice(0,77)+'...';
          return name
        }
        else{
          return item.name_txt || item.name
        }
      }
      if(['query','code'].includes(label) && item.display_id){
        if(item.name && item.name.trim()!=='' && !item.name.includes(item.signature) && item.name!==item.id)return item.name;
        return item.name_txt || 'Query-'+item.display_id;
      }
      if(label==='source' || label==='host'){
        return item.alternate_name_txt || item.alternate_name || item.alternate_name || item.name_txt || item.name || 'Unknown'
      }
      if(item.name_txt)return item.name_txt
      else{
        return item.name_txt || item.name || `${label?label+'-':''}${item.id}`
      }
    case 'dispSubtitle':
      if(label === 'schema'){
        let additionalInfo = [];
        if(item.database_txt)additionalInfo.push(item.database_txt)
        if(additionalInfo.length>0){
          let text = additionalInfo;
          if(item.source_txt){
            if(isApplyBoldText){
              return <span style={{wordBreak:'break-all'}}><span style={{fontWeight:700}}>{text}</span> ({item.source_txt})</span>
            }
            text+= ` (${item.source_txt})`
            return text;
          }
          if(isApplyBoldText){
            return <span style={{fontWeight:700,wordBreak:'break-all'}}>{text}</span>
          }
          return text;
        }
        if(item.location_txt)return item.location_txt
        if(item.location)return item.location
      }
      if(label === 'table'){
        let additionalInfo = [];
        if(item.database_txt)additionalInfo.push(item.database_txt)
        if(item.schema_txt)additionalInfo.push(item.schema_txt)
        if(additionalInfo.length>0){
          let text = additionalInfo.join(' / ');
          if(item.source_txt)text+= ` (${item.source_txt})`
          return text;
        }
        if(item.location_txt)return item.location_txt
        if(item.signature){
          return item.signature.split('.').slice(1, 3).join(' / ').toUpperCase();
        }
        if(item.nodeKey){
          return item.nodeKey.split('.').slice(1, 3).join(' / ').toUpperCase();
        }
        if(item.location && item.location!=='')return item.location
      }
      if(label==='source' || label==='host'){
        return item.name_txt || item.host || item.name || 'Unknown'
      }
      if(label === 'column'){
        let additionalInfo = [];
        if(item.database_txt)additionalInfo.push(item.database_txt)
        if(item.schema_txt)additionalInfo.push(item.schema_txt)
        if(item.table_txt)additionalInfo.push(item.table_txt)
        if(additionalInfo.length>0){
          let text = additionalInfo.join(' / ');
          if(item.source_txt)text+= ` (${item.source_txt})`
          return text;
        }
        if(item.location_txt)return item.location_txt
        if(item.nodeKey){
          return item.nodeKey.split('.').slice(1, 4).join(' / ').toUpperCase();
        }
        if(item.signature){
          return item.signature.split('.').slice(1, 4).join(' / ').toUpperCase();
        }
        if(item.location && item.location!=='')return item.location
      }
      else if(label==='collection_instance' && collectionName==='access role' && item.source_type_txt){
        let text = item.source_type_txt;
        if(item.source_txt)text += ` (${item.source_txt})`
        return text
      }
      else if(label==='collection_instance' && collectionName==='job type'){
        return ''
      }
      else if(label==='query' || (label==='code' && item && item.code_type_txt==='QUERY')){
        return item.code || ' '
      }
      else if(item.location_txt){
        return item.location_txt;
      }
      else if(label === 'user' && item.groups){
        return item.groups.join(', ');
      }
      else if(label === 'user' && item.teams_txts){
        return item.teams_txts.join(', ');
      }
      else if(label === 'schema' || label === 'macro' || label === 'procedure' || label === 'query'){
        if(item.location && item.location!=='')return item.location
        let nodeKeySplitted = [];
        if(item.nodeKey){
          nodeKeySplitted = item.nodeKey.split('.');
        }
        if(item.signature){
          nodeKeySplitted = item.signature.split('.');
        }
        if(nodeKeySplitted.length<3 || nodeKeySplitted.find(s=>s===''))return '';
        return nodeKeySplitted[1];
      }
      else if(label === 'database'){
        if(item.location && item.location!=='')return item.location
        if(item.nodeKey){
          return item.nodeKey.split('.')[0];
        }
        if(item.signature){
          return item.signature.split('.')[0];
        }
      }
      else if(label === 'issue' || ((item.object && item.object.name.toLowerCase()==='issue'))){
        if(item.displayId)return `ISSUE-${item.displayId}`;
        if(item.display_id)return `ISSUE-${item.display_id}`;
        if(item.description)return item.description
        return '';
      }
      else if(collectionName === 'location' || label === 'query cluster'){
        return item.name;
      }
      else if(label === 'collection'){
        if(item.location && item.location!=='')return item.location
        return item.category?item.category.replace(/_/g,' '):''
      }
      else if(label==='collection_instance'){
        if(item.location && item.location!=='')return item.location
        return collectionName?collectionName.replace(/_/g,' '):''
      }
      else if(label === 'group'){
        return undefined
      }
      else{
        if(item.location && item.location!=='')return item.location;
        if(item.path && item.path!=='')return item.location;
        return undefined
      }
      break;
    case 'dispBody':
      if(label==='query' || label==='code'){
        return item.code || ''
      }
      return item.description || 'No description'
    default:
      return item.nodeKey;
  }
}

export const formatBusinessnName = ({roles, dispTitle, businessName, item, returnCustomPayload, isBoldText}) => {
  let shownTitle = dispTitle || item?.name_txt || item?.name;
  businessName = (businessName || item?.alternate_name || item?.alternate_name_txt || '').trim()
  let label = (item?.object_type_txt || item?.object?.name || item?.type || '').toLowerCase()
  let primaryName, secondaryName, isBusinessPrimary;
  if(businessName){
    if(label==='data_quality_test'){
      shownTitle = `${businessName} (${dispTitle})`
      if(isBoldText){
        shownTitle = <><span style={{fontWeight:700}}>{businessName}</span> ({dispTitle})</>
      }
      primaryName = businessName;
      secondaryName = dispTitle;
      isBusinessPrimary = true;
    }else{
      if(!roles.every(el=>el==='90')){
        shownTitle =  `${dispTitle} (${businessName})`
        if(isBoldText){
          shownTitle = <>{dispTitle} (<span style={{fontWeight:700}}>{businessName}</span>)</>
        }
        primaryName = dispTitle;
        secondaryName = businessName;
        isBusinessPrimary = false;
      }
      else{
        shownTitle = `${businessName} (${dispTitle})`
        if(isBoldText){
          shownTitle = <><span style={{fontWeight:700}}>{businessName}</span> ({dispTitle})</>
        }
        primaryName = businessName;
        secondaryName = dispTitle;
        isBusinessPrimary = true;
      }
    }
  }else{
    primaryName = shownTitle;
    secondaryName = '';
    isBusinessPrimary = false;
  }
  if(returnCustomPayload){
    return {
      primaryName,
      secondaryName,
      isBusinessPrimary
    }
  }
  return shownTitle
}

export const onClickResultItem = ({ item, id, label, history, newWindow = false, urlOnly=false, query }) => {
  if(item && item.source_template)label='source';
  if(!label)return;
  label = label.toLowerCase();
  if(label==='host')label = 'source'
  if(label==='code'){
    label = item?(item.code_type_txt||'query').toLowerCase():'query'
  }
  if(label==='report')label='content';
  if(label==='sheet')label='content_child'
  if(label==='team')label='group'
  let uri;
  uri = `/profile/${label}/${id}`

  if(!uri)return;

  if(query && query.length>0){
    uri += `?${query.join('&')}`
  }

  if(urlOnly)return uri;

  if (newWindow) {
    window.open(uri, '_blank');
  }else{
    if(history)history.push(uri);
    else{window.open(uri,"_self")}
  }
}

export const handleShareClick = (url,msg) => {
  copyToClipboard(url || window.location.href)
  Alert.info(msg || "Copied link to clipboard", {
    position: 'top',
    effect: 'stackslide',
  });
}

// given a name, returns initial
export const getInitials = function (string) {
  if (!string) return;
  var names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

// assumes all numbers are integers >= 0
// when the value is 0 return log value -1 (just a flag)
export const transformLogScale = (nums, logbase) => {
  function getBaseLog(base, value) {
    return Math.log(value) / Math.log(base);
  }

  let max = Math.max(...nums);
  if(nums.filter(el=>isNaN(el)).length>0 || max<=0){
    return {logValue:nums.map(()=>0),actualValue:nums};
  }

  let logValue = [];
  nums.forEach(el=>{
    logValue.push(el===0?-1:getBaseLog(logbase,el))
  })

  return {logValue,actualValue:nums}
}


export const getFontColourByBackground = (background) => {
  // determine the luminance of the background color, then decide if the font should be white or black
  // background is a hex color
  // need to cater for 3 or 6 digit hex colors
  if(!background)return '#000';
  let r, g, b;
  if (background.length === 4) {
    r = parseInt(background[1] + background[1], 16);
    g = parseInt(background[2] + background[2], 16);
    b = parseInt(background[3] + background[3], 16);
  } else {
    r = parseInt(background[1] + background[2], 16);
    g = parseInt(background[3] + background[4], 16);
    b = parseInt(background[5] + background[6], 16);
  }
  let luminance = (0.252 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000' : '#fff';
};

export const getCollectionColour = (label,type,value) => {
  let colour, fontColour;
  colour = type==='manual'?'#FC642D':'#FFAB00'
  label = label?.toLowerCase();
  // fontColour = type==='manual'?'#FFF':'#000'
  if(['glossary','term'].includes(type)){
    colour = '#00D46A'
    // fontColour = '#fff'
  }
  if(label==='category'){
    colour = getCategoryColourByName(value)
    fontColour = getFontColourByBackground(colour)
  }
  if(label && ['pii data detected','pii metadata linked'].includes(label)){
    colour='#B73029'
    // fontColour='#fff'
  }
  if(label && value && 'usage'===label && ['unused','infrequent'].includes(value)){
    colour="#E4E4E4"
    // fontColour='#000'
  }
  if(label && value && 'usage'===label && !['unused','infrequent'].includes(value)){
    colour="#00D46A"
    // fontColour='#000'
  }
  if(label==='verified' || label==='verified use case'){
    colour="#00D46A"
    // fontColour='#000'
  }
  if(['classification','verified not'].includes(label)){
    colour='#E01F21';
    // fontColour='#fff'
  }
  if(['warning notice'].includes(label)){
    colour = theme.palette.warning.main;
  }
  if(label==='domain'){
    colour=palette.primary.dark;
    // fontColour=palette.background.main
  }
  fontColour = getFontColourByBackground(colour)
  return {colour,fontColour}
}

// low->high
export const collectionColourOrder = {
  '#00D46A':1,
  '#E4E4E4':2,
  '#FFAB00':3,
  '#FC642D':4,
  '#B73029':5,
}

const getTagOrder = label => {
  if(['load status','load status','load frequency'].includes(label))return 1;
  if(label==='zone')return 2;
  if(label==='usage')return 3;
  if(label==='period')return 7;
  if(['pii data detected','pii metadata linked'].includes(label))return 10;
  return 5
}

export const headerAutoCollectionList = ['collection_usage','collection_zone','consumed_by_type','created_by_type','load_frequency','load_status','trend','user_usage_type']
export const autoCollectionShortNames = ['consumed','created','key','load freq','load status','trend','usage','user type','user usage type','zone']
export const platformCollectionShortNames = ['classification','category','data role','pii data det','pii meta linked','verified','not verified','domain']

export const processCollection = (tags,type,componentName,variant) => {

  const isHiddenLabel = (label,name) => {
    name = name.toLowerCase();

    if(componentName==='catalogue'){
      if(platformCollectionShortNames.includes(label) || autoCollectionShortNames.includes(label))return true;
    }
    if(label==='usage' && name==='unknown')return true;
    if(['verified use case','user type','channel','access role','verified','domain','data quality dimension','warning notice'].includes(label))return true;
    if(['resultItem','catalgue'].includes(componentName) ){
      if(name==='unknown' || (['key for team','month end','load frequencey','trend','period','consumed by type','created by type','consumed','created'].includes(label))){
        return true;
      }
    }
    if(componentName==='header' && (['warning notice','key for team','month end','consumed by type','created by type','trend','classification','location','pii data detected','category'].includes(label)))return true;
    if(['load status','load frequencey'].includes(label)){
      if(name==='unknown')return true;
    }
    if(label==='table cluster' || label==='query cluster')return true;
    if(label==='trend' && name==='stable')return true;
    if(['pii data detected','pii metadata linked'].includes(label) && name==='unknown')return true;
    return false;
  }

  let arr = [];
  let count = 0;
  if(!tags)return {tags:[],tagsCount:0}
  if(variant==='solr'){
    let collections = Object.keys(tags);
    if(type==='auto')collections = collections.filter(c=>autoCollectionShortNames.find(cn=>c.split('_txt').length===2 && cn===c.split('_txt')[0].replace(/_/g,' ')))
    else{collections = collections.filter(el=>el.includes('_kc_txts')).filter(c=>!autoCollectionShortNames.includes(c.split('_kc_txts')[0].replace(/_/g,' ')))}
    collections.forEach(el => {
      let label = el.split('_txt')[0].replace(/_/g,' ');
      let value = tags[el];
      if(!value)return;
      // check if value is arrary
      if(Array.isArray(value)){
        value = value.join(',');
      }
      if(type!=='auto'){
        value = tags[el].join(',');
        label = el.split('_kc_txts')[0].replace(/_/g,' ');
      }
      if(label==='load freq')label='load frequency'
      if(label==='pii data det')label='pii data detected'
      if(label==='pii meta linked')label='pii metadata linked'
      if(label==='key')label='key for team'
      if(isHiddenLabel(label,value))return;
      let {colour, fontColour} = getCollectionColour(label,type,value)
      let obj = {name:(label + ': ' + value).toUpperCase(),colour,fontColour,label,parent_name:label}
      arr.push(obj)
      count++;
    })
    arr = arr.sort((a,b)=>getTagOrder(a.label)-getTagOrder(b.label))
  }
  if(variant==='cerebrum'){
    if(type==='manual'){
      tags.forEach(el=>{
        let parentName = el.parent_name.toLowerCase().replace(/_/g,' ')
        if(isHiddenLabel(parentName.toLowerCase(), el.name.toLowerCase())) return;
        let {colour, fontColour} = getCollectionColour(parentName,'manual',el.name)
        arr.push({name:(el.short_name || el.parent_name) + ': ' + el.name, colour, fontColour, id:el.id, parent_name:parentName,obj:el})
        count++;
      })
    }
    if(type==='auto'){
      tags.forEach(el=>{
        let parentName = el.parent_name.toLowerCase().replace(/_/g,' ')
        let {colour, fontColour} = getCollectionColour(parentName,'auto',el.name)
        if(parentName==='collection usage')parentName = 'usage';
        if(parentName==='collection zone')parentName = 'zone';
        if(isHiddenLabel(parentName.toLowerCase(), el.name.toLowerCase())) return;
        arr.push({name:toTitleCase(parentName) + ': ' + toTitleCase(el.name),colour,fontColour,id:el.id,parent_name:parentName,obj:el})
        count++;
      })
    }

    //// group by parent_name if its' header tag
    let multiInstanceParents = [];
    arr.forEach(el=>{
      if(el.parent_name && arr.filter(a=>a.parent_name===el.parent_name).length>1 && !multiInstanceParents.includes(el.parent_name)){
        multiInstanceParents.push(el.parent_name)
      }
    })
    multiInstanceParents.forEach(el=>{
      let children = arr.filter(a=>a.parent_name===el);
      let obj = {
        id: el,
        name: children.length + ' ' + el + '(s)',
        colour: children[0].colour,
        fontColour: children[0].fontColour,
        children,
        parent_name:el,
        isGroup:true,
      }
      arr = arr.filter(a=>a.parent_name!==el);
      arr.push(obj)
    })
  }

  let finalTags = arr.sort((a,b)=>{
    if(collectionColourOrder[a.colour]-collectionColourOrder[b.colour]!==0){
      return collectionColourOrder[a.colour]-collectionColourOrder[b.colour]
    }else{
      if(a.parent_name<b.parent_name)return -1;
      return 1
    }
  })

  return {
    tags: finalTags,
    tagsCount:count
  };
}

export const setInitialState = (pageCache, initialState, hardcodeID) => {
  let id = hardcodeID || window.location.href.split('?')[0]
  let matchedCache = pageCache.find(el=>el.cacheID===id);
  if(matchedCache && Math.abs(moment(matchedCache.updated).diff(moment(),'minutes'))<30){
    return {...initialState,...matchedCache};
  }
  return initialState
}

export const getUserRoles = roles => {
  let env = process.env.NODE_ENV || 'development';
  if(localStorage.hasOwnProperty('mock_role') && env==='development'){
    roles = localStorage.getItem('mock_role').split(',')
    return roles
  }
  if(!roles)return [];
  roles = roles.map(el=>el.toLowerCase());
  const arr = [];
  roles.includes('kada_admin') && arr.push('00');
  roles.includes('kada_data_manager') && arr.push('10');
  roles.includes('kada_user') && arr.push('20');
  roles.includes('kada_data_gov_user') && arr.push('40')
  roles.includes('kada_business_user') && arr.push('90');
  return arr;
}

export const getLabelPlural = (label, isRemoveUnderline=true) => {
  switch(label.toLowerCase()){
    case 'mode':
    case 'tableau':
    // case 'content':
      return label.toLowerCase();
    case 'query':
      return 'queries';
    case 'content_app':
      return isRemoveUnderline?'contentapps':'content_apps'
    case 'content_child':
      return isRemoveUnderline?'contentchildren':'content_children';
    case 'collection_instance':
      return isRemoveUnderline?'collectioninstances':'collection_instances';
    case 'data_quality_test':
      return isRemoveUnderline?'dataqualitytests':'data_quality_tests';
    case 'dataset_table':
      return isRemoveUnderline?'datasettables':'dataset_tables';
    case 'ml_model':
      return isRemoveUnderline?'mlmodels':'ml_models';
    default:
      return isRemoveUnderline?label.replace(/_/g,'').toLowerCase()+'s':label+'s'
  }
}


export const isInViewport = (ref, offset = 0) => {
  if (!ref || !ref.current) return false;
  const bottom = ref.current.getBoundingClientRect().top;
  return (bottom + offset) >= 0 && (bottom + offset) <= window.innerHeight;
}

export  const getJobResult = (id, setData, setError, onTimeOut, ignoreTimeOut) => {
  let timeoutCount = 0;
  const recursiveFetch = () => {
    axiosCerebrum.get(
      `/api/jobexecutions/${id}`
    ).then(response => {
      if(response.data.job_status==='FAILED'){
        setError(response.data.error || true)
        return;
      }
      if (response.data.job_status==='COMPLETE') {
        axiosCerebrum
          .get(`/api/jobexecutions/${id}/results`)
          .then(result=>{
            setData(result.data)
          })
          .catch(error=>{
            console.log(error)
            setError(error)
          })
        return;
      }
      setTimeout(() => {
        if(timeoutCount>=300){
          if(onTimeOut)onTimeOut();
          else if(setError)setError();
          return;
        }else{
          timeoutCount+=1;
          recursiveFetch();
        }
      }, 1000);
    }).catch(error => {
      console.log(error)
      setError()
    })
  }
  recursiveFetch()

}

export const getExtractResult = ({id, onSuccess, onError, defaultFileName}) => {
  axiosCerebrum.get(`/api/extracts/${id}`).then(response=>{
    if(response.data.status==='COMPLETE'){
      axiosCerebrum.get(`/api/extracts/${id}/download`,{responseType:"blob"})
        .then(downloadRes=>{
          let fileName = defaultFileName || downloadRes.headers['content-disposition'].split('filename=')[1];
          fileDownload(downloadRes.data, fileName);
          onSuccess && onSuccess(downloadRes)
        })
        .catch(error=>{
          console.log(error)
          onError && onError(error)
        })
      return;
    }
    if(response.data.status==='FAILED'){
      onError && onError()
      return;
    }
    setTimeout(()=>{
      getExtractResult({id, onSuccess, onError, defaultFileName})
    },2000)
  }).catch(error=>{
    console.log(error);
    onError();
  })
}

export const updateElement = (id, attributes) => {
  let element = document.getElementById(id);
  if(!element || !attributes)return;
  attributes.forEach(el=>{
    element.setAttribute(el.name,el.value)
  })
}

export const formatNumber = (number, noDecimal, convertThousand, commaOnly) => {
  if(isNaN(number))return number;
  if(commaOnly)return numeral(number).format('0,0')
  if(number>=(convertThousand?1000:10000) || number<=(convertThousand?-1000:-10000))return numeral(number).format(noDecimal?'0a':'0.0a');
  if((number+'').includes('.'))return numeral(number).format(noDecimal?'0,0':'0,0.0')
  return numeral(number).format('0,0')
}

export const scrollIntoElemenet = (id,delay,options) => {
  let count = 0;
  const scrollInto = () => {
    setTimeout(()=>{
      if(count>200)return;
      let section = document.getElementById(id);
      if(!section){
        scrollInto();
        count += 1;
        return;
      }
      section && section.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    },isNaN(delay)?750:delay)
  }
  scrollInto();
}

export const mapObjectName = (objectType, ignoreCode) => {
  if(!objectType)return '';
  switch(objectType.toUpperCase()){
    case 'CONTENT':
      return 'REPORT'
    case 'CONTENT_CHILD':
      return 'SHEET';
    case 'GROUP':
      return 'TEAM'
    case 'DATA_PIPELINE':
      return 'DATA_PIPELINE'
    case 'QUERY':
      return ignoreCode?'QUERY':'CODE'
    case 'PROCEDURE':
      return ignoreCode?'PROCEDURE':'CODE'
    case 'MACRO':
      return ignoreCode?'MACRO':'CODE'
    case 'HOST':
      return 'SOURCE'
    case 'DATA_QUALITY_TEST':
      return 'DQ TEST'
    default:
      return objectType.toUpperCase()
  }
}

export const mapSearchObjectName = (newObjectType, codeType) => {
  if(!newObjectType)return '';
  switch(newObjectType.toUpperCase()){
    case 'REPORT':
      return 'CONTENT'
    case 'SHEET':
      return 'CONTENT_CHILD';
    case 'CODE':
      return codeType || 'QUERY'
    case 'TEAM':
      return 'GROUP'
    case 'SOURCE':
      return 'HOST'
    default:
      return newObjectType.toUpperCase()
  }
}

export const getIconLabelByIntegration = (integration) => {
  switch(integration.source_template.name){
    case 'JIRA':
    case 'JIRA_SOFTWARE':
      return 'jira';
    case 'JIRA_SERVICE_MANAGEMENT':
      return 'jira_service_management'
    default:
      return 'integration'
  }
}

export const getSearchMode = () => {
  if(localStorage.hasOwnProperty('advance_search'))return 'advance_search';
  return 'basic_search'
}

export const collectionIds = {
  verifiedUseCase: '0ffdae93-b46c-38c2-8511-8183f1d89c8d',
  classification: 'e1fd2337-1b0e-3841-8d60-7c85daa1707e',
  channel:'8ee1fb9f-4385-3aa5-aaa0-174da1048d4b',
  accessRole:'5a3c3e73-5790-3335-8dbd-f65b4f169e36',
  dataRole:'b9b1c68c-4d8c-3726-b078-15fb6645ea7d',
  list:'99f06985-21df-3d80-b45b-d5dc8be2d249',
  domain:'73f92690-2edd-33a9-ad32-4f9c225a5c18',
  dqDimensions:'ea698091-d256-3aa3-948c-1562bf76c4b6',
  // piiDetectedMeta:'0ffc122b-1ed7-39f6-9040-01ae076ddc22',
  piiDetected:'3dbf7c4f-1d05-3b75-9920-9122e6eaed56',
  piiMetaLinked:'f2c66fe8-4fc7-3ec8-bf01-9e4fb22c1970',
  question:'478b6338-b94f-3344-b071-2b6b9c39899b',
  dataProduct:'2e6cca41-23a2-3731-ab73-eef3bbca285f',
  category:'e86aec69-e7a1-3059-8879-7d73eaf0c79c',
  jobType:'8e5676eb-d05b-3e06-8c55-4fcc2ced1611',
  warningNotice:'bb741d68-dd39-37fa-80cb-af822855ebe1',
  sourceLayer:'a40c5104-fcd3-3da3-92db-810c2e4fd74a'
}

export const instanceIds = {
  dataOwner: '16e05af2-13fa-301d-b7fa-69d48bc71d7d',
  dataSteward: 'd13d6b10-a535-3718-854d-459f086ad057'
}

export const validOwnerObjectNames = "ISSUE,TABLE,COLUMN,SCHEMA,CONTENT,DATASET,DATASET_TABLE,DATASET_FIELD,DATA_PIPELINE,QUERY,CONTENT_CHILD,MACRO,PROCEDURE,COLLECTION_INSTANCE,ML_MODEL"

export const checkOwnedCount = async sessionData => {
  let data;
  await axiosCerebrum
  .get(`/api/users/${sessionData.id}/related`,{params:{relationship:'OWNER_OF',object_name:validOwnerObjectNames,per_page:0}})
    .then(response=>{
      data = response.data
    })
    .catch(error=>{
      console.log(error)
      data = {total:0}
    })
  return data
}

export const checkStewardedCount = async sessionData => {
  let data;
  await axiosCerebrum
    .get(`/api/users/${sessionData.id}/related`,{params:{relationship:'STEWARD_OF',object_name:validOwnerObjectNames,per_page:0}})
    .then(response=>{
      data = response.data
    })
    .catch(error=>{
      console.log(error)
      data = {total:0}
    })
  return data
}

export const removeUrlQueryArg = ({url, keys}) => {
  if(!url)return;
  let splitted = url.split('?');
  if(splitted.length===1)return url;
  let argsSplitted = splitted[1].split('&');
  let newArgs = [];
  argsSplitted.forEach(a=>{
    if(!keys.includes(a.split('=')[0]))newArgs.push(a)
  })
  return splitted[0]+(newArgs.length>0?'?':'')+newArgs.join('&')
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
    );
};

export const validateUrl = (url) => {
  if(!url.toLowerCase().match(/^https?:\/\//i) && !url.toLowerCase().match(/^http?:\/\//i)){
    return false;
  }
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

export const getSourceObjectCount = (name, type) => {
  if(type==='DATABASE')return 'TABLE';
  if(type==='TOOL'){
    if(['AIRFLOW','ALTERYX','ALTERYX_SERVER','DBT_CLOUD','DBT_CORE','FIVETRAN','GENERIC_TOOLS','INFORMATICA','IICS','INFORMATICA_POWERCENTER','SSIS','HEVO','FIVETRAN','AZURE_DATA_FACTORY','HIGHTOUCH'].includes(name)){
      return 'DATA_PIPELINE'
    }
    if(['AZURE_WINDOWS_AD','LDAP'].includes(name)){
      return 'USER'
    }
    if(['GENERIC_ML_TOOL'].includes(name)){
      return 'ML_MODEL'
    }
    if(['GREAT_EXPECTATIONS'].includes(name)){
      return 'DATA_QUALITY_TEST'
    }
    return 'REPORT'
  }
  return 'TABLE'
}

export const getPartialMatchSearchString = (str,fuzzy,allowSpace) => {
  if(str.trim()==='')return '*';
  if(!str.match(/[a-zA-Z0-9_-]$/) && !allowSpace)return str
  return `(${str.trim()}) OR (${fuzzy?'*':''}${str.trim() + '*'})`
}

export const trimRichText = (str, limit) => {
  const rules = getAllRules().map(el=>new RegExp(el.rule,'g'))
  str = str.replace(/<br>/g,'\n')
  let foundLimit = -1;
  rules.forEach(r=>{
    if(foundLimit>0)return;
    let match = r.exec(str);
    while(match && match!==null){
      if(match[0].length>limit-(match.index-10)){
        foundLimit = match.index + match[0].length + 2;
        break;
      }
      match = r.exec(str)
    }
  })

  if(foundLimit>=0){
    if(foundLimit>=str.length)return str;
    return str.slice(0,foundLimit)+'...'
  }else{
    if(str.length>limit){
      return str.slice(0,limit)+'...'
    }
    return str
  }
}

export const setHelpWdigetVisibility = visible => {
  if(!document.getElementById('jsd-widget'))return;
  document.getElementById('jsd-widget').style.visibility = visible?'visible':'hidden'
}


export const getItemChildType = (item, alwaysShow) => {
  if(!item)return ''
  let childType = alwaysShow?'Child Item':undefined;
  switch(item.object_type_txt){
    case 'DATABASE':
      childType = 'Schema'
      break;
    case 'SCHEMA':
    case 'HOST':
    case 'SOURCE':
    case 'WORKSPACE':
    case 'TOOL':
      childType = 'Asset'
      break;
    case 'TABLE':
      childType = 'Column'
      break;
    case 'CONTENT_APP':
      childType = 'Report'
      break;
    case 'REPORT':
      childType = 'Sheet'
      break;
    case 'DATA_PIPELINE':
      childType = 'Pipeline'
      break;
    case 'DATASET':
      childType = 'Dataset Table'
      break;
    case 'DATASET_TABLE':
      childType = 'Dataset Field'
      break;
    case 'COLLECTION':
      if(item.collection_type_txt==='GLOSSARY'){
        childType = 'Term'
      }
      else if(item.collection_type_txt==='KNOWLEDGE'){
        if(item.name_txt==='Question')childType = 'Question'
        else{childType = 'Article'}
      }
      else{
        childType = 'Instance'
      }
      break;
    case 'COLLECTION_INSTANCE':
      if(item.collection_type_txt==='GLOSSARY'){
        childType = 'child Term'
      }
      else if(item.collection_txt==='VERIFIED USE CASE'){
        childType = 'child use case'
      }
      else{
        childType = 'child Instance'
      }
      break;
    case 'TEAM':
      childType = 'Team'
      break;
    default:
  }
  return childType
}

export const tabGroup = [
  {
    name:'DATA',
    tabs:['TABLE','COLUMN','SCHEMA','DATABASE'],
  },
  {
    name:'CONTENT',
    tabs:['CONTENT_APP','CONTENT','CONTENT_CHILD','REPORT','SHEET','DATASET','DATASET_FIELD','DATASET_TABLE','DATA_PIPELINE','ML_MODEL','FILE','WORKSPACE','TOOL'],
  },
  {
    name:'CODE',
    tabs:['CODE','QUERY','MACRO','PROCEDURE','DATA_QUALITY_TEST'],
  },
  {
    name:'PEOPLE',
    tabs:['USER','GROUP'],
  },
  {
    name:'COLLECTIONS',
    tabs:['COLLECTION_INSTANCE','COLLECTION'],
  },
  {
    name:'ISSUES',
    tabs:['ISSUE'],
  },
  {
    name:'TAGS',
    tabs:['TAG'],
  },
  {
    name:'SOURCE',
    tabs:['HOST','SOURCE'],
  },
]

export const sendMessage = msg => {
  window.postMessage(msg,document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
}

export const getCustomerName = () => {
  let customerName = '';
  try{
    customerName = JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===200).value;
  }catch{}
  return customerName
}

export const getKnowledgeBaseUrl = () => {
  let knowledgeBaseUrl = '';
  try{
    knowledgeBaseUrl = JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===7000).value;
    if (knowledgeBaseUrl.trim()!=='' && !knowledgeBaseUrl.match(/^https?:\/\//i) && !knowledgeBaseUrl.match(/^http?:\/\//i)){
      knowledgeBaseUrl = 'https://' + knowledgeBaseUrl;
    }
  }catch{}
  return knowledgeBaseUrl
}

export const getUsageFreqTooltip = usage => {
  switch(usage?.toUpperCase()){
    case 'DAILY':
      return 'Data asset was used 10+ weekdays over the last 3 weeks'
    case 'WEEKLY':
      return 'Data asset was used in 3 different weeks over the 4 weeks'
    case 'MONTHLY':
      return 'Data asset was used in 3 different months over the last 4 months'
    case 'INFREQUENT':
      return 'Data asset was used within the last 4 months'
    case 'UNUSED':
      return "Data asset has not be used within the last 4 months"
    default:
      return usage;
  }
}

export const getNameSearchQuery = search => {
  return !search||search.trim()===''?'*':`name_txt:(${getPartialMatchSearchString(search,true,true)}) OR alternate_name_txt:(${getPartialMatchSearchString(search,true,true)})`
}

export const getVersionNumber = () => {
  return localStorage.getItem('k_platform_version') || ''
}

export const checkIsValidCron = str => {
  let isCronValid = false;
  try{
    cronParser.parseExpression(str)
    isCronValid = true;
    if(str.trim()==='')isCronValid = false;
    if(str.trim().split(' ').length>5)isCronValid = false;
    isCronValid = isValidCron(str)
  }catch(err){
    isCronValid = false;
  }
  return isCronValid
}


export const checkIsHexColour = (value) => {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f]{6}[0-9]{2})$/.test(value)
}

export const convertMBSizes = value => {
  // convert mb, gb, or tb, based on value
  let size = value;
  let unit = ' MB';
  if(size>1024){
    size = size/1024;
    unit = ' GB';
  }
  if(size>1024){
    size = size/1024;
    unit = ' TB';
  }
  return size.toFixed(2) + unit
}

export const formatTimeToUtc = time => {
  if(!time.utc)time = moment(time);
  return time.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
}
