import React, { useState , useEffect, useRef} from 'react';
import { withTheme,withStyles, Typography, CircularProgress, Button, IconButton} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import { getExtractResult, getIconComponent, isInViewport} from '../../../utilities'
import KTooltip from '../../UI/KTooltip/KTooltip';
import moment from 'moment';
import CancelModal from '../../PlatformManagement/Monitor/CancelModal';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {

  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },
  columnRow:{
    height:48,
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    display:'flex',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    // cursor:'pointer',
    // '&:hover':{
    //   background:theme.palette.hovered.main
    // }
  },
  titleRow:{
    display:'flex',
    // marginBottom:18
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  statusChip:{
    minWidth:124,
    width:'max-content',
    textAlign:'center',
    padding:'8px 12px',
    borderRadius:3,
    fontSize:13.75
  },

  iconButton:{
    padding:2
  },
})

const TemplatesList = props => {

  const {
    classes,
    theme,
    // state,
    // dispatch,
  } = props;

  const [historyData, setHistoryData] = useState({loading:false,error:false});
  const scrollRef = useRef();

  const autoRefreshTimeoutRef = useRef();
  const [autoRefreshCount, setAutoRefreshCount] = useState();
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`template-bulk-edit-history`,
    isCancelledRef
  })

  useEffect(()=>{
    return () => {
      isCancelledRef.current = true
    }
  },[])

  const loadHistory = ({page=1}) => {
    setHistoryData({loading:true,data:page===1?undefined:historyData.data})
    if(page===1){
      clearInterval(autoRefreshTimeoutRef.current)
      setAutoRefreshCount()
    }
    axiosCerebrum
      .get(
        `/api/me/extracts`,
        {params:{
          per_page:20,
          sort:'START_DESC',
          page:page,
          status:'COMPLETE,FAILED,RUNNING,SUBMITTED,KILLED',
          type:'BULK_EDIT_EXCEL'
        }}
      )
      .then(async response=>{
        let newData;
        if(page===1){
          newData = response.data
        }else{
          newData = {...response.data,items:[...historyData.data.items,...response.data.items]}
        }
        setHistoryData({loading:false,data:newData});
        if(page===1){
          clearInterval(autoRefreshTimeoutRef.current)
          setAutoRefreshCount(30)
          let count = 30;
          autoRefreshTimeoutRef.current = setInterval(()=>{
            count -= 1;
            setAutoRefreshCount(count)
            if(count===0){
              setAutoRefreshCount()
              loadHistory({autoRefresh:true})
              clearInterval(autoRefreshTimeoutRef.current)
            }
          },1000)
        }

      })
      .catch(error=>{
        console.log(error);
        setHistoryData({loading:false,error:true})
      })
  }

  useEffect(()=>{
    loadHistory({})
    return ()=>{
      clearInterval(autoRefreshTimeoutRef.current)
    }
     // eslint-disable-next-line
  },[])

  window.onscroll = () => {
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
      clearInterval( autoRefreshTimeoutRef.current)
      setAutoRefreshCount()
    }
  }

  useEffect(()=>{
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
    }
  // eslint-disable-next-line
  },[historyData.data,historyData.loading])


  const getIcon = extract => {
    if(extract.status==='RUNNING'){
      return <div className={classes.statusChip} style={{background:'#FC642D',color:'white'}}>Extract running</div>
    }
    if(extract.status==='COMPLETE'){
      return <div className={classes.statusChip} style={{background:'#48D46A',color:'black'}}>Extract ready</div>
    }
    if(extract.status==='SUBMITTED'){
      return <div className={classes.statusChip} style={{background:'#FFAB00',color:'black'}}>Extract submitted</div>
    }
    if(extract.status==='FAILED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Extract failed</div>
    }
    if(extract.status==='KILLED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Extract cancelled</div>
    }
  }

  return (
    <div className={classes.root}>
      <div style={{zIndex:9,background:theme.palette.background.main,paddingBottom:16,position:'sticky',top:150}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start',marginBottom:24,marginTop:16}}>
          <div>
            <Typography style={{color:theme.palette.header.main,fontSize:20}}>TEMPLATES</Typography>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginTop:2}}>
              Status will update every 30 seconds. Click an available template to download it. Templates are stored for 7 days.
            </Typography>
          </div>
          <Button
            color='primary'
            style={{height:42,flexShrink:0,background:autoRefreshCount?theme.palette.success.main:undefined,color:autoRefreshCount?'#000':undefined}}
            variant='contained'
            onClick={()=>loadHistory({})}
          >
            REFRESH {autoRefreshCount && `(${autoRefreshCount})`}
          </Button>
        </div>
        <div className={classes.titleRow}>
          <div  className={classes.tableTitle} style={{width:160,flexShrink:0}}>
            STATUS
          </div>
          <div className={classes.tableTitle} style={{flexGrow:1,marginRight:16,}}>
            DETAILS
          </div>
          <div className={classes.tableTitle}  style={{width:300,flexShrink:0,marginRight:16}}>
            CREATED ON
          </div>
          <div className={classes.tableTitle} style={{width:88,flexShrink:0}}>
            ACTIONS
          </div>
        </div>
      </div>
      {
        historyData.error &&
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading templates</Typography>
      }
      {
        historyData.data && historyData.data.total===0 &&
        <Typography style={{color:theme.palette.primaryText.main}}>No template found</Typography>
      }
      {
        historyData.data && historyData.data.items.map(el=>(
          <div className={classes.columnRow}>
            <KTooltip classes={{tooltip:classes.tooltip}} title={el.status}>
              <div style={{width:150,flexShrink:0,marginRight:10}}>
                {/* status */}
                {getIcon(el)}
              </div>
            </KTooltip>
            <KTooltip placement={"bottom-start"} classes={{tooltip:classes.tooltip}} title={el.args?.filters?.name || 'No details'}>
              <div style={{flexGrow:1,marginRight:16,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',}}>
                {el.args?.filters?.name || 'No details'}
              </div>
            </KTooltip>
            <div style={{width:300,marginRight:16,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
              {moment(el.user_created_at).format('llll')}
            </div>
            <div style={{width:88,flexShrink:0,display:'flex'}}>
              {
                el.status==='COMPLETE'?
                <IconButton
                  className={classes.iconButton}
                  onClick={()=>{
                    getExtractResult({
                      id:el.id,
                      onSuccess:()=>{
                        sendAlert({type:'info',message:'Download will start soon'})
                      },
                      onError:()=>{
                        sendAlert({type:'error',message:'Failed to download the template'})
                      },
                      defaultFileName:`K_bulk_edit_template_${moment().format('DD_MM_YYYY')}.xlsx`
                    })
                }}>
                  {getIconComponent({label:'download',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
                :
                <div style={{width:24,marginRight:8}}/>
              }
              {
                ['RUNNING','SUBMITTED'].includes(el.status) &&
                <KTooltip title="Cancel job">
                  <IconButton
                    className={classes.iconButton}
                    onClick={(event) => {
                      event.stopPropagation();
                      setCancelModalOpen({item:el,type:'single'});
                    }}
                  >
                    {getIconComponent({label:'clear_circle',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </KTooltip>
              }
            </div>
          </div>
        ))
      }
      <div ref={scrollRef} style={{marginBottom:40,display:'flex',justifyContent:'center'}}>
        {
          historyData.loading &&
          <CircularProgress color='secondary'/>
        }
      </div>
      <CancelModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        loadMoreHistory={loadHistory}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(TemplatesList));
