import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress, IconButton } from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum';
import { generateScheduleText } from '../../UI/Scheduler/utils';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getIconComponent, isInViewport } from '../../../utilities';
import EditScheduleModal from './EditScheduleModal';
import FilterDetailEditModal from './FilterDetailEditModal';
import EditRecipientsModal from './EditRecipientsModal';
import { getExtractName } from '../../QuestionBoard/Utils/Utils';
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';
import useAlert from '../../../hooks/useAlert';
import AscIcon from '@material-ui/icons/ArrowUpward'
import DescIcon from '@material-ui/icons/ArrowDownward'

const styles = theme => ({

  columnText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primary.main,
    minWidth:66,
    letterSpacing:1.5
  },
  tableText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    minWidth:66,
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    // cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    },
    paddingLeft:16
  },
  sortIconWrapper:{
    marginLeft:4,
    position:'relative',
    top:3
  },
  header:{
    color:theme.palette.primaryText.main,
    fontSize:20,
    marginTop:16
  }
})

function List(props) {

  const {
    classes,
    history,
    state,
    dispatch,
    variant,
    sessionData
  } = props;

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)
  const [recipientsModalOpen, setRecipientsModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const scrollRef = useRef()

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [buttonHovered, setButtonHovered] = useState(false)

  const {
    sendAlert
  } = useAlert({
  })

  const loadAlerts = ({page=1, sort=state.alertSort}) => {
    dispatch({
      type:"set_alert_list",
      alertListLoading:true,
      alertList:page===1?undefined:state.alertList
    })
    axiosCerebrum
      .get(
        `/api/alerts`,{
          params:{
            page,
            per_page:20,
            sort,
            created_by_id:variant==='admin'?undefined:sessionData.id
          }
        }
      )
      .then(response=>{
        dispatch({
          type:"set_alert_list",
          alertListLoading:false,
          alertList:{
            ...response.data,
            items:page===1?response.data.items:state.alertList.items.concat(response.data.items)
          }
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:"set_alert_list",
          alertListError:error
        })
      })
  }

  useEffect(()=>{
    if(!state.alertList && !state.alertListLoading){
      loadAlerts({page:1})
    }
    // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && !state.alertListLoading && state.alertList && state.alertList?.page < state.alertList?.pages
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadAlerts({page:state.alertList.page+1})
    }
    // eslint-disable-next-line
  },[state.alertList, state.alertListLoading])

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadAlerts({page:state.alertList.page+1})
    }
  }

  const flexConfig = [
    '1 1 200px',
    '0 1 120px',
    '1 1 220px',
    '0 1 200px',
    '0 1 200px',
    '0 1 240px',
    '0 0 150px',
  ]

  const getRecipientText = el => {
    if(el.alert_channel_args?.recipients){
      return el.alert_channel_args.recipients.join(', ')
    }
    return 'N/A'
  }

  const getRecipientCount = el => {
    return (el.alert_channel_args?.recipients?.length || 0) + ' recipient(s)'
  }

  const getTypeText = el => {
    if(el.filtered_list)return 'Filter: ' + el.filtered_list?.name;
    return 'Ask K: ' + (getExtractName(el.alert_source_args?.lookup_code, el.alert_source_args?.filters) || el.extract_template?.name );
  }

  const onDelete = alert => {
    setLoading(true)
    axiosCerebrum
      .delete(
        `/api/alerts/${alert.id}`
      )
      .then(response=>{
        dispatch({
          type:"set_alert_list",
          alertList:{
            ...state.alertList,
            items:state.alertList.items.filter(el=>el.id!==alert.id),
            total:state.alertList.total-1
          }
        })
        setLoading(false)
        setDeleteModalOpen(false)
        sendAlert({type:"info",message:`${alert.name} alert deleted successfully`})
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
        setAlertMessage('Error occurred deleting alert')
        setAlertOpen(true)
      })
  }

  const getSortIcon = field => {
    if(field==='name'){
      if(state.alertSort==='ALPHABETICAL'){
        return <AscIcon style={{width:16,height:16,color:theme.palette.primary.main}}/>
      }
      if(state.alertSort==='REVERSE_ALPHABETICAL'){
        return <DescIcon style={{width:16,height:16,color:theme.palette.primary.main}}/>
      }
    }
  }

  const onChangeSort = field => {
    if(field==='name'){
      if(state.alertSort==='ALPHABETICAL'){
        dispatch({type:'set_alert_sort',alertSort:'REVERSE_ALPHABETICAL'})
        loadAlerts({page:1, sort:'REVERSE_ALPHABETICAL'})
      }else{
        dispatch({type:'set_alert_sort',alertSort:'ALPHABETICAL'})
        loadAlerts({page:1, sort:'ALPHABETICAL'})
      }
    }
  }

  return (
    <div className={classes.root}>
      <div style={{zIndex:9,background:theme.palette.background.main,paddingBottom:16,position:'sticky',top:150}}>
        <Typography className={classes.header}>
          {state.alertList?.total} ALERT(S)
        </Typography>
      </div>
      {
        state.alertList?.items?.length===0 &&
        <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light}}>
          No alerts created
        </Typography>
      }
      {
        state.alertList?.items?.length>0 &&
        <div style={{display:"flex",marginBottom:8,paddingLeft:16}}>
          <Typography className={classes.columnText} style={{flex:flexConfig[0],cursor:'pointer'}} onClick={()=>onChangeSort('name')}>
            ALERT NAME <span className={classes.sortIconWrapper}>{getSortIcon('name')}</span>
          </Typography>
          <Typography className={classes.columnText} style={{flex:flexConfig[1]}}>
            ALERT ID
          </Typography>
          <Typography className={classes.columnText} style={{flex:flexConfig[2]}}>
            TYPE
          </Typography>
          {
            variant==='admin' &&
            <Typography className={classes.columnText} style={{flex:flexConfig[3]}}>
              CREATED BY
            </Typography>
          }
          <Typography className={classes.columnText} style={{flex:flexConfig[4]}}>
            DELIVER TO
          </Typography>
          <Typography className={classes.columnText} style={{flex:flexConfig[5]}}>
            SCHEDULE
          </Typography>
          <Typography className={classes.columnText} style={{flex:flexConfig[6]}}>
            ACTIONS
          </Typography>
        </div>
      }
      {
        state.alertList?.items?.map(el=>(
          <div
            className={classes.listItem}
            key={el.id}
            style={{background:buttonHovered?theme.palette.background.main:undefined}}
          >
            <Typography className={classes.tableText} style={{flex:flexConfig[0]}}>
              {el.name}
            </Typography>
            <Typography className={classes.tableText} style={{flex:flexConfig[1]}}>
              {el.id}
            </Typography>
            <KTooltip title={getTypeText(el)}>
              <Typography className={classes.tableText} style={{flex:flexConfig[2]}}>
                {getTypeText(el)}
              </Typography>
            </KTooltip>
            {
              variant==='admin' &&
              <KTooltip title={el.created_by?.name}>
                <Typography className={classes.tableText} style={{flex:flexConfig[3]}}>
                  {el.created_by?.name}
                </Typography>
              </KTooltip>
            }
            <KTooltip title={getRecipientText(el)}>
              <Typography className={classes.tableText} style={{flex:flexConfig[4]}}>
                {getRecipientCount(el)}
              </Typography>
            </KTooltip>
            <KTooltip title={generateScheduleText({cron:el.schedule, frequency:el.schedule?.load_frequency||'custom'})}>
              <Typography className={classes.tableText} style={{flex:flexConfig[5]}}>
                {
                  Object.keys(el.schedule).length===0?
                  <div style={{width:'max-content',height:24,borderRadius:12,background:theme.palette.error.main,color:"#fff",display:'flex',alignItems:'center',padding:'0 8px'}}>
                    Not Scheduled
                  </div>
                  :
                  generateScheduleText({cron:el.schedule, frequency:el.schedule?.load_frequency||'custom'})
                }
              </Typography>
            </KTooltip>
            <div className={classes.tableText} style={{flex:flexConfig[6], display:'flex'}}>
              <KTooltip title="Edit alert details">
                <IconButton
                  onClick={()=>setEditModalOpen({alert:el})}
                  onMouseEnter={()=>setButtonHovered(true)}
                  onMouseLeave={()=>setButtonHovered(false)}
                >
                  {getIconComponent({label:"edit",size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </KTooltip>
              <KTooltip title="Edit alert recipients">
                <IconButton
                  onClick={()=>setRecipientsModalOpen({alert:el})}
                  onMouseEnter={()=>setButtonHovered(true)}
                  onMouseLeave={()=>setButtonHovered(false)}
                >
                  {getIconComponent({label:"add_recipients",size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </KTooltip>
              <KTooltip title="Edit alert schedule">
                <IconButton
                  onClick={()=>setScheduleModalOpen({alert:el})}
                  onMouseEnter={()=>setButtonHovered(true)}
                  onMouseLeave={()=>setButtonHovered(false)}
                >
                  {getIconComponent({label:"schedule",size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </KTooltip>
              <KTooltip title="Delete alert">
                <IconButton
                  onClick={()=>setDeleteModalOpen({alert:el})}
                  onMouseEnter={()=>setButtonHovered(true)}
                  onMouseLeave={()=>setButtonHovered(false)}
                >
                  {getIconComponent({label:"delete",size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </KTooltip>
            </div>
          </div>
        ))
      }
      {
        state.alertListError &&
        <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light}}>
          Error occurred loading alerts
        </Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24,marginTop:16}}>
        {
          state.alertListLoading &&
          <CircularProgress color='secondary'/>
        }
      </div>
      <EditScheduleModal
        modalOpen={scheduleModalOpen}
        setModalOpen={setScheduleModalOpen}
        state={state}
        dispatch={dispatch}
      />
      <FilterDetailEditModal
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        state={state}
        dispatch={dispatch}
        history={history}
      />
      <EditRecipientsModal
        modalOpen={recipientsModalOpen}
        setModalOpen={setRecipientsModalOpen}
        state={state}
        dispatch={dispatch}
      />
      <WarningConfirmModal
        description={
          <span>
            You are about to delete the <span style={{fontWeight:700}}>"{deleteModalOpen?.alert?.name}"</span> alert.
            {'\n\n'}
            <span>This action cannot be reversed.</span>
            {'\n\n'}
            <span>Click YES to proceed.</span>
          </span>
        }
        setModalOpen={setDeleteModalOpen}
        modalOpen={deleteModalOpen}
        onConfirm={()=>onDelete(deleteModalOpen?.alert)}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={alertMessage}
        confirmText={'YES'}
        disableConfirmButton={loading}
      />
    </div>
  )
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(List));
