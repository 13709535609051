import React,{ useEffect, useRef, useState} from 'react';
import {withTheme, Typography, withStyles, Button, IconButton, } from '@material-ui/core';
import { getIconComponent, getLabelPlural, mapObjectName, toTitleCase } from '../../../utilities';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ExpandLessIcon from '@material-ui/icons/ArrowDropUpOutlined';
import KTooltip from '../KTooltip/KTooltip';
import axiosCerebrum from '../../../axios-cerebrum';
import { getPutPayload } from '../UpdateInput/utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    marginBottom:60,
    marginTop:60
  },
  columnHeader:{
    color:theme.palette.primaryText.light,
    letterSpacing:2,
    fontSize:12,
    paddingRight:16
  },
  listText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    padding:'12px 16px 12px 0'
  },
  clickableItem:{
    cursor:'pointer',
  },
  hoverable:{
    "&:hover":{
      background:theme.palette.hovered.main
    }
  },
  scrollableContent:{
    maxHeight:158,
    height:'max-content',
    whiteSpace:'pre-wrap',
    marginTop:8,
    color:theme.palette.primaryText.light,
    overflow:'auto',
    fontSize:13.75,
    width:'60%',
    ...theme.components.customScroll,
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:2,
  }
})

function SourceDescription(props) {

  const {
    theme,
    classes,
    titleStyle={},
    state,
    dispatch,
    overrideOnApplyFunc,
    noMargin
  } = props;

  const [seeMore, setSeeMore] = useState(false);
  const [expandedItems, setExpandedItems] = useState([])

  const [isHovering, setIsHovering] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const onExpand = (event, id) => {
    let el = event.currentTarget.parentElement;
    if(!el)return;
    if(!expandedItems.includes(id)){
      expandedItems.forEach(i=>{
        onExpand({currentTarget:{parentElemenet:document.getElementById(`source_descriptions_${i}`)}}, i)
      })
      el.style["max-height"] = '335px';
      setExpandedItems([id])
    }else{
      el.style["max-height"] = '47px';
      setTimeout(()=>setExpandedItems(expandedItems.filter(e=>e!==id)),100)
    }
  }

  const onOverideDescription = value => {
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(state.basicData.object.name)}/${state.basicData.id}`,
        getPutPayload({property:'description',value,data:state.basicData})
      )
      .then(response=>{
        dispatch({
          type:'set_basic_data',
          basicData:{
            ...state.basicData,
            ...response.data
          }
        })
        sendAlert({ message: 'Description successfully updated', type: 'info' })
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred updating description', type: 'error' })
      })
  }

  if(!state.basicData.source_descriptions || state.basicData.source_descriptions.length===0)return <></>

  let list = state.basicData.source_descriptions;

  return (
    <div className={classes.root} style={noMargin?{margin:0}:undefined}>
      <Typography style={{fontSize:20,color:theme.palette.header.main,marginBottom:16,...titleStyle}}>OTHER DETAILS</Typography>

      <div style={{display:'flex',width:'100%',height:24,alignItems:'center'}}>
        <Typography className={classes.columnHeader} style={{marginLeft:56,flex:'0 0 25%'}}>SOURCE</Typography>
        <Typography className={classes.columnHeader} style={{flex:'1 0 45%'}}>DESCRIPTION</Typography>
      </div>

      {
        list.slice(0,seeMore?list.length:3).map((el, index)=>(
          <div id={`source_descriptions_${index}`} style={{transition: "max-height .1s ease",maxHeight:47,display:'flex',flexDirection:'column', overflow:'hidden',border:`1px solid ${theme.palette.listItemDivider.main}`, borderStyle:expandedItems.includes(index)?'solid':'none none solid none'}}>
            <div onClick={event=>onExpand(event, index)} className={classes.clickableItem + (isHovering?'':' '+classes.hoverable)} style={{display:'flex',width:'100%',alignItems:'flex-start',height:47}}>
              <div style={{width:24,height:24,margin:'12px 16px',flexShrink:0,flexGrow:0}}>
                {getIconComponent({label:el.source?.source_template?.name || 'info',size:20,colour:theme.palette.primary.main})}
              </div>
              <KTooltip placement='bottom-start'  title={el.source?.name || ''}>
                <Typography className={classes.listText} style={{flex:expandedItems.includes(index)?'1 1 100%':'0 0 25%',fontWeight:expandedItems.includes(index)?700:400}}>{el.source?.name || ''}</Typography>
              </KTooltip>
              {
                !expandedItems.includes(index) &&
                <KTooltip placement='bottom-start' title={el.value}>
                  <Typography data-test-classname="source-description" className={classes.listText} style={{flex:'1 0 45%' }}>{el.description}</Typography>
                </KTooltip>
              }
              <KTooltip title={`Override description for this ${toTitleCase(mapObjectName(state.basicData.object.name).replace(/_/g,' '))} with this information from ${el.source.name}`}>
                <IconButton
                  data-test-classname="source-description-overwrite-button"
                  onMouseEnter={()=>setIsHovering(true)}
                  onMouseLeave={()=>setIsHovering(false)}
                  style={{marginTop:4,marginRight:6}}
                  onClick={event=>{
                    event.stopPropagation();
                    if(overrideOnApplyFunc){
                      overrideOnApplyFunc(el.description)
                    }else{
                      onOverideDescription(el.description)
                    }
                  }}
                >
                  {getIconComponent({label:'description_override',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </KTooltip>
              {
                expandedItems.includes(index)?
                <ExpandLessIcon style={{marginTop:12, marginRight:8, color:theme.palette.primaryText.light }}/>:
                <ExpandMoreIcon style={{marginTop:12, marginRight:8, color:theme.palette.primaryText.light }} />
              }
            </div>
            {
              expandedItems.includes(index) &&
              <div style={{flex:'1 1', height:'100%', display:'flex', flexDirection:'column',paddingLeft:56,marginBottom:8,paddingBottom:16}}>
                <div style={{flex:'1 1' }}>
                  <Typography className={classes.sectionHeader} style={{marginTop:12}}>DESCRIPTION</Typography>
                  <div className={classes.scrollableContent}>
                    {el.description}
                  </div>
                </div>
              </div>
            }
          </div>
        ))
      }
      <div style={{display:'flex'}}>
        {
          list.length>3 && !seeMore &&
          <Button color='primary' style={{marginTop:8}} onClick={()=>{setSeeMore(true)}}>SEE MORE</Button>
        }
        {
          seeMore &&
          <Button color='primary' style={{marginTop:8,marginLeft:8}} onClick={()=>{setSeeMore(false)}}>SEE LESS</Button>
        }
      </div>
    </div>
  )
}


export default withTheme()(withStyles(styles)(SourceDescription));
