import React, {useState,useEffect} from 'react';
import { withTheme, withStyles, Typography} from '@material-ui/core';
import TopArrow from '@material-ui/icons/ExpandLess';
import { globalListenerRef } from '../../../GlobalListenerRef'
import PropTypes from 'prop-types';
import { getContentContainerStyle } from './layoutUtils';
import { isV3Lineage } from '../Lineage/utils';


const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    // width: '100%',
    overflow:'visible',
    display:"block",
    background:theme.palette.background.main,
  },
  header: {
    flexGrow:1,
    zIndex: 15,
  },
  headerContainer:{
    position:'sticky',
    overflow:'hidden',
    display:'flex',
    // justifyContent:'center',
    top:0,
    marginTop:16,
    paddingTop:10,
    width:'100%',
    background:theme.palette.background.main,
    zIndex:30,
    transition: 'height 0.5s',
  },
  tabBarContainer:{
    overflow:'visible',
    position: 'sticky',
    background:theme.palette.background.main,
    zIndex:25,
    paddingBottom:8,
    height:65,
    transition: 'top 0.5s',
  },
  goTopButton:{
    width:85,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    "&:hover":{
      cursor:'pointer'
    },
    background:'transparent',
  },
  tabBar: {
    position:'relative',
    left: -56,
    flexGrow:1,
    // height:56,
    marginBottom:1,
    zIndex: 17,
  },
  contentContainer:{
    display:'flex',
    // justifyContent:'center',
    alignItems:'flex-start',
    // flexGrow:1,
    width:'100%',
  },
  sideBar: {
    flexGrow:0,
    flexShrink:0,
    background:theme.palette.background.main,
    zIndex: 15,
  },
  hiddenSideBar:{
    width:0,
    minWidth:0,
    height:0,
    minHeight:0,
    overflow:'hidden',
    flex:'0 0 0',
  },
});

const ProfileLayout = props => {

  const {
    classes,
    header,
    body,
    tabBar,
    disableTabBarOffset,
    hideHeader,
    hideSideBar,
    sideBar,
    disableHeaderAutoHeight,
    disableMinContentHeight,
    maxWidth
  } = props;

  const [headerHeight, setHeaderHeight] = useState();
  const [scrollUpVisible, setScrollUpVisible] = useState(false);
  const [containerStyle, setContainerStyle] = useState(getContentContainerStyle(maxWidth))
  const [sideBarWidth, setSideBarWidth] = useState(window.innerWidth>1600?520:420)
  const [right, setRight] = useState(0)

  useEffect(()=>{
    let header = document.getElementById('profile-header-main-content')
    if(header){
      setRight(header.getBoundingClientRect().right)
    }else{
      setRight(-1)
    }
    let subHeaderHeight = document.getElementById('profile-header-sub-header')?document.getElementById('profile-header-sub-header').getBoundingClientRect().height:0;
    let tagsHeight = document.getElementById('profile-header-tags')?document.getElementById('profile-header-tags').getBoundingClientRect().height+8:0;
    let bannerHeight =  document.getElementById('profile-header-banner')? document.getElementById('profile-header-banner').getBoundingClientRect().height+12:0;
    let totalHeight = tagsHeight+bannerHeight+subHeaderHeight
    if(header){
      setHeaderHeight(header.getBoundingClientRect().height+bannerHeight-Math.min(window.scrollY,totalHeight))
    }
    setContainerStyle(getContentContainerStyle(maxWidth))
  // eslint-disable-next-line
  },[header])


  useEffect(()=>{
    window.removeEventListener('scroll', globalListenerRef.profileLayoutScroll);
    window.removeEventListener('message', globalListenerRef.updateProfileLayout);
    window.removeEventListener('resize', globalListenerRef.profileLayoutResize);

    const onAdjustHeaderHeight = () => {
      if ( window.scrollY>200) {
        setScrollUpVisible(true);
      }else{
        setScrollUpVisible(false);
      }
      let header = document.getElementById('profile-header-main-content')
      if(header){
        setRight(header.getBoundingClientRect().right)
        let subHeaderHeight = document.getElementById('profile-header-sub-header')?document.getElementById('profile-header-sub-header').getBoundingClientRect().height:0;
        let tagsHeight = document.getElementById('profile-header-tags')?document.getElementById('profile-header-tags').getBoundingClientRect().height:0;
        let bannerHeight =  document.getElementById('profile-header-banner')? document.getElementById('profile-header-banner').getBoundingClientRect().height+12:0;
        let totalHeight = tagsHeight+bannerHeight+subHeaderHeight;
        setHeaderHeight(header.getBoundingClientRect().height+bannerHeight-Math.min(window.scrollY,totalHeight))
      }
    }

    globalListenerRef.profileLayoutScroll = onAdjustHeaderHeight;
    globalListenerRef.updateProfileLayout = function(msg){
      if(msg.data.updated_profile_layout ){
        onAdjustHeaderHeight();
      }
      if(msg.data.newNavbarWidth){
        setContainerStyle(getContentContainerStyle(maxWidth))
      }
    }
    globalListenerRef.profileLayoutResize = function(){
      setContainerStyle(getContentContainerStyle(maxWidth))
      if(window.innerWidth>1600){
        setSideBarWidth(520)
      }else{
        setSideBarWidth(420)
      }
    }
    window.addEventListener('scroll', globalListenerRef.profileLayoutScroll);
    window.addEventListener('message', globalListenerRef.updateProfileLayout);
    window.addEventListener('resize', globalListenerRef.profileLayoutResize);
    return ()=>{
      window.removeEventListener('scroll', globalListenerRef.profileLayoutScroll)
      window.removeEventListener('message', globalListenerRef.updateProfileLayout);
    }
  // eslint-disable-next-line
  },[])


  const onClickGoTop = () => {
    window.scrollTo(0,0)
  }

  let isHideHeader = hideHeader && isV3Lineage()

  return (
    <div id="profile-layout-root">
      {
        header
        &&
        <div
          style={{
            height:isHideHeader?0:disableHeaderAutoHeight?undefined:headerHeight,
            padding:isHideHeader?0:undefined,
            margin:isHideHeader?0:undefined,
          }}
          className={classes.headerContainer}
          id='profile-header'
        >
          <div style={{...containerStyle,boxSizing:'border-box'}}>
            {header}
          </div>
        </div>
      }

      <div className={classes.root} style={containerStyle}>
        {
          tabBar?
          <div className={classes.tabBarContainer} style={{top:isHideHeader?0:headerHeight}} id='profile-tab-bar-container'>
            {/* <div className={classes.tabBarContainer}  style={{maxWidth,overflow:'visible',width:mainContentWidth}}> */}
              <div className={classes.tabBar} style={{left:disableTabBarOffset?0:undefined}} id='profile-tab-bar'>
                {tabBar}
              </div>
            {/* </div> */}
          </div>
          :
          <div style={{height:16}}></div>
        }

        <div
          className={classes.contentContainer}
          style={{minHeight:disableMinContentHeight?0:undefined, paddingBottom:disableMinContentHeight?0:80}}
          id='profile-content'
        >
          <div
            style={{
              flexShrink:1,
              flexGrow:1,
              maxWidth:'100%',
              minWidth:400,
              marginRight:sideBar && !hideSideBar?40:0,
              minHeight:window.innerHeight-headerHeight-100,
            }}
            id='profile-body'
          >
            {body}
          </div>

          { sideBar &&
            <div className={hideSideBar?classes.hiddenSideBar:classes.sideBar} style={{width:sideBarWidth}}>
              {sideBar}
            </div>
          }
        </div>
      </div>

      {
        right!==-1 &&
        // <div style={{visibility:scrollUpVisible?'visible':'hidden',zIndex:999,display:'flex',justifyContent:'flex-end',position:'sticky',bottom:40,paddingRight:window.innerWidth-right-10}}>
        <div style={{visibility:scrollUpVisible?'visible':'hidden',zIndex:999,display:'flex',justifyContent:'flex-end',position:'sticky',bottom:24,width:85,marginLeft:'auto',marginRight:'auto'}}>
          <div className={classes.goTopButton} onClick={onClickGoTop}>
            <TopArrow color='primary' style={{width:24,height:24}}/>
            <Typography color='primary'>GO TO TOP</Typography>
          </div>
        </div>
      }


    </div>
  )
}

ProfileLayout.propTypes = {
  header: PropTypes.any,
  body:PropTypes.any,
  tabBar:PropTypes.any,
  disableTabBarOffset:PropTypes.bool,
  hideHeader:PropTypes.bool,
  hideSideBar:PropTypes.bool,
  sideBar:PropTypes.any,
  disableHeaderAutoHeight:PropTypes.bool,
  disableMinContentHeight:PropTypes.bool,
  maxWidth:PropTypes.number
}

export default withTheme()(withStyles(styles)(ProfileLayout));
