import { getPartialMatchSearchString } from "../../../../utilities";
import { getContentContainerStyle } from "../../../UI/ProfileLayoutNew/layoutUtils";

export const formatColumnNames = name => {
  let suffixes = ['_srt','_msrt','_kc_msrt','kc_srt','_kc'];
  let processedName = name;
  suffixes.forEach(el=>{
    processedName = processedName.split(el)[0]
  })
  if(processedName==='dq_score_overall')processedName = 'overall_dq_score'
  if(processedName==='last_data_quality_score')processedName = 'last DQ score'
  if(processedName==='data_quality_dimension')processedName = 'DQ dimension'
  return processedName.replace(/_/g,' ').toUpperCase()
}

export const processObjectType = type => {
  if(!type?.split)return type;
 return type.split('@K-SEARCH-ID-')[0]
}

export const getCollectionObjectName = ({collectionName, collectionType}) => {
  return `${collectionName}@K-SEARCH-ID-${collectionType}-COLLECTION`
}

export const getCollectionTypeObjectName = ({collectionType}) => {
  return collectionType+'@K-SEARCH-ID-COLLECTIONTYPE'
}

export const generateSearchFq = ({tabs, objectType, filters, urlFilter, forceFq, indexName='search'}) => {
  let isCollection = tabs && tabs.find(el=>el.objectType===objectType).isCollection;
  let isCollectionType = tabs && tabs.find(el=>el.objectType===objectType).isCollectionType;
  let isCodeType = tabs && tabs.find(el=>el.objectType===objectType).isCodeType;
  let collectionType;
  if(isCollection){
    collectionType = tabs && tabs.find(el=>el.objectType===objectType).collectionType;
  }
  if(isCollectionType){
    collectionType = tabs && processObjectType(tabs.find(el=>el.objectType===objectType).objectType);
  }
  let fq = '';
  if(indexName==='search'){
    fq = `object_type_srt:${processObjectType(objectType)}`;
    if(objectType==='ALL')fq = 'object_type_srt:*'
    if(isCollection){
      fq = `collection_srt:"${processObjectType(objectType)}" AND collection_type_srt:"${collectionType}"  AND object_type_srt:COLLECTION_INSTANCE`
    }
    if(isCollectionType){
      fq = `collection_type_srt:"${collectionType}"  AND object_type_srt:COLLECTION`
    }
    if(isCodeType){
      fq = `code_type_srt:"${objectType}" AND object_type_srt:CODE`
    }
    if(filters?.length>0)fq += ` AND ${filters.join(' AND ')}`;
    if(urlFilter?.length>0)fq += ` AND ${urlFilter.join(' AND ')}`;
  }else{
    if(filters?.length>0)fq = `${filters.join(' AND ')}`;
  }
  if(forceFq)fq += fq?` AND ${forceFq}`:forceFq;
  return fq;
}

export const defaultExcludeReferenceTypes = ['TABLE','COLUMN','SCHEMA','DATABASE','TOOL','CONTENT_APP','REPORT','SHEET','DATASET','DATASET_TABLE','DATASET_FIELD','DATA_PIPELINE','ML_MODEL','FILE','WORKSPACE','USER','ALL'];

export const defaultFilterMap = {
  ALL:['object_type_srt','source_type_srt','source_srt','active_srt','reference_srt'],
  DATABASE:['source_type_srt','key','category_kc_msrt','active_srt','reference_srt'],
  SCHEMA:['source_type_srt','source_srt','database_srt','category_kc_msrt','last_used_srt','active_srt','reference_srt'],
  TABLE:['source_type_srt','source_srt','database_srt','schema_srt','table_type_srt','trust','category_kc_msrt','usage_srt','dq_score_overall_srt','last_used_srt','active_srt','reference_srt'],
  COLUMN:['source_type_srt','source_srt','database_srt','schema_srt','table_srt','lifecycle_srt','column_type_srt','trust','category_kc_msrt','dq_score_overall_srt','last_used_srt','active_srt','reference_srt'],
  CODE:['source_type_srt','location_srt','source_srt','database_srt','schema_srt','last_used_srt','tables_msrt','top_users_msrt','active_srt'],
  MACRO:['source_type_srt','location_srt','source_srt','database_srt','schema_srt','last_used_srt','tables_msrt','top_users_msrt','active_srt'],
  QUERY:['source_type_srt','location_srt','source_srt','database_srt','schema_srt','last_used_srt','tables_msrt','top_users_msrt','active_srt'],
  PROCEDURE:['source_type_srt','location_srt','source_srt','database_srt','schema_srt','last_used_srt','tables_msrt','top_users_msrt','active_srt'],
  TOOL:['source_type_srt','last_used_srt','active_srt','reference_srt'],
  REPORT:['source_type_srt','source_srt','location_srt','object_subtype_srt','tool_srt','category_kc_msrt','trust','usage_srt','last_used_srt','top_users_msrt','active_srt','reference_srt'],
  CONTENT_APP:['source_type_srt','source_srt','location_srt','category_kc_msrt','trust','active_srt','reference_srt'],
  SHEET:['source_type_srt','source_srt','location_srt','tool_srt','category_kc_msrt','trust','usage_srt','last_used_srt','top_users_msrt','active_srt','reference_srt'],
  DATASET:['source_type_srt','source_srt','location_srt','object_subtype_srt','tool_srt','category_kc_msrt','trust','usage_srt','last_used_srt','top_users_msrt','active_srt','reference_srt'],
  DATASET_TABLE:['source_type_srt','source_srt','location_srt','object_subtype_srt','category_kc_msrt','trust','last_used_srt','active_srt','reference_srt'],
  DATASET_FIELD:['source_type_srt','source_srt','location_srt','data_type_srt','object_subtype_srt','category_kc_msrt','trust','last_used_srt','active_srt','reference_srt'],
  DATA_PIPELINE:['source_type_srt','source_srt','location_srt','tool_srt','category_kc_msrt','trust','usage_srt','last_used_srt','top_users_msrt','active_srt','reference_srt'],
  ISSUE:['source_srt','issue_type_srt','issue_status_srt','issue_severity','last_updated_srt','assigned_to_srt','reported_by_srt','active_srt'],
  TAG:['last_updated_srt','created_on_srt'],
  COLLECTION:['collection_type_srt','source_srt'],
  ML_MODEL:['source_type_srt','source_srt','category_kc_msrt','location_srt','active_srt','reference_srt'],
  FILE:['source_type_srt','source_srt','location_srt','active_srt','reference_srt'],
  COLLECTION_INSTANCE:['source_srt','created_by_msrt','last_updated_srt'],
  WARNING_NOTICE:['source_srt','created_by_msrt','last_updated_srt','active_srt'],
  TEAM:['active_srt'],
  USER:['source_srt','alternate_sources_msrt','account_type_srt','teams_msrt','last_used_srt','active_srt','reference_srt'],
  DATA_QUALITY_TEST:['source_type_srt','source_srt','location_srt','data_quality_dimension_kc_msrt','active_srt'],
  SOURCE:['source_type_srt','active_srt','reference_srt'],
  WORKSPACE:['source_type_srt','source_srt','category_kc_msrt','trust','reference_srt'],
}

export const defaultColumnMap = {
  ALL:['object_type_srt','source_srt','trust'],
  PLATFORM:['created_by_msrt','source_srt'],
  COLLECTION_INSTANCE:['created_by_msrt'],
  WARNING_NOTICE:['created_by_msrt','expiry_srt','active_srt'],
  COLLECTION:['collection_type_srt','created_by_msrt'],
  USER:['source_srt','alternate_sources_msrt','account_type_srt','teams_msrt'],
  TEAM:['source_type_srt'],
  ISSUE:['source_type_srt','source_srt','last_used_srt'],
  CODE:['source_type_srt','database_srt','last_used_srt','tables_msrt'],
  MACRO:['source_type_srt','database_srt','last_used_srt','tables_msrt'],
  QUERY:['source_type_srt','database_srt','last_used_srt','tables_msrt'],
  PROCEDURE:['source_type_srt','database_srt','last_used_srt','tables_msrt'],
  TAG:['source_type_srt'],
  TOOL:['source_type_srt','last_used_srt'],
  DATABASE:['source_type_srt','last_used_srt'],
  STORAGE:['source_type_srt','last_used_srt'],
  COLUMN:['source_type_srt','column_type_srt','trust','last_used_srt'],
  DATA_QUALITY_TEST:['source_type_srt','source_srt'],
  SCHEMA:['source_type_srt','trust','last_used_srt'],
  CONTENT_APP:['source_type_srt','trust'],
  DATASET:['source_type_srt','object_subtype_srt','trust','usage_srt','last_used_srt'],
  DATASET_TABLE:['source_srt','object_subtype_srt','trust','last_used_srt'],
  DATASET_FIELD:['source_srt','data_type_srt','object_subtype_srt','trust','last_used_srt'],
  REPORT:['source_type_srt','object_subtype_srt','trust','usage_srt','last_used_srt'],
  ML_MODEL:['source_srt'],
  TABLE:['source_type_srt','table_type_srt','trust','usage_srt','last_used_srt'],
  VIEW:['source_type_srt','table_type_srt','trust','usage_srt','last_used_srt'],
  DEFAULT:['source_type_srt','trust','usage_srt','last_used_srt'],
  WORKSPACE:['source_srt','trust'],
  SOURCE:[],
}

export const getNameWidth = columns => {
  let { width, paddingLeft, paddingRight } = getContentContainerStyle();
  width -= (280 + paddingLeft + paddingRight);
  let nameWidth = width - (48 + 40 + columns.reduce((acc,el)=>acc+getColumnWidth(el)+16,0) + 32);
  if(nameWidth<350)nameWidth = 350;
  if(nameWidth>600){
    nameWidth = Math.min(nameWidth,width/2.2)
  }
  return nameWidth;
}

export const getColumnWidth = field => {
  if(['top_teams_msrt','teams_msrt','top_users_msrt'].includes(field))return 240
  return 146;
}

export const isColumnExtendable = field => {
  if(['top_teams_msrt','teams_msrt','top_users_msrt'].includes(field))return true
  return false;
}

export const getListWidth = columns => {
  // icon width + name width + main padding + column width + list padding
  return 48 + getNameWidth(columns) + 40 + columns.reduce((acc,el)=>acc+getColumnWidth(el)+16,0) + 32
}

export const getDefaultColumn = ({objectType, tabs}) => {
  let isCollection = tabs?.find(el=>el.objectType===objectType)?.isCollection;
  let isCollectionType = tabs?.find(el=>el.objectType===objectType)?.isCollectionType;
  let collectionType = tabs?.find(el=>el.objectType===objectType)?.collectionType;

  let type = isCollection?collectionType==='PLATFORM'?'PLATFORM':'COLLECTION_INSTANCE':isCollectionType?'COLLECTION':objectType;
  if(processObjectType(objectType)==='WARNING NOTICE'){
    type = 'WARNING_NOTICE'
  }

  let defaultColumns = defaultColumnMap[type]||defaultColumnMap.DEFAULT
  return defaultColumns
}

export const processFieldName = f => {
  if(f.includes('_kc_srt')){
    return f.replace('_kc_srt','').replace(/_/g,' ')
  }else if(f.includes('_kc_msrt')){
    return f.replace('_kc_msrt','').replace(/_/g,' ')
  }else if(f.includes('_srt')){
    return f.replace('_srt','').replace(/_/g,' ')
  }else if(f.includes('_msrt')){
    return f.replace('_msrt','').replace(/_/g,' ')
  }else if(f.includes('_count')){
    return f.replace(/_/g,' ')
  }
  return f
}


export const generateUrl = ({mainSearchQuery, andFilters, searchFilters, selectedColumns, selectedObjectType, defaultColumns = [], searchMode}) => {
  let query = mainSearchQuery;
  let object = selectedObjectType;
  let newUrl = `/basic_search?query=${query}&object=${object}`
  let presetFilter = ''
  let processedAndFilters = ''
  if(searchMode?.length>0){
    newUrl += `&searchMode=${searchMode.join(',')}`
  }
  if(andFilters?.length>0){
    newUrl += `&andFilters=${andFilters.join(',')}`
    processedAndFilters = andFilters.join(',')
  }
  if(searchFilters?.length>0){
    presetFilter = searchFilters.join(' AND ')
    newUrl += `&presetFilter=${presetFilter}`
  }
  let presetColumn = ''
  if(selectedColumns.length>0 && selectedColumns.some(c=>!defaultColumns.includes(c))){
    presetColumn = selectedColumns.join(',')
    newUrl += `&presetColumn=${presetColumn}`
  }
  return {newUrl, query, object, presetFilter, andFilters:processedAndFilters, presetColumn, searchMode}
}

export const getSearchFilterList = ({filterStatus, negativeFilters}) => {
  let searchFilters = [];
  Object.keys(filterStatus).forEach(k=>{
    if(filterStatus[k]?.selected?.length>0){
      searchFilters.push(`${negativeFilters.includes(k)?'-':''}${k}:${encodeURIComponent(filterStatus[k].selected.map(el=>el===null?`NO ${processFieldName(k)}`.toUpperCase():el).join(','))}`)
    }
  })
  return searchFilters
}

export const getSearchQuery = ({queryInput, searchMode, indexName}) => {
  // for query input, change every white space to \ + whitespace
  if(!queryInput)return '*'
  if(indexName==='impact')return `name_txt:(${getPartialMatchSearchString(queryInput,true,true)})`
  if(searchMode.length===0){
    queryInput = queryInput.replace(/ /g,'\\ ')
    return getPartialMatchSearchString(queryInput, true, true)
  }
  else if(searchMode.includes('exact_match') && searchMode.includes('name_only')){
    queryInput = queryInput.replace(/ /g,'\\ ')
    return `name_txt:(${queryInput})`
  }
  // else if(searchMode.includes('exact_match') && searchMode.includes('name_only')){
  //   return `name_txt:(${queryInput.split(' ').map(el=>el+'*').join(' ')})`
  // }
  // else if(searchMode.includes('exact_match')){
  //   return `${queryInput.split(' ').map(el=>el+'*').join(' ')}`
  // }
  else if(searchMode.includes('exact_match')){
    return `${queryInput}`
  }
  else if(searchMode.includes('name_only')){
    queryInput = queryInput.replace(/ /g,'\\ ')
    return `name_txt:(${getPartialMatchSearchString(queryInput,true,true)})`
  }
  return getPartialMatchSearchString(queryInput)
}

export const getSearchParams = ({queryInput, searchMode}) => {
  let params = {}
  if(searchMode.includes('exact_match')){
    params.mm = '100%'
  }
  return params
}

export const getDefaultSearchParams = ({indexName}) => {
  if(indexName!=='search')return {}
  let params = {}
  try{
    params = JSON.parse(JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===8000).value);
  }catch{

  }
  return params
}


export const rangeFilterFileds = [
  'row_count_srt',
  'size_mb_srt',
  'downstream_count',
  'upstream_count',
  'dq_score_overall_delta_srt'
]
