import React, { useState , useEffect, useRef} from 'react';
import { withTheme,withStyles, Typography, CircularProgress, Button, IconButton} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr';
import moment from 'moment'
import {addHistory} from '../../../HistoryManager'
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getDispFields, getIconComponent, isInViewport } from '../../../utilities';
import CancelModal from '../../PlatformManagement/Monitor/CancelModal';
import useAlert from '../../../hooks/useAlert';
import { checkEmailConfigured } from '../../../permissionChecker';

const styles = theme => ({
  root: {

  },
  statusChip:{
    width:88,
    textAlign:'center',
    padding:'8px 12px',
    borderRadius:3,
    fontSize:13.75
  },
  tooltip:{
    fontSize:13.75
  },
  columnRow:{
    height:48,
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    display:'flex',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  hoverableRow:{
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  titleRow:{
    display:'flex',
    overflow:'hidden'
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    overflow:'hidden',
    color:theme.palette.primary.main,
  },
  iconButton:{
    padding:6
  }
})

const History = props => {

  const {
    classes,
    theme,
    dispatch,
    history,
    direction,
    appName
  } = props;

  const [actionButtonHovered, setActionButtonHovered] = useState(false)
  const [historyData, setHistoryData] = useState({loading:false,error:false});
  const unmounted = useRef()

  const [autoRefreshCount, setAutoRefreshCount] = useState(0)
  const refreshInterval = useRef()
  const scrollRef = useRef();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const loadHistory = ({page=1,autoRefresh=false}) => {
    if(!autoRefresh)setHistoryData({loading:true})
    if(page===1){
      setAutoRefreshCount()
      clearInterval(refreshInterval.current)
    }
    axiosCerebrum
      .get(
        `/api/me/extracts`,
        {params:{
          per_page:10,
          sort:'START_DESC',
          page:page,
          status:'COMPLETE,FAILED,RUNNING,SUBMITTED,KILLED',
          type:`IMPACT_ANALYSIS_${direction.toUpperCase()}`,
        }}
      )
      .then(async response=>{
        if(response.data.total===0){
          setHistoryData({loading:false,data:response.data})
        }else{
          let targetIds = []
          response.data.items.forEach(el=>targetIds.push(...(el.args.filters.object_ids||[])));
          let targetDetails = [];
          let collectionIds = [];
          let collectionDetails = [];
          response.data.items.forEach(el=>{
            if(el.args.filters.collection_ids){
              collectionIds.push(...el.args.filters.collection_ids)
            }
          })
          try{
            if(targetIds.length>0){
              await axiosSolr
                .post(
                  `/solr/search/select`,
                  {
                    query:"*",
                    filter:`id:(${targetIds.join(' OR ')})`,
                    limit:targetIds.length,
                  }
                )
                .then(solrRes=>{
                  targetDetails = solrRes.data.response.docs;
                })
            }
            if(collectionIds.length>0){
              await axiosSolr
                .post(
                  `/solr/search/select`,
                  {
                    query:"*",
                    filter:`id:(${collectionIds.join(' OR ')})`,
                    limit:targetIds.length,
                  }
                )
                .then(solrRes=>{
                  collectionDetails = solrRes.data.response.docs;
                })
            }
            let list = response.data.items.map(el=>(
              {
                ...el,
                target_objects:targetDetails.filter(s=>el.args.filters.object_ids?.includes(s.id)),
                additional_criteria:el.args.filters.collection_ids && el.args.filters.collection_ids.map(el=>collectionDetails.find(s=>s.id===el)).filter(el=>el)
              }
            ))
            setHistoryData({loading:false,data:{...response.data,items:page===1?list:[...(historyData?.data?.items||[]),...list]}})
            if(!unmounted.current && page===1){
              clearTimeout(refreshInterval.current)
              setAutoRefreshCount(30)
              let count = 30;
              refreshInterval.current = setInterval(()=>{
                count-=1;
                setAutoRefreshCount(count)
                if(count===0){
                  setAutoRefreshCount()
                  clearInterval(refreshInterval.current)
                  loadHistory({page:1,autoRefresh:true})
                }
              },1000)
            }
          }catch(error){
            console.log(error)
            setHistoryData({loading:false,error:true})
          }
        }
      })
      .catch(error=>{
        console.log(error);
        setHistoryData({loading:false,error:true})
      })
  }

  useEffect(()=>{
    loadHistory({})
    return ()=>{
      unmounted.current = true;
      clearInterval(refreshInterval.current)
    }
     // eslint-disable-next-line
  },[])

  const onNotifyJobRun = () => {
    let message = 'Your job has been submitted.'
    if(checkEmailConfigured()){
      message += '\n\nYou will receive an email when the results are ready to review.'
    }else{
      message += '\n\nEmail delivery is currently not configured. Please check back regularly.'
    }

    sendAlert({message, type:'info'})
  }

  const onReSubmit = extract => {
    const payload = {
      ...extract.args,
      "ignore_cache":true,
      "lookup_code": `ia_${direction}`,
      "type":`IMPACT_ANALYSIS_${direction.toUpperCase()}`,
    }
    axiosCerebrum
      .post('/api/extracts',payload)
      .then(extractResponse=>{
        loadHistory({})
        onNotifyJobRun()
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:'Error occurred submitting the request, please try again',type:'error'})
      })
  }

  const onClickHistory = extract => {
    if(extract.status!=='COMPLETE')return;
    if(extract.target_objects.length===0)return;
    if(extract.target_objects.length===1){
      dispatch({type:'set_history_object',historyObject:extract.target_objects[0]})
    }
    if(extract.target_objects.length>1){
      dispatch({type:'set_multi_history_objects',multiHistoryObjects:extract.target_objects})
    }

    let url = window.location.pathname+`?extractId=${extract.id}`
    history.push(url)
    addHistory({url, iconLabel:'data_solution', title: extract.target_objects.map(el=>el.name_txt).join(', '), subTitle:`${appName} Result`,type:'application'})
  }

  let expireDays = 1;
  if(localStorage.hasOwnProperty('platformSettings')){
    try{
      let settings = JSON.parse(localStorage.getItem('platformSettings')).items;
      expireDays = settings.find(el=>el.id===4007).value;
    }catch{

    }
  }

  const getIcon = extract => {
    if(extract.status==='RUNNING'){
      return <div className={classes.statusChip} style={{background:'#FC642D',color:'white'}}>Running</div>
    }
    if(extract.status==='COMPLETE'){
      return <div className={classes.statusChip} style={{background:'#48D46A',color:'black'}}>Complete</div>
    }
    if(extract.status==='SUBMITTED'){
      return <div className={classes.statusChip} style={{background:'#FFAB00',color:'black'}}>Submitted</div>
    }
    if(extract.status==='FAILED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Failed</div>
    }
    if(extract.status==='KILLED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Cancelled</div>
    }
  }

  const getDetails = el => {
    let txt = `${el.args.filters.period} days`
    if(el.additional_criteria && el.additional_criteria.length>0){
      let names = el.additional_criteria.map(el=>el.name_txt)
      let criteriaTxt;
      if(names.length<=2)criteriaTxt  = `${names.join(' & ')}`
      else{
        criteriaTxt  = `${names.slice(0,names.length-1).join(', ')} & ${names[names.length-1]}`
      }
      txt += `, Additional Criteria: ${criteriaTxt}`
    }
    return txt
  }

  window.onscroll = () => {
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
      clearInterval( refreshInterval.current)
      setAutoRefreshCount()
    }
  }

  useEffect(()=>{
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
    }
  // eslint-disable-next-line
  },[historyData.data,historyData.loading])

  return (
    <div className={classes.root}>
      <div style={{zIndex:9,background:theme.palette.background.main,paddingBottom:16,position:'sticky',top:150}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start',marginBottom:24,marginTop:16}}>
          <div>
            <Typography  style={{fontSize:20,color:theme.palette.header.main,}}>{historyData.data?historyData.data.items.length:0} ASSESSMENT(S) AVAILABLE</Typography>
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:8}}>Assessments will be updated every 30 seconds. Click refresh to check the current status. Assessments older than {expireDays} day(s) will be purged</Typography>
          </div>
          <Button color='primary' style={{height:42,flexShrink:0,background:autoRefreshCount?theme.palette.success.main:undefined,color:autoRefreshCount?'#000':undefined}} variant='contained' onClick={()=>loadHistory({})}>
            REFRESH {autoRefreshCount && `(${autoRefreshCount})`}
          </Button>
        </div>
        <div className={classes.titleRow}>
          <div className={classes.tableTitle} style={{width:120,flexShrink:0}}>
            STATUS
          </div>
          <div className={classes.tableTitle} style={{width:200,flexGrow:0,minWidth:160,flexShrink:1,paddingRight:16}}>
            ASSESSMENT TYPE
          </div>
          <div className={classes.tableTitle} style={{width:200,flexGrow:1,minWidth:160,flexShrink:1,paddingRight:16}}>
            TARGET
          </div>
          {/* <div className={classes.tableTitle} style={{width:234,flexShrink:1,marginLeft:16,marginRight:16}}>
            PATH
          </div> */}
          <div className={classes.tableTitle}  style={{flexGrow:1,width:250,minWidth:120,paddingRight:16}}>
            DETAILS
          </div>
          <div className={classes.tableTitle}  style={{width:200,flexShrink:0}}>
            CREATED ON
          </div>
          <div className={classes.tableTitle}  style={{width:200,flexShrink:0}}>
            ACTIONS
          </div>
        </div>
      </div>
      {
        historyData.error &&
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading history</Typography>
      }
      {
        historyData.data && historyData.data.total===0 &&
        <Typography style={{color:theme.palette.primaryText.main}}>No history found</Typography>
      }
      {
        historyData.data && historyData.data.items.map(el=>(
          <div className={classes.columnRow + ' ' + (actionButtonHovered?'':classes.hoverableRow)} data-test-classname="ia-list-item">
            <KTooltip classes={{tooltip:classes.tooltip}} title={el.status.toLowerCase()}>
              <div style={{width:120,display:'flex',alignItems:'center',flexShrink:0}}>
                {getIcon(el)}
              </div>
            </KTooltip>
            <div style={{width:200,flexGrow:0,flexShrink:1,minWidth:160,paddingRight:16,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',}}>
              {el.args?.filters?.object_ids?.length===1?'Single':`Multiple (${el.args?.filters?.object_ids?.length} assets)`}
            </div>
            <div style={{width:200,flexGrow:1,flexShrink:1,minWidth:160,paddingRight:16,overflow:'hidden'}}>
              <KTooltip classes={{tooltip:classes.tooltip}} title={el.target_objects.map(el=>el.name_txt).join(', ')}>
                <Typography style={{fontSize:13.75,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:"nowrap" }}>
                  {el.target_objects.map(el=>el.name_txt).join(', ')}
                </Typography>
              </KTooltip>
              {
                el.target_objects.length===1 &&
                <KTooltip classes={{tooltip:classes.tooltip}} title={getDispFields(el.target_objects[0],'dispSubtitle')}>
                  <Typography style={{fontSize:12,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:"nowrap",color:theme.palette.primaryText.light }}>
                    {getDispFields(el.target_objects[0],'dispSubtitle')}
                  </Typography>
                </KTooltip>
              }
            </div>
            <KTooltip classes={{tooltip:classes.tooltip}} title={getDetails(el)}>
              <div style={{flexGrow:1,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16,minWidth:120,width:250}}>
                {getDetails(el)}
              </div>
            </KTooltip>
            <div style={{width:200,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
              {moment(el.user_created_at).format('llll')}
            </div>
            <div style={{width:200,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
              {
                el.status==='COMPLETE'?
                <KTooltip classes={{tooltip:classes.tooltip}} title={`View results`}>
                  <IconButton
                    className={classes.iconButton}
                    onMouseEnter={()=>setActionButtonHovered(true)}
                    onMouseLeave={()=>setActionButtonHovered(false)}
                    onClick={(event)=>{event.stopPropagation();onClickHistory(el)}}
                  >
                    {getIconComponent({label:'open',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </KTooltip>
                :
                <div style={{flex:'0 0 24px',marginRight:8}}></div>
              }
              {
                ['FAILED','KILLED','COMPLETE'].includes(el.status)?
                <KTooltip classes={{tooltip:classes.tooltip}} title={`Rerun the assessment`}>
                  <IconButton
                    className={classes.iconButton}
                    onMouseEnter={()=>setActionButtonHovered(true)}
                    onMouseLeave={()=>setActionButtonHovered(false)}
                    onClick={(event)=>{event.stopPropagation();onReSubmit(el)}}
                  >
                    {getIconComponent({label:'refresh',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </KTooltip>
                :
                <div style={{flex:'0 0 24px',marginRight:8}}></div>
              }
              {
                ['RUNNING','SUBMITTED'].includes(el.status) &&
                <KTooltip title="Cancel job">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setCancelModalOpen({item:el,type:'single'});
                    }}
                    className={classes.iconButton}
                    onMouseEnter={()=>setActionButtonHovered(true)}
                    onMouseLeave={()=>setActionButtonHovered(false)}
                  >
                    {getIconComponent({label:'clear_circle',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </KTooltip>
              }
            </div>
          </div>
        ))
      }
      <div ref={scrollRef} style={{marginBottom:40,display:'flex',justifyContent:'center'}}>
        {
          historyData.loading &&
          <CircularProgress color='secondary'/>
        }
      </div>
      <CancelModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        loadMoreHistory={loadHistory}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(History));
